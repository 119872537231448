import { HStack, Spinner, Tag } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { FiGitCommit } from "react-icons/fi";
import { Cell } from "react-table";
import { DataTable } from "../../components/DataTable";
import { Error } from "../../components/Error";
import {
  ComputerSoftwareVersionFragment,
  useGetDisplayComputersSoftwareVersionsQuery,
} from "../../generated/graphql";
import { getSoftwareVersionColor } from "../../libs/softwareVersion+";
import { PlayerDeploymentDrawer } from "./PlayerDeploymentDrawer";

export const PlayerVersions = () => {
  const {
    data: dataPlayers,
    error,
    loading,
  } = useGetDisplayComputersSoftwareVersionsQuery();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const players = useMemo(
    () =>
      dataPlayers?.displayComputers?.edges
        .map((edge) => edge?.node)
        .filter((item): item is ComputerSoftwareVersionFragment => !!item) ||
      [],
    [dataPlayers]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Composer",
        id: "composer",
        accessor: (row: ComputerSoftwareVersionFragment) => row.customer.name,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Player Version",
        id: "backoffice-version",
        sortType: (
          rowA: ComputerSoftwareVersionFragment,
          rowB: ComputerSoftwareVersionFragment
        ) => {
          return (
            (rowA.playerVersion?.number || 0) >
            (rowB.playerVersion?.number || 0)
          );
        },
        accessor: (row: ComputerSoftwareVersionFragment) =>
          row.playerVersion?.version,
        Cell: (cell: Cell<ComputerSoftwareVersionFragment>) => {
          const playerVersion = cell.row.original.playerVersion;
          if (!playerVersion) {
            return null;
          }
          return (
            <HStack>
              <Tag colorScheme={getSoftwareVersionColor(playerVersion.version)}>
                {playerVersion.version}
              </Tag>
              <Tag>{playerVersion.number}</Tag>
              {!!playerVersion.dockerImageTag && (
                <Tag>{playerVersion.dockerImageTag}</Tag>
              )}
            </HStack>
          );
        },
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      pageSize: 25,
    }),
    []
  );
  const onRowSelection = useCallback(
    (selectedRowIds: string[]) => setSelectedIds(selectedRowIds),
    []
  );

  const tableData = useMemo(() => players, [players]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }
  return (
    <DataTable
      data={tableData}
      columns={columns}
      initialState={initialState}
      onRowSelection={onRowSelection}
      actionButtons={[
        <PlayerDeploymentDrawer
          selectedIds={selectedIds}
          computers={players}
          leftIcon={<FiGitCommit />}
          disabled={selectedIds.length === 0}
        >
          Déployer Version
        </PlayerDeploymentDrawer>,
      ]}
    />
  );
};
