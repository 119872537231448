import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { VStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { FiPlus } from "react-icons/fi";
import {
  MediaTypeEnum,
  useCreateWidgetMediaMutation,
  WidgetLayoutItemNode,
} from "../../../generated/graphql";
import { MediaImageForm } from "../../Media/MediaImageForm";
import { MediaSubmitHandler } from "../../Media/MediaTypes";
import { MediaVideoForm } from "../../Media/MediaVideoForm";
import { MediaWebpageForm } from "../../Media/MediaWebpageForm";
import { dumpMediaMetaData, toDate } from "../metaData";

type AddMediaProps = {
  widgetLayoutItem: WidgetLayoutItemNode;
};

export const AddMedia: FC<AddMediaProps> = ({ widgetLayoutItem }) => {
  const [mediaType, setMediaType] = useState<MediaTypeEnum>(
    MediaTypeEnum.Image
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [createWidgetMedia] = useCreateWidgetMediaMutation({
    refetchQueries: ["LayoutItem"],
  });

  const handleSubmit: MediaSubmitHandler = async ({
    media,
    duration,
    objectFit,
    startDate,
    endDate,
  }) => {
    await createWidgetMedia({
      variables: {
        input: {
          widgetLayoutItemId: widgetLayoutItem.id,
          mediaContentId: media.id,
          data: dumpMediaMetaData({
            order: 0,
            duration,
            object_fit: objectFit,
            start_date: toDate(startDate),
            end_date: toDate(endDate),
          }),
        },
      },
    });

    onClose();
  };
  return (
    <>
      <IconButton
        aria-label="Ajouter Media"
        icon={<FiPlus />}
        size="sm"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un média</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignSelf="flex-start" spacing="16px">
              <FormControl as="fieldset">
                <FormLabel as="legend">Type de Media</FormLabel>
                <Select
                  defaultValue={mediaType}
                  onChange={(value) =>
                    setMediaType(value.currentTarget.value as MediaTypeEnum)
                  }
                >
                  <option value={MediaTypeEnum.Video}>Vidéo</option>
                  <option value={MediaTypeEnum.Image}>Image</option>
                  <option value={MediaTypeEnum.Iframe}>Page Web</option>
                </Select>
              </FormControl>
              {mediaType === MediaTypeEnum.Image && (
                <MediaImageForm onSubmitted={handleSubmit} create />
              )}
              {mediaType === MediaTypeEnum.Video && (
                <MediaVideoForm onSubmitted={handleSubmit} create />
              )}
              {mediaType === MediaTypeEnum.Iframe && (
                <MediaWebpageForm onSubmitted={handleSubmit} create />
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button mr={3} colorScheme="orange" type="submit" form="media-form">
              Créer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
