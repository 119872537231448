import {
  Button,
  ButtonProps,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CustomerSoftwareVersionFragment,
  useActivateWidgetOnCustomerMutation,
  useGetWidgetTypesQuery,
  WidgetTypeFragment,
} from "../../generated/graphql";

type ActivateWidgetsDrawerProps = ButtonProps & {
  selectedIds: string[];
  customers: CustomerSoftwareVersionFragment[];
};

type WidgetState = {
  widget: WidgetTypeFragment;
  activated: boolean;
  indeterminate: boolean;
};

export const ActivateWidgetsDrawer: FC<ActivateWidgetsDrawerProps> = ({
  selectedIds,
  customers,
  ...props
}) => {
  const { data: widgets, loading } = useGetWidgetTypesQuery();
  const toast = useToast();
  const [activateWidget] = useActivateWidgetOnCustomerMutation({
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const allWidgets =
    widgets?.allWidgets?.edges
      .map((edge) => edge?.node)
      .filter((widget): widget is WidgetTypeFragment => !!widget) || [];

  const [widgetsState, setSelectedWidgets] = useState<WidgetState[]>([]);

  const selectedCustomers = customers.filter((customer) =>
    selectedIds.includes(customer.id)
  );
  const selectedIdsSignature = selectedIds.join(",");
  useEffect(() => {
    const newWidgetsState = allWidgets.map((widget) => {
      const nbCustomerWithWidget = selectedCustomers.filter((customer) =>
        customer.activatedWidgets.edges.some(
          (edge) => edge?.node?.id === widget.id
        )
      ).length;
      const isChecked = nbCustomerWithWidget === selectedCustomers.length;
      const isIndeterminate = nbCustomerWithWidget > 0 && !isChecked;
      return {
        widget: widget,
        activated: isChecked,
        indeterminate: isIndeterminate,
      };
    });
    setSelectedWidgets(newWidgetsState);
  }, [loading, customers.length, selectedIdsSignature]);

  const handleActivateWidgets = (
    widget: WidgetTypeFragment,
    isChecked: boolean
  ) => {
    setSelectedWidgets((prev) => {
      const index = prev.findIndex(
        (widgetState) => widgetState.widget.id === widget.id
      );
      if (index === -1) {
        return [
          ...prev,
          {
            widget: widget,
            activated: isChecked,
            indeterminate: false,
          },
        ];
      }
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          activated: isChecked,
          indeterminate: false,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const [activationLoading, setActivationLoading] = useState(false);

  const activateWidgetCb = useCallback(async () => {
    console.log("activateWidgetCb");
    setActivationLoading(true);
    for (const widget of widgetsState) {
      if (!widget.indeterminate) {
        await activateWidget({
          variables: {
            input: {
              customerIds: selectedIds,
              widgetId: widget.widget.id,
              check: widget.activated,
            },
          },
        });
      }
    }
    setActivationLoading(false);
    onClose();
  }, [
    customers.length,
    selectedIdsSignature,
    activateWidget,
    widgetsState,
    onClose,
  ]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} {...props}>
        {props.children}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="lg"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Activer Widgets</DrawerHeader>

          <DrawerBody>
            <VStack width="100%" spacing="12px" alignItems={"flex-start"}>
              {widgetsState.map((widgetState) => {
                return (
                  <Checkbox
                    key={widgetState.widget.id}
                    value={widgetState.widget.id}
                    isChecked={widgetState.activated}
                    isIndeterminate={widgetState.indeterminate}
                    onChange={(e) =>
                      handleActivateWidgets(
                        widgetState.widget,
                        e.target.checked
                      )
                    }
                  >
                    {widgetState.widget.name}
                  </Checkbox>
                );
              })}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button
              isLoading={activationLoading}
              colorScheme="orange"
              onClick={activateWidgetCb}
              disabled={activationLoading}
            >
              Sauvegarder
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
