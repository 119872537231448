import { Progress, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { FC, useState } from "react";
import { MediaContentNode, MediaTypeEnum } from "../../generated/graphql";
import { MediaPreviewCard } from "./MediaCard";
import { UploadMediaButton } from "./UploadMediaButton";

type ProgressType = {
  loaded: number;
  total: number;
};

export type MediaUploadState = {
  media: MediaContentNode | null;
  progress: ProgressType | null;
  duration: number;
};

export type MediaListUploadState = {
  [key: string]: MediaUploadState;
};

export type MediaUploadProps = {
  mediaType: MediaTypeEnum;
  onMediaUpdate: (media: MediaUploadState) => void;
};

const MediaTypeAccept = {
  [MediaTypeEnum.Video]: "video/*",
  [MediaTypeEnum.Image]: "image/*",
  [MediaTypeEnum.Iframe]: "",
};

export const MediaUpload: FC<MediaUploadProps> = ({
  mediaType,
  onMediaUpdate,
}) => {
  const [mediaList, setMediaList] = useState<MediaListUploadState>({});
  return (
    <VStack>
      <Wrap spacing={12} width="100%">
        {mediaList &&
          Object.values(mediaList).map((media) =>
            media.media ? (
              <WrapItem>
                <MediaPreviewCard
                  media={media.media}
                  boxSize={180}
                  onLoadedMetadata={(e: any) =>
                    setMediaList((prevState) => {
                      const newState = {
                        ...prevState,
                        [media.media!.id]: {
                          ...prevState[media.media!.id],
                          duration: e.currentTarget?.duration || 10,
                        },
                      };
                      onMediaUpdate(newState[media.media!.id]);
                      return newState;
                    })
                  }
                />
              </WrapItem>
            ) : (
              media.progress && (
                <WrapItem>
                  <VStack h="50px" w="100%" alignSelf="flex-start">
                    <Progress
                      h="12px"
                      w="100%"
                      borderRadius={"8px"}
                      value={media.progress.loaded}
                      min={0}
                      max={media.progress.total || 1}
                    />
                    <Text>
                      {(media.progress.loaded / 1048576).toFixed(2)} /{" "}
                      {(media.progress.total / 1048576).toFixed(2)} MB
                    </Text>
                  </VStack>
                </WrapItem>
              )
            )
          )}
      </Wrap>

      <UploadMediaButton
        alignSelf="flex-start"
        variant="outline"
        bg="white"
        color="gray.500"
        accept={MediaTypeAccept[mediaType]}
        onUploadFinished={(media) => {
          setMediaList((prevState) => {
            const newState = {
              ...prevState,
              [media.id]: {
                ...prevState[media.id],
                media,
              },
            };
            onMediaUpdate(newState[media.id]);
            return newState;
          });
        }}
        onUploadUpdate={(progress: any, id) => {
          console.log(progress);
          setMediaList((prevState) => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              progress: {
                loaded: progress.loaded,
                total: progress.total,
              },
            },
          }));
        }}
        mediaType={mediaType}
      >
        <Text ml="8px">Selectionner un fichier</Text>
      </UploadMediaButton>
    </VStack>
  );
};
