import React, { FC } from "react";
import { WidgetAddWidgetPreviewProps } from "@govisupro/studio";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { AiFillYoutube } from "react-icons/ai";
import { v4 } from "uuid";
import { FiPlayCircle } from "react-icons/fi";

export const AddPreview: FC<WidgetAddWidgetPreviewProps> = ({
  onClick,
  widgetWidth,
  ...rest
}) => {
  const data = {
    id: v4(),
    data: {},
  };

  return (
    <Flex
      onClick={() => onClick(data)}
      {...rest}
      gap={3}
      bgColor="orange.400"
      alignItems="center"
      transition="0.2s"
      p={3}
      sx={{
        ":hover": {
          bgColor: "orange.500",
        },
      }}
    >
      <FiPlayCircle
        size={(265 / 1000) * widgetWidth * 0.5}
        color="white"
        strokeWidth={1.5}
      />
      <Text
        fontFamily="Rubik"
        fontWeight="bold"
        fontSize={`${(24 / 300) * widgetWidth}px`}
        color="white"
      >
        Playlist
      </Text>
    </Flex>
  );
};
