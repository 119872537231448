import { FC, FunctionComponent, useContext, useMemo } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Badge,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { CreateDisplayComputer } from "./CreateDisplayComputer";
import { LatLngPosition } from "../../components/Map";
import {
  DisplayComputerListItemFragment,
  DisplayStatusType,
  useGetDisplayComputersQuery,
  useGetMeQuery,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import { DataTable } from "../../components/DataTable";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { DisplayMenu } from "./DisplayMenu";
import { DisplayStatusBadge } from "../../components/DisplayStatusBadge";
import { compareComputerStatus } from "../../libs/displayStatus";

type CellProps = {
  computer: DisplayComputerListItemFragment;
};

const ScheduleCell: FC<CellProps> = ({ computer }) => (
  <HStack>
    <Badge>{computer.startTime.substring(0, 5)}</Badge>
    <Flex justifyContent="center" height="100%">
      <ArrowForwardIcon color="gray.500" alignSelf="center" />
    </Flex>
    <Badge>{computer.endTime.substring(0, 5)}</Badge>
  </HStack>
);

const NameCell: FC<CellProps> = ({ computer }) => {
  const display = computer.displaySet.edges[0]?.node;
  const match = useRouteMatch();
  return (
    <>
      <Link to={`${match.url}/display-computer/${computer.id}`}>
        <Text color="gray.700" fontSize="large" fontWeight="bold">
          {computer?.name}
        </Text>
      </Link>
      <Text color="gray.500" fontSize="medium">
        {display?.widthPx} x {display?.heightPx}px
      </Text>
    </>
  );
};

type DisplayTableProps = {
  onFlyTo?: (position: LatLngPosition) => void;
};

export const DisplayTable: FunctionComponent<DisplayTableProps> = ({
  onFlyTo,
}) => {
  const customerId = useContext(CustomerContext);
  const { data: userData } = useGetMeQuery();
  const { data } = useGetDisplayComputersQuery({
    variables: { customerId: customerId || "" },
    pollInterval: 30000,
  });
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const compareStatus = useMemo(
    () => (rowA: any, rowB: any) => {
      return compareComputerStatus(
        rowA.original.status as DisplayStatusType,
        rowB.original.status as DisplayStatusType
      );
    },
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (row: any) => <NameCell computer={row.row.original} />,
      },
      {
        Header: "Statut",
        accessor: "status",
        sortType: compareStatus,
        Cell: (row: any) => <DisplayStatusBadge computer={row.row.original} />,
      },
      {
        Header: "Programme",
        accessor: "schedule",
        Cell: (row: any) => <ScheduleCell computer={row.row.original} />,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (row: any) => <DisplayMenu computer={row.row.original} />,
      },
    ],
    [compareStatus]
  );
  const tableData =
    data?.customer?.displaycomputerSet?.edges.map((edge) => edge?.node) || [];

  return (
    <VStack alignItems="flex-start" maxHeight="100%" width="100%">
      <HStack spacing="24px">
        <DataBreadcrumb
          items={[
            {
              name: "Parc Ecrans",
            },
          ]}
        />
        {userData?.me?.isStaff && <CreateDisplayComputer />}
      </HStack>
      <Box
        borderRadius="12px"
        padding="18px"
        bg="white"
        width="100%"
        minHeight="120px"
      >
        <DataTable
          columns={columns}
          data={tableData || []}
          initialState={{
            hiddenColumns: isLargerThan1080 ? [] : ["schedule", "actions"],
          }}
        />
      </Box>
    </VStack>
  );
};
