import { useContext } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Text,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { CustomerContext } from "../../screens/NavigationContext";
import {
  useGetCustomerUsersQuery,
  UserNode,
  UserProfile,
} from "../../generated/graphql";
import { UpdateUser } from "./UpdateUser";
import { RemoveUser } from "./RemoveUser";

export const UserTable = () => {
  const customerId = useContext(CustomerContext);
  const { data } = useGetCustomerUsersQuery({
    variables: { customerId: customerId || "" },
  });

  return (
    <Box
      borderRadius="12px"
      padding="18px"
      bg="white"
      minWidth="512px"
      minHeight="120px"
    >
      <Table>
        <Tbody>
          {data?.customer?.userSet?.edges.map((user, index) => (
            <Tr key={"user-" + index}>
              <Td>
                <Checkbox />
              </Td>
              <Td>
                <Text color="gray.700" fontSize="medium" fontWeight="semibold">
                  {user?.node?.email}
                </Text>
              </Td>
              <Td>
                <Badge
                  variant="outline"
                  colorScheme={
                    user?.node?.profile === UserProfile.Admin
                      ? "orange"
                      : "gray"
                  }
                >
                  {user?.node?.profile}
                </Badge>
              </Td>
              <Td>
                <Text color="gray.600">
                  {user?.node?.firstName} {user?.node?.lastName}
                </Text>
              </Td>
              <Td>
                <Badge
                  variant="outline"
                  colorScheme={user?.node?.isActive ? "green" : "gray"}
                >
                  Actif
                </Badge>
              </Td>
              <Td>
                {user?.node?.id && (
                  <HStack>
                    <RemoveUser user={user.node as UserNode} />
                    <UpdateUser user={user.node as UserNode} />
                  </HStack>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
