import { InMemoryCache, ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "/graphql/",
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({});

export const client = new ApolloClient({
  uri: "/graphql/",
  link: authLink.concat(httpLink),
  cache,
});

export const AUTH_TOKEN = "affichage-dynamique-auth-token";

export const logout = async () => {
  await client.clearStore();
  localStorage.removeItem(AUTH_TOKEN);
  client.resetStore();
};

export const isLogged = () => {
  const token = getUserToken();
  return token !== null;
};

export const getUserToken = () => localStorage.getItem(AUTH_TOKEN);
