import {
  Box,
  HStack,
  Tag,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { FiGitCommit } from "react-icons/fi";
import {
  BackofficeSoftwareVersionFragment,
  PlayerSoftwareVersionFragment,
} from "../generated/graphql";
import { getSoftwareVersionColor } from "../libs/softwareVersion+";

type VersionRadioGroupProps = {
  versions: (
    | BackofficeSoftwareVersionFragment
    | PlayerSoftwareVersionFragment
  )[];
  onSelect: (versionId: string) => void;
};
export const VersionRadioGroup: FC<VersionRadioGroupProps> = ({
  versions,
  onSelect,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "versions",
    onChange: onSelect,
  });

  const group = getRootProps();

  return (
    <VStack {...group} width="100%">
      {versions.map((version) => {
        const radio = getRadioProps({ value: version.id });
        const colorScheme = getSoftwareVersionColor(version.version);

        return (
          <VersionRadio key={version.id} {...radio} colorScheme={colorScheme}>
            <HStack>
              <FiGitCommit />
              <Box>Version {version.number}</Box>
              <Tag colorScheme={colorScheme}>{version.version}</Tag>
              <Tag>{version.dockerImageTag}</Tag>
            </HStack>
            <Text color="gray.500">
              {new Date(version.date).toDateString()}
            </Text>
          </VersionRadio>
        );
      })}
    </VStack>
  );
};

type VersionRadioProps = UseRadioProps & {
  colorScheme: string;
};

const VersionRadio: FC<VersionRadioProps> = ({ colorScheme, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" width="100%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        width="100%"
        _checked={{
          bg: colorScheme + ".100",
          // color: "white",
          borderColor: colorScheme + ".600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};
