import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useCreateDisplayComputerMutation } from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";

export const CreateDisplayComputer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [createDisplayComputer] = useCreateDisplayComputerMutation({
    onError: (error) =>
      toast({
        title: "erreur lors de la création.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [name, setName] = useState("");
  const customerId = useContext(CustomerContext);
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <>
      <Button variant="outline" bg="white" onClick={onOpen}>
        Ajouter Player
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl width="180px">
              <FormLabel>Nom</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              disabled={name === ""}
              onClick={async () => {
                if (customerId) {
                  const displayComputerResult = await createDisplayComputer({
                    variables: {
                      customerId,
                      name,
                    },
                  });
                  if (
                    displayComputerResult.data?.createDisplayComputer
                      ?.displayComputer
                  ) {
                    history.push(
                      `${match.url}/display-computer/${displayComputerResult.data?.createDisplayComputer?.displayComputer.id}`
                    );
                  }
                }
              }}
            >
              Créer
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
