import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useRegisterUserMutation } from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { UserForm } from "./UserForm";

export const CreateUser = () => {
  const [registerUser] = useRegisterUserMutation({
    refetchQueries: ["GetCustomerUsers"],
    onError: (error) => console.log(error),
  });
  const customerId = useContext(CustomerContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  return (
    <>
      <Button variant="outline" bg="white" onClick={onOpen}>
        Créer
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inviter un utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserForm
              handleSubmit={(fields) => {
                registerUser({
                  variables: {
                    email: fields.email,
                    customerId: customerId || "",
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    profile: fields.profile,
                  },
                }).then((result) => {
                  result.errors
                    ? toast({
                        title: "erreur lors de la mise à jour.",
                        status: "error",
                        description: (result.errors as any).message,
                        isClosable: true,
                      })
                    : onClose();
                });
              }}
              create
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button type="submit" colorScheme="orange" form="user-form">
              Inviter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
