import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import moment from "moment";
import {
  useGetDisplayComputerQuery,
  useUpdateDisplayComputerMutation,
} from "../../generated/graphql";
import { useClock } from "../../libs/clock";
import { MediaRotationLine } from "./MediaRotationLine";
import { ErrorsLine } from "./ErrorsLine";
import { ComputerStatsLine } from "./ComputerStatsLine";

type ComputerDetailProps = {
  computerId: string;
};

const DescriptionItemStyle = {
  border: "1px solid",
  borderColor: "gray.100",
};
export const ComputerDetail: FC<ComputerDetailProps> = ({ computerId }) => {
  const { data, loading } = useGetDisplayComputerQuery({
    variables: { id: computerId },
  });
  const [updateComputer] = useUpdateDisplayComputerMutation({
    refetchQueries: ["GetDisplayComputersStatus"],
  });

  const now = useClock(1000 * 60);
  const startDate = moment(now).subtract(1, "days").toISOString();
  const designation =
    data?.displayComputer?.displaycomputeradminfields?.designation;
  const informations =
    data?.displayComputer?.displaycomputeradminfields?.informations;

  return loading ? (
    <Spinner />
  ) : (
    <VStack align={"flex-start"} p="12px" height="100%">
      <HStack spacing="24px">
        <Text fontWeight={"bold"} color="gray.700">
          {data?.displayComputer?.name}
        </Text>
        <FormControl display="flex" alignItems="center" width="170px">
          <FormLabel htmlFor="is-computer-active" mb="0">
            Opérationel ?
          </FormLabel>
          <Switch
            id="is-computer-active"
            isChecked={data?.displayComputer?.isActive}
            onChange={(e) => {
              updateComputer({
                variables: {
                  input: {
                    id: computerId,
                    isActive: e.target.checked,
                  },
                },
              });
            }}
          />
        </FormControl>
      </HStack>
      <Grid
        h="100px"
        width="100%"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap={0}
      >
        <GridItem
          rowSpan={1}
          colSpan={1}
          bg="gray.50"
          p="12px"
          {...DescriptionItemStyle}
          borderBottomColor="gray.300"
          borderRightColor="gray.300"
        >
          <Center height="100%" justifyContent={"end"}>
            AnyDesk
          </Center>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={1}
          bg="white"
          p="12px"
          {...DescriptionItemStyle}
          borderBottomColor="gray.300"
          borderRightColor="gray.300"
        >
          <Center height="100%" justifyContent={"start"}>
            {data?.displayComputer?.displaycomputeradminfields?.anydesk}
          </Center>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={1}
          bg="gray.50"
          p="12px"
          {...DescriptionItemStyle}
          borderRightColor="gray.300"
        >
          <Center height="100%" justifyContent={"end"}>
            Serial Number
          </Center>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={1}
          bg="white"
          p="12px"
          {...DescriptionItemStyle}
          borderRightColor="gray.300"
        >
          <Center height="100%" justifyContent={"start"}>
            {data?.displayComputer?.displaycomputeradminfields?.serialNumber}
          </Center>
        </GridItem>
      </Grid>
      {designation && <Text>{designation}</Text>}
      {informations && <Text>{informations}</Text>}
      <Flex flex={1} flexDir="column" width="100%">
        <Box minH="200px">
          <MediaRotationLine computerId={computerId} startDate={startDate} />
        </Box>
        <Box minH="200px">
          <ErrorsLine computerId={computerId} startDate={startDate} />
        </Box>
        <Box minH="200px">
          <ComputerStatsLine computerId={computerId} startDate={startDate} />
        </Box>
      </Flex>
    </VStack>
  );
};
