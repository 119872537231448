import { Box } from "@chakra-ui/layout";
import { Route, Switch, useRouteMatch } from "react-router";
import { UserList } from "./UserList";

export const UsersPage = () => {
  const match = useRouteMatch();
  return (
    <Box p="16px">
      <Switch>
        <Route path={`${match.url}/`} exact component={UserList} />
        {/* <Route path={`${match.url}/users/:userId`} exact component={} /> */}
      </Switch>
    </Box>
  );
};
