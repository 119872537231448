import {
  Button,
  MenuItem,
  MenuItemProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import {
  MediaMediaContentContentTypeChoices,
  useUpdateWidgetMediaMutation,
  WidgetMediaNode,
} from "../../../generated/graphql";
import { MediaImageForm } from "../../Media/MediaImageForm";
import { MediaSubmitHandler } from "../../Media/MediaTypes";
import { MediaVideoForm } from "../../Media/MediaVideoForm";
import { MediaWebpageForm } from "../../Media/MediaWebpageForm";
import {
  dumpMediaMetaData,
  parseMediaMetaData,
  toDate,
  toISOString,
} from "../metaData";

type MediaUpdateProps = MenuItemProps & {
  widgetMedia: WidgetMediaNode;
};

export const MediaUpdate: FunctionComponent<MediaUpdateProps> = ({
  widgetMedia,
  children,
  ...menuItemProps
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [updateWidgetMedia] = useUpdateWidgetMediaMutation();
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  const handleSubmit: MediaSubmitHandler = async (fields) => {
    await updateWidgetMedia({
      variables: {
        input: {
          id: widgetMedia.id,
          data: dumpMediaMetaData({
            order: mediaMetaData.order,
            duration: fields.duration,
            object_fit: fields.objectFit,
            start_date: toDate(fields.startDate),
            end_date: toDate(fields.endDate),
          }),
        },
      },
    });
    onClose();
  };
  return (
    <>
      <MenuItem {...menuItemProps} onClick={onOpen}>
        {children}
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editer un média</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignSelf="flex-start" spacing="16px">
              {widgetMedia.mediaContent?.contentType ===
                MediaMediaContentContentTypeChoices.Im && (
                <MediaImageForm
                  onSubmitted={handleSubmit}
                  defaultValues={{
                    media: widgetMedia.mediaContent,
                    objectFit: mediaMetaData.object_fit,
                    duration: mediaMetaData.duration,
                    startDate: toISOString(mediaMetaData.start_date),
                    endDate: toISOString(mediaMetaData.end_date),
                  }}
                />
              )}
              {widgetMedia.mediaContent?.contentType ===
                MediaMediaContentContentTypeChoices.Vd && (
                <MediaVideoForm
                  onSubmitted={handleSubmit}
                  defaultValues={{
                    media: widgetMedia.mediaContent,
                    objectFit: mediaMetaData.object_fit,
                    duration: mediaMetaData.duration,
                    startDate: toISOString(mediaMetaData.start_date),
                    endDate: toISOString(mediaMetaData.end_date),
                  }}
                />
              )}
              {widgetMedia.mediaContent?.contentType ===
                MediaMediaContentContentTypeChoices.If && (
                <MediaWebpageForm
                  onSubmitted={handleSubmit}
                  defaultValues={{
                    media: widgetMedia.mediaContent,
                    objectFit: mediaMetaData.object_fit,
                    duration: mediaMetaData.duration,
                    startDate: toISOString(mediaMetaData.start_date),
                    endDate: toISOString(mediaMetaData.end_date),
                  }}
                />
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button mr={3} colorScheme="orange" type="submit" form="media-form">
              Sauvegarder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
