import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import {
  DisplayComputerListItemFragment,
  useUpdateAdminFieldsMutation,
} from "../../generated/graphql";

type AdminFieldsFormProps = {
  displayComputer: DisplayComputerListItemFragment;
};
export const AdminFieldsForm: FC<AdminFieldsFormProps> = ({
  displayComputer,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const [updateAdminFields] = useUpdateAdminFieldsMutation({
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
    refetchQueries: ["GetDisplayComputer"],
  });
  const onSubmit = handleSubmit(async (formData) => {
    setIsUpdating(true);
    await updateAdminFields({
      variables: {
        id: displayComputer.displaycomputeradminfields!.id,
        anydesk: formData.anydesk,
        informations: formData.informations,
        serialNumber: formData.serialNumber,
        designation: formData.designation,
      },
    });

    setIsUpdating(false);
  });

  return displayComputer?.displaycomputeradminfields ? (
    <VStack alignItems="flex-start" maxHeight="100%" flexGrow={1}>
      <Text fontSize="md" fontWeight="bold" color="gray.600" lineHeight="40px">
        Administration
      </Text>
      <Box
        minHeight="300px"
        borderRadius="md"
        bg="white"
        padding="24px 24px"
        width="100%"
      >
        <form onSubmit={onSubmit}>
          <VStack alignItems="flex-start" spacing="4">
            <FormControl id="anydesk">
              <FormLabel>AnyDesk</FormLabel>
              <Input
                type="text"
                bg="white"
                {...register("anydesk")}
                defaultValue={
                  displayComputer.displaycomputeradminfields?.anydesk
                }
              />
            </FormControl>
            <FormControl id="informations">
              <FormLabel>Informations</FormLabel>
              <Textarea
                bg="white"
                {...register("informations")}
                defaultValue={
                  displayComputer.displaycomputeradminfields?.informations
                }
              />
            </FormControl>
            <FormControl id="serial_number">
              <FormLabel>Serial Number</FormLabel>
              <Input
                type="text"
                bg="white"
                {...register("serialNumber")}
                defaultValue={
                  displayComputer.displaycomputeradminfields?.serialNumber
                }
              />
            </FormControl>
            <FormControl id="designation">
              <FormLabel>Designation</FormLabel>
              <Input
                type="text"
                bg="white"
                {...register("designation")}
                defaultValue={
                  displayComputer.displaycomputeradminfields?.designation
                }
              />
            </FormControl>

            <Button
              leftIcon={isUpdating ? <Spinner /> : <FiSave />}
              type="submit"
            >
              Enregistrer
            </Button>
          </VStack>
        </form>
      </Box>
    </VStack>
  ) : null;
};
