import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  StackDivider,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { FiArrowDown, FiGitCommit } from "react-icons/fi";
import { VersionRadioGroup } from "../../components/VersionsRadioGroup";

import {
  PlayerSoftwareVersionFragment,
  ComputerSoftwareVersionFragment,
  useAssignPlayerVersionToComputerMutation,
  useGetSoftwareVersionsQuery,
} from "../../generated/graphql";
import { getSoftwareVersionColor } from "../../libs/softwareVersion+";

type PlayerDeploymentDrawerProps = ButtonProps & {
  selectedIds: string[];
  computers: ComputerSoftwareVersionFragment[];
};

export const PlayerDeploymentDrawer: FC<PlayerDeploymentDrawerProps> = ({
  selectedIds,
  computers,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const { data: dataSoftwareVersions } = useGetSoftwareVersionsQuery();
  const [assignPlayerVersionToComputer] =
    useAssignPlayerVersionToComputerMutation({
      onError: (error) => {
        toast({
          title: "erreur lors du deploiement.",
          status: "error",
          description: error.message,
          isClosable: true,
        });
      },
    });
  const playerVersions = useMemo(
    () =>
      dataSoftwareVersions?.playerVersionList?.edges
        .map((edge) => edge?.node)
        .filter(
          (version): version is PlayerSoftwareVersionFragment => !!version
        ) || [],
    [dataSoftwareVersions]
  );
  const selectedComputers = useMemo(
    () => computers.filter((customer) => selectedIds.includes(customer.id)),
    [computers, selectedIds]
  );
  const selectionSignature = selectedIds.join(",");
  const selectedVersions = useMemo(
    () =>
      selectedComputers
        .map((customer) => customer.playerVersion)
        .filter((item, pos, self) => self.indexOf(item) === pos)
        .filter(
          (version): version is PlayerSoftwareVersionFragment => !!version
        ),
    [computers, selectionSignature]
  );

  const [selectedNextVersion, setSelectedNextVersion] = useState<string | null>(
    null
  );

  const [deploymentLoading, setDeploymentLoading] = useState(false);

  const deployVersion = useCallback(async () => {
    console.log("deployVersion");
    setDeploymentLoading(true);
    if (selectedNextVersion === null) {
      setDeploymentLoading(false);
      return;
    }
    for (const computerId of selectedIds) {
      await assignPlayerVersionToComputer({
        variables: {
          computerId,
          playerVersionId: selectedNextVersion,
        },
      });
    }
    setDeploymentLoading(false);
    onClose();
  }, [
    selectedIds,
    selectedNextVersion,
    onClose,
    assignPlayerVersionToComputer,
  ]);

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} {...props}>
        {props.children}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="lg"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Déploiement d'une version Player</DrawerHeader>

          <DrawerBody>
            <VStack width="100%" spacing="24px">
              <VStack width="100%" spacing={"24px"}>
                {selectedVersions.map((selectedVersion) => (
                  <Box
                    key={selectedVersion.id}
                    borderRadius="lg"
                    width="100%"
                    borderWidth="1px"
                    p="8px"
                  >
                    <HStack>
                      <FiGitCommit />
                      <Box>Version {selectedVersion.number}</Box>
                      <Tag
                        colorScheme={getSoftwareVersionColor(
                          selectedVersion.version
                        )}
                      >
                        {selectedVersion.version}
                      </Tag>
                      <Tag>{selectedVersion.dockerImageTag}</Tag>
                    </HStack>
                    <Text color="gray.600">
                      {new Date(selectedVersion.date).toDateString()}
                    </Text>
                    <Center height="24px">
                      <Divider />
                    </Center>
                    <VStack
                      divider={<StackDivider borderColor="gray.200" />}
                      alignItems="flex-start"
                      maxHeight="120px"
                      overflowY="scroll"
                    >
                      {selectedComputers
                        .filter(
                          (customer) =>
                            customer.playerVersion?.id === selectedVersion.id
                        )
                        .map((customer) => (
                          <Box key={customer.id} color="gray.500">
                            {customer.name}
                          </Box>
                        ))}
                    </VStack>
                  </Box>
                ))}
              </VStack>
              <HStack width="100%" spacing={"24px"}>
                <FiArrowDown size="32px" />
                <Text>Choisir la nouvelle version:</Text>
              </HStack>
              <VStack alignItems={"flex-start"} width="100%">
                <VersionRadioGroup
                  versions={playerVersions.filter(
                    (version) =>
                      !selectedVersions.map((v) => v.id).includes(version.id)
                  )}
                  onSelect={(versionId) => setSelectedNextVersion(versionId)}
                />
              </VStack>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button
              isLoading={deploymentLoading}
              colorScheme="orange"
              onClick={deployVersion}
              disabled={selectedNextVersion === null || deploymentLoading}
            >
              Déployer
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
