import { WidgetMediaNode } from "../../../generated/graphql";

export const IframePreview = (widgetMedia: WidgetMediaNode) => {
  return (
    <iframe
      height="100%"
      width="100%"
      title={widgetMedia.id}
      src={widgetMedia.mediaContent.signedUrl || ""}
      frameBorder="0"
      allow="encrypted-media"
    />
  );
};
