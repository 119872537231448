// @ts-nocheck
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  VStack,
  HStack,
  IconButton,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Checkbox,
} from "@chakra-ui/react";
import { SearchIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useMountedLayoutEffect,
} from "react-table";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { memo, useState } from "react";
import { useMemo } from "react";

type DataTableProps = {
  columns: object[];
  data: object[];
  initialState: object;
  onRowSelection?: (rowIds: string[]) => void;
  actionButtons?: JSX.Element[];
};

export const DataTable: FC<DataTableProps> = memo(
  ({ columns, data, initialState, onRowSelection, actionButtons }) => {
    const columnsWithSelection = useMemo(
      () => (onRowSelection ? [SelectionColumn, ...columns] : columns),
      [columns, onRowSelection]
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      visibleColumns,
      setGlobalFilter,
      globalFilter,
      state: { pageIndex, selectedRowIds },
    } = useTable(
      {
        initialState,
        columns: columnsWithSelection,
        data,
        getRowId(originalRow, relativeIndex, parent?) {
          return originalRow.id;
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect
    );
    useMountedLayoutEffect(() => {
      const selectedKeys = Object.entries(selectedRowIds)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
      onRowSelection && onRowSelection(selectedKeys);
    }, [selectedRowIds, onRowSelection]);

    return (
      <VStack>
        <Table {...getTableProps()}>
          <Thead>
            <Tr>
              <Th colSpan={visibleColumns.length - 1}>
                <GlobalSearchInput
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </Th>
              <Th>
                <HStack>
                  {actionButtons?.map((button, index) => (
                    <Box key={index}>{button}</Box>
                  ))}
                </HStack>
              </Th>
            </Tr>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                    maxWidth={column.maxWidth}
                  >
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                    >
                      {cell.render("Cell")}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {pageOptions.length > 1 && (
          <HStack>
            <IconButton
              icon={<FiChevronsLeft />}
              aria-label="Première page"
              onClick={() => gotoPage(0)}
            />
            <IconButton
              icon={<FiChevronLeft />}
              aria-label="Prochaine page"
              onClick={() => previousPage()}
            />
            <Box>
              {" "}
              Page {pageIndex + 1} / {pageOptions.length}{" "}
            </Box>
            <IconButton
              icon={<FiChevronRight />}
              aria-label="Prochaine page"
              onClick={() => nextPage()}
            />
            <IconButton
              icon={<FiChevronsRight />}
              aria-label="Dernière page"
              onClick={() => gotoPage(pageCount - 1)}
            />
          </HStack>
        )}
      </VStack>
    );
  }
);

const SelectionColumn = {
  id: "selection",
  // The header can use the table's getToggleAllRowsSelectedProps method
  // to render a checkbox
  Header: ({
    isAllRowsSelected,
    toggleAllRowsSelected,
    selectedFlatRows,
  }: any) => (
    <Checkbox
      isChecked={isAllRowsSelected}
      isIndeterminate={!isAllRowsSelected && selectedFlatRows.length > 0}
      onChange={(e) => {
        toggleAllRowsSelected(e.target.checked);
      }}
    />
  ),
  // The cell can use the individual row's getToggleRowSelectedProps method
  // to the render a checkbox
  Cell: (cell: any) => (
    <Checkbox
      isChecked={cell.row.isSelected}
      isIndeterminate={cell.row.isSomeSelected}
      onChange={(e) => {
        cell.row.toggleRowSelected(e.target.checked);
      }}
    />
  ),
};

const GlobalSearchInput = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup maxWidth="420px" alignSelf="center">
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="gray.300" />}
      />
      <Input
        type="search"
        placeholder="Rechercher"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </InputGroup>
  );
};
