import { Button, ButtonProps } from "@chakra-ui/button";
import { FunctionComponent, useRef } from "react";

interface UploadButtonProps extends ButtonProps {
  accept?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onFileChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}
export const UploadButton: FunctionComponent<UploadButtonProps> = ({
  accept,
  children,
  onClick,
  onFileChange,
  ...buttonProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Button
      {...buttonProps}
      onClick={(event) => {
        inputRef.current?.click();
        onClick && onClick(event);
      }}
    >
      {children}
      <input
        type="file"
        accept={accept}
        multiple={true}
        onChange={onFileChange}
        ref={inputRef}
        style={{ display: "none" }}
      ></input>
    </Button>
  );
};
