import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { FiMail, FiPhone } from "react-icons/fi";

export const Contact = () => {
  return (
    <>
      <Text fontSize="lg" fontWeight="bold" color="gray.700" lineHeight="40px">
        Nous contacter:
      </Text>
      <HStack spacing={2}>
        <FiPhone />
        <Text color="gray.600">+33 (0)5 67 76 21 00</Text>
      </HStack>
      <HStack spacing={2}>
        <FiMail />
        <Text color="gray.600">support@govisupro.zendesk.com</Text>
      </HStack>
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="gray.700"
        lineHeight="40px"
        marginTop="3"
      >
        Horaires d'ouverture:
      </Text>
      <Text color="gray.600">Du lundi au vendredi: de 9h à 18h</Text>
    </>
  );
};
