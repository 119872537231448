import { Box, Flex, VStack } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";

type HeaderContentBottomBarProps = {
  header: React.ReactNode;
  bar: React.ReactNode;
};

export const HeaderContentBottomBar: FunctionComponent<
  HeaderContentBottomBarProps
> = ({ children, header, bar }) => {
  return (
    <VStack margin="0" alignItems={"stretch"}>
      <Flex height={"75px"} bg="white" alignItems="center">
        {header}
      </Flex>
      <Box height="calc(100vh - 170px)" overflowY={"scroll"}>
        {children}
      </Box>
      <Box height="70px">{bar}</Box>
    </VStack>
  );
};
