import { DisplayStatusType } from "../generated/graphql";

export const DisplayStatusMetric = {
  [DisplayStatusType.Disabled]: 0,
  [DisplayStatusType.Standby]: 1,
  [DisplayStatusType.Live]: 2,
  [DisplayStatusType.Empty]: 3,
  [DisplayStatusType.Connected]: 4,
  [DisplayStatusType.Disconnected]: 5,
  [DisplayStatusType.Error]: 6,
};
export function compareComputerStatus(
  statusA: DisplayStatusType,
  statusB: DisplayStatusType
) {
  if (DisplayStatusMetric[statusA] < DisplayStatusMetric[statusB]) {
    return -1;
  } else if (DisplayStatusMetric[statusA] > DisplayStatusMetric[statusB]) {
    return 1;
  } else {
    return 0;
  }
}
