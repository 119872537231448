import { Button } from "@chakra-ui/button";
import { useClipboard, useDisclosure } from "@chakra-ui/hooks";
import { HStack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { useToast } from "@chakra-ui/toast";
import { FunctionComponent } from "react";
import { FiKey } from "react-icons/fi";
import {
  DisplayComputerListItemFragment,
  DisplayItemFragment,
  useCreateRegistrationPinMutation,
  useDeleteRegistrationMutation,
} from "../../generated/graphql";

type NewKeyDialogProps = {
  display: DisplayItemFragment;
  computer: DisplayComputerListItemFragment;
};

export const NewKeyDialog: FunctionComponent<NewKeyDialogProps> = ({
  display,
  computer,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [createKey, data] = useCreateRegistrationPinMutation({
    refetchQueries: ["GetDisplayComputers"],
    onError: (error) =>
      toast({
        title: "erreur lors de la création de la clé",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [deleteKey] = useDeleteRegistrationMutation({
    refetchQueries: ["GetDisplayComputers"],
  });
  const { hasCopied, onCopy } = useClipboard(
    data.data?.createRegistrationPin?.pinCode || ""
  );

  return (
    <>
      <Button
        aria-label={
          display?.displaycredential ? "Recréer une clé" : "Créer une clé"
        }
        title={display?.displaycredential ? "Recréer une clé" : "Créer une clé"}
        leftIcon={<FiKey />}
        color="gray.500"
        onClick={async () => {
          if (display?.displaycredential) {
            await deleteKey({
              variables: { displayId: display.id },
            });
          }
          const result = await createKey({
            variables: { displayId: display.id },
          });
          if (result.data) {
            onOpen();
          }
        }}
      >
        {display?.displaycredential ? "Recréer" : "Associer"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Clé de registration pour {computer?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <PinInput
                value={data.data?.createRegistrationPin?.pinCode || ""}
                type="alphanumeric"
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button colorScheme="orange" onClick={onCopy}>
              {hasCopied ? "Copié" : "Copier"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
