import { v4 as uuidv4 } from "uuid";
import { FiPlayCircle } from "react-icons/fi";
import { WidgetType } from "@govisupro/studio";
import { PlaylistContextProvider } from "./context";
import { ControlPanel } from "./ControlPanel";
import { PreviewPlayer } from "./PlayerPreview";
import { AddPreview } from "./AddPlayer/AddPreview";

export const PlaylistWidget: WidgetType = {
  id: "5f0f8f8f-f9b8-4f7b-b8b8-f9b8f9b8f9b8",
  name: "Playlist",
  icon: <FiPlayCircle />,
  type: "playlist",
  inHeadbar: true,
  description: "A playlist of medias",
  config: null,
  createdAt: "2022-05-24T16:09:00.000Z",
  updatedAt: "2022-05-24T16:09:00.000Z",
  isActive: true,
  isDeleted: false,
  scope: ["media"],
  factory: () => ({
    id: uuidv4(),
    data: {},
  }),
  controlPanel: ControlPanel,
  playerPreview: PreviewPlayer,
  addWidgetPreview: [AddPreview],
  context: PlaylistContextProvider,
  playerDevice: () => <></>,
  contextDevice: () => <></>,
  load: null,
};
