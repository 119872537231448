import {
  Text,
  Wrap,
  WrapItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Flex,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import {
  CustomerNode,
  useCreateCustomerMutation,
  useGetCustomersQuery,
  useGetMeQuery,
} from "../../generated/graphql";
import { useState } from "react";
import { CustomerCard } from "../../components/CustomerCard";

export const ComposerList = () => {
  const history = useHistory();
  const user = useGetMeQuery();
  const { data } = useGetCustomersQuery();
  const [createCustomer] = useCreateCustomerMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  return (
    <Flex direction="column" width="100%" height="100%" padding="32px">
      <VStack spacing="8px" alignItems="flex-start">
        <Text fontSize="md" color="gray.700" lineHeight="32px">
          Composers
        </Text>
        <Wrap>
          {data?.customers?.edges.map((customer) => (
            <WrapItem key={customer?.node?.id}>
              {customer?.node && (
                <CustomerCard customer={customer.node as CustomerNode} />
              )}
            </WrapItem>
          ))}
          {user?.data?.me?.isStaff && (
            <>
              <Button
                leftIcon={<AddIcon />}
                w="128px"
                h="128px"
                onClick={onOpen}
              >
                Créer
              </Button>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Ajouter un Client</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl width="180px">
                      <FormLabel>Nom</FormLabel>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                      />
                    </FormControl>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme="orange"
                      mr={3}
                      disabled={name === ""}
                      onClick={async () => {
                        const customer = await createCustomer({
                          variables: {
                            name,
                          },
                        });
                        if (customer.data?.createCustomer?.customer?.id) {
                          history.push(
                            `/backoffice/${customer.data?.createCustomer?.customer?.id}`
                          );
                        }
                      }}
                    >
                      Créer
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                      Annuler
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}
        </Wrap>
      </VStack>
    </Flex>
  );
};
