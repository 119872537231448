import { Button } from "@chakra-ui/button";
import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { HStack, VStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import {
  useCreatePublicationMutation,
  useCreatePublicationScreenMutation,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { PublicationsTable } from "./PublicationsTable";

export const PublicationHomePage = () => {
  const toast = useToast();
  const [createPublication] = useCreatePublicationMutation({
    refetchQueries: ["GetCustomerPublications"],
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [createPublicationScreen] = useCreatePublicationScreenMutation({
    refetchQueries: ["GetCustomerPublications"],
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const customerId = useContext(CustomerContext);
  const history = useHistory();
  const location = useLocation();
  return (
    <VStack alignItems="flex-start">
      <DataBreadcrumb items={[{ name: "Publications" }]} />
      <HStack spacing="32px">
        <InputGroup flexGrow={1} maxWidth="530px" alignSelf="center">
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input type="search" placeholder="Publication name..." bg="white" />
        </InputGroup>
        {customerId && (
          <Button
            variant="outline"
            bg="white"
            onClick={async () => {
              const result = await createPublication({
                variables: {
                  customerId: customerId,
                  name: "Nouvelle Publication",
                },
              });
              if (result.data?.createPublication?.publication) {
                const resultPage = await createPublicationScreen({
                  variables: {
                    publicationId: result.data.createPublication.publication.id,
                  },
                });
                if (
                  resultPage.data?.createPublicationScreen?.publicationScreen
                ) {
                  history.push(
                    `${location.pathname}/publication/${result.data.createPublication.publication.id}`
                  );
                }
                if (resultPage.errors) {
                  toast({
                    title: "erreur lors de la création.",
                    status: "error",
                    description: resultPage.errors[0].message,
                    isClosable: true,
                  });
                }
              }
              if (result.errors) {
                toast({
                  title: "erreur lors de la création.",
                  status: "error",
                  description: result.errors[0].message,
                  isClosable: true,
                });
              }
            }}
          >
            Créer
          </Button>
        )}
      </HStack>
      <PublicationsTable />
    </VStack>
  );
};
