import { HStack, Tag, Wrap, WrapItem } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useMemo } from "react";
import { FiGitCommit, FiPlusSquare } from "react-icons/fi";
import { Cell } from "react-table";
import { DataTable } from "../../components/DataTable";
import {
  CustomerSoftwareVersionFragment,
  useGetCustomersSoftwareVersionsQuery,
} from "../../generated/graphql";
import { getSoftwareVersionColor } from "../../libs/softwareVersion+";
import { ActivateWidgetsDrawer } from "./ActivateWidgetsDrawer";
import { BackOfficeDeploymentDrawer } from "./BackOfficeDeployment";

export const BackOfficeVersions = () => {
  const { data: dataCustomers } = useGetCustomersSoftwareVersionsQuery();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const customers = useMemo(
    () =>
      dataCustomers?.customers?.edges
        .map((edge) => edge?.node)
        .filter((item): item is CustomerSoftwareVersionFragment => !!item) ||
      [],
    [dataCustomers]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Composer",
        id: "composer",
        accessor: (row: CustomerSoftwareVersionFragment) => row.name,
      },
      {
        Header: "Backoffice Version",
        id: "backoffice-version",
        sortType: (
          rowA: CustomerSoftwareVersionFragment,
          rowB: CustomerSoftwareVersionFragment
        ) => {
          return (
            (rowA.backofficeVersion?.number || 0) >
            (rowB.backofficeVersion?.number || 0)
          );
        },
        accessor: (row: CustomerSoftwareVersionFragment) =>
          row.backofficeVersion?.version,
        Cell: (cell: Cell<CustomerSoftwareVersionFragment>) => {
          const backofficeVersion = cell.row.original.backofficeVersion;
          if (!backofficeVersion) {
            return null;
          }
          return (
            <HStack>
              <Tag
                colorScheme={getSoftwareVersionColor(backofficeVersion.version)}
              >
                {backofficeVersion.version}
              </Tag>
              <Tag>{backofficeVersion.number}</Tag>
              {!!backofficeVersion.dockerImageTag && (
                <Tag>{backofficeVersion.dockerImageTag}</Tag>
              )}
            </HStack>
          );
        },
      },
      {
        Header: "Widgets",
        id: "widgets-activated",
        accessor: (row: CustomerSoftwareVersionFragment) =>
          row.activatedWidgets,
        Cell: (cell: Cell<CustomerSoftwareVersionFragment>) => {
          const activatedWidgets = cell.row.original.activatedWidgets.edges.map(
            (edge) => edge?.node
          );
          return (
            <Wrap maxWidth="320px" minWidth="240px">
              {activatedWidgets.map((widget) => (
                <WrapItem key={widget?.id}>
                  <Tag>{widget?.name}</Tag>
                </WrapItem>
              ))}
            </Wrap>
          );
        },
      },
    ],
    []
  );
  const initialState = useMemo(
    () => ({
      pageSize: 25,
    }),
    []
  );
  const onRowSelection = useCallback(
    (selectedRowIds: string[]) => setSelectedIds(selectedRowIds),
    []
  );

  const tableData = useMemo(() => customers, [customers]);

  return (
    <DataTable
      data={tableData}
      columns={columns}
      initialState={initialState}
      onRowSelection={onRowSelection}
      actionButtons={[
        <BackOfficeDeploymentDrawer
          selectedIds={selectedIds}
          customers={customers}
          leftIcon={<FiGitCommit />}
          disabled={selectedIds.length === 0}
        >
          Déployer Version
        </BackOfficeDeploymentDrawer>,
        <ActivateWidgetsDrawer
          selectedIds={selectedIds}
          customers={customers}
          leftIcon={<FiPlusSquare />}
          disabled={selectedIds.length === 0}
        >
          Activer Widget
        </ActivateWidgetsDrawer>,
      ]}
    />
  );
};
