import { Flex } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";

type HeaderContentProps = {
  header: React.ReactNode;
};

export const HeaderContent: FunctionComponent<HeaderContentProps> = ({
  children,
  header,
}) => {
  return (
    <Flex flexDir="column" height="100%" margin="0">
      <Flex height={"103px"} bg="white" alignItems="center">
        {header}
      </Flex>
      <Flex height="100%">{children}</Flex>
    </Flex>
  );
};
