import { Button } from "@chakra-ui/button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { Tag } from "@chakra-ui/tag";
import { useToast } from "@chakra-ui/toast";
import { useContext } from "react";
import { FiRepeat, FiSunrise, FiSunset } from "react-icons/fi";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import { ConfirmButton } from "../../components/ConfirmButton";
import {
  useGetPublicationAllocationsQuery,
  useCreatePublicationAllocationMutation,
  useUpdatePublicationAllocationMutation,
  useDeletePublicationAllocationMutation,
  PublicationAllocationFragment,
} from "../../generated/graphql";
import { getNumberOfAllocatedDisplays } from "../../libs/publicationHelpers";
import { CustomerContext } from "../../screens/NavigationContext";

type PublicationListParams = {
  publicationId: string;
};

export const PublishList = () => {
  const { publicationId } = useParams<PublicationListParams>();
  const customerId = useContext(CustomerContext);
  const history = useHistory();
  const match = useRouteMatch();
  const { data } = useGetPublicationAllocationsQuery({
    variables: { publicationId: publicationId || "" },
  });
  const [createAllocation] = useCreatePublicationAllocationMutation({
    refetchQueries: ["GetPublicationAllocations"],
  });

  const [updateAllocation] = useUpdatePublicationAllocationMutation({
    refetchQueries: ["GetPublicationAllocations"],
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });

  const [deleteAllocation] = useDeletePublicationAllocationMutation({
    refetchQueries: ["GetPublicationAllocations"],
  });
  const toast = useToast();

  const publication = data?.publication;
  if (!publication) {
    return null;
  }

  const publicationAllocations = publication.publicationallocationSet.edges
    .map((edge) => edge?.node)
    .filter((item): item is PublicationAllocationFragment => !!item);

  return (
    <VStack alignItems="flex-start" spacing="18px">
      <Flex justifyContent="space-between" width="100%">
        <DataBreadcrumb
          items={[
            {
              name: "Publications",
              url: `/backoffice/${customerId}/publications`,
            },
            {
              name: data?.publication?.name || "",
              url: `/backoffice/${customerId}/publications/publication/${data?.publication?.id}`,
            },
            {
              name: "Liste de diffusion",
            },
          ]}
        />
        <Button
          variant="outline"
          bg="white"
          onClick={async () => {
            const allocation = await createAllocation({
              variables: { publicationId: publicationId },
            });
            if (Array.isArray(allocation.errors)) {
              toast({
                title: "Erreur: Impossible de créer une allocation",
                status: "error",
                isClosable: true,
              });
            } else if (
              allocation.data?.createPublicationAllocation
                ?.publicationAllocation
            ) {
              history.push(
                `${match.url}/${allocation.data.createPublicationAllocation.publicationAllocation.id}/${data?.publication?.publicationallocationSet?.edges.length}`
              );
            } else {
              toast({
                title: "Une erreur inattendue c'est produite.",
                status: "error",
                isClosable: true,
              });
            }
          }}
        >
          Ajouter une diffusion
        </Button>
      </Flex>
      <Box
        borderRadius="12px"
        padding="18px"
        bg="white"
        minWidth="512px"
        minHeight="120px"
      >
        {publicationAllocations.length === 0 ? (
          <Text fontSize="lg" color="gray.500">
            Aucune diffusion n'est encore configurée pour cette publication.
          </Text>
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>Période d'affichage</Th>
                <Th>Horaires Journaliers</Th>
                <Th>Ecrans</Th>
                <Th>Active?</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {publicationAllocations.map((allocation, index) => (
                <Tr key={"tr-" + index}>
                  <Td>
                    <HStack>
                      <Tag>
                        {allocation.startDate
                          ? new Date(allocation.startDate).toLocaleString()
                          : "Toujours"}
                      </Tag>
                      <ArrowForwardIcon />
                      <Tag>
                        {allocation.endDate
                          ? new Date(allocation.endDate).toLocaleString()
                          : "Toujours"}
                      </Tag>
                    </HStack>
                  </Td>
                  <Td>
                    {!allocation.startTime && !allocation.endTime ? (
                      <HStack>
                        <FiRepeat />
                        <Tag>H24</Tag>
                      </HStack>
                    ) : (
                      <HStack>
                        <FiSunrise />
                        <Tag>
                          {allocation.startTime
                            ? allocation.startTime.substring(0, 5)
                            : "Début"}
                        </Tag>
                        <ArrowForwardIcon />
                        <Tag>
                          {allocation.endTime
                            ? allocation.endTime.substring(0, 5)
                            : "Fin"}
                        </Tag>
                        <FiSunset />
                      </HStack>
                    )}
                  </Td>
                  <Td>
                    <Text color="gray.600">
                      {getNumberOfAllocatedDisplays([allocation])} écrans
                    </Text>
                  </Td>
                  <Td>
                    <Switch
                      defaultChecked={allocation.active}
                      onChange={async (e) => {
                        await updateAllocation({
                          variables: {
                            id: allocation.id,
                            active: e.target.checked,
                          },
                        });
                        toast({
                          title: `La diffusion est maintenant ${
                            e.target.checked ? "active" : "désactivée"
                          }.`,
                          status: "success",
                          isClosable: true,
                        });
                      }}
                    />
                  </Td>
                  <Td>
                    <HStack>
                      <Button
                        onClick={() =>
                          history.push(`${match.url}/${allocation.id}/${index}`)
                        }
                      >
                        Modifier
                      </Button>
                      <ConfirmButton
                        confirmButtonText="Supprimer"
                        onConfirm={async () =>
                          await deleteAllocation({
                            variables: {
                              id: allocation.id,
                            },
                          })
                        }
                        bodyText="Êtes-vous sûr de vouloir supprimer cette diffusion?"
                      >
                        <Button>Supprimer</Button>
                      </ConfirmButton>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </VStack>
  );
};
