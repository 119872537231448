import { Spinner } from "@chakra-ui/react";
import { ResponsiveLine } from "@nivo/line";
import { FunctionComponent } from "react";
import { useRequestLokiQuery } from "../../generated/graphql";
import { prepareData } from "./helpers";

type MediaRotationLineProps = {
  startDate?: string;
  endDate?: string;
  computerId: string;
};

export const MediaRotationLine: FunctionComponent<MediaRotationLineProps> = ({
  startDate,
  endDate,
  computerId,
}) => {
  const queryMediaRotation = `sum by (computerName) (count_over_time ({job="platform", computerId=~"${computerId}"} | json  | message="Media Rotation" [5m]))`;
  const mediaRotationMetric = useRequestLokiQuery({
    variables: { query: queryMediaRotation, start: startDate, end: endDate },
  });
  const lineData = prepareData(
    mediaRotationMetric?.data?.requestLoki,
    "mediaRotation",
    60 * 5 * 1000
  );
  return lineData ? (
    <ResponsiveLine
      data={[lineData]}
      margin={{ top: 32, right: 24, bottom: 24, left: 32 }}
      xScale={{
        type: "time",
        precision: "hour",
        format: "native",
        min: new Date(startDate || ""),
        max: endDate ? new Date(endDate) : new Date(),
      }}
      xFormat="time: %H:%M"
      yScale={{
        type: "linear",
        stacked: false,
        nice: true,
      }}
      axisLeft={{
        // legend: "linear scale",
        legendOffset: 6,
        tickValues: 5,
      }}
      curve="step"
      axisBottom={{
        format: "%Hh",
        tickValues: "every 1 hours",
        // legend: "time scale",
        legendOffset: -12,
      }}
      enablePoints={false}
      useMesh={true}
      enableSlices={false}
      colors={["#97e3d5"]}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: -32,
          translateY: -24,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  ) : (
    <Spinner />
  );
};
