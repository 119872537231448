import { MediaMetaDataType } from "./metaData";

export const isMediaNotExpired = (mediaMetaData: MediaMetaDataType) =>
  mediaMetaData.end_date === null ||
  new Date(mediaMetaData.end_date).getTime() + 3600 * 1000 * 24 >=
    new Date().getTime();

export const isMediaReady = (mediaMetaData: MediaMetaDataType) =>
  mediaMetaData.start_date === null ||
  new Date(mediaMetaData.start_date).getTime() <= new Date().getTime();
