import { FunctionComponent, useContext, useRef, useState } from "react";
import html2canvas from "html2canvas";

import {
  LayoutItemType,
  Studio,
  PageMetaData,
  LayoutService,
} from "@govisupro/studio";
import { Box } from "@chakra-ui/react";

import { CustomerContext } from "../../screens/NavigationContext";
import {
  PublicationFragment,
  PublicationScreenFragment,
  useCreateLayoutItemMutation,
  useDeleteLayoutItemMutation,
  useUpdateLayoutItemMutation,
} from "../../generated/graphql";
import { useActivatedWidgets } from "./Widgets";
import { uploadFileToS3 } from "../../libs/fileHelpers";
import { fonts, useLoadFonts } from "../../libs/fontsHooks";
import { LayoutItemTypeToFragment, LayoutItemFragmentToType } from "./helpers";
import { PageService } from "@govisupro/studio/dist/studio/types";

type UpdatePublicationProps = {
  publication: PublicationFragment;
  screen: PublicationScreenFragment;
  onSelectPage: (index: number) => void;
  onCreatePage: () => void;
  onDeletePage: (index: number) => void;
  onDuplicatePage: (index: number) => void;
  onEditPage: (index: number) => void;
  isLocked: boolean;
  pageList: PageMetaData[];
  showAnimations: boolean;
};

export const UpdatePublication: FunctionComponent<UpdatePublicationProps> = ({
  publication,
  screen,
  onSelectPage,
  onCreatePage,
  onDeletePage,
  onDuplicatePage,
  onEditPage,
  isLocked,
  pageList,
  showAnimations,
}) => {
  useLoadFonts();
  const customerId = useContext(CustomerContext);

  const [selectedLayoutItemId, setSelectedLayoutItemId] = useState<
    string | null
  >(null);

  const [createLayoutItem] = useCreateLayoutItemMutation();
  const [updateLayoutItem] = useUpdateLayoutItemMutation();
  const [deleteLayoutItem] = useDeleteLayoutItemMutation();

  const layoutItems: LayoutItemType[] =
    screen.layoutitemSet.edges
      .map((edge) => edge?.node && LayoutItemFragmentToType(edge!.node))
      .filter((item): item is LayoutItemType => !!item) || [];

  const widgets = useActivatedWidgets(customerId || "");

  const studioContainerRef = useRef<HTMLDivElement>(null);

  const layoutService: LayoutService = {
    addLayoutItem: async (layoutItem) => {
      console.log(layoutItem);

      const result = await createLayoutItem({
        variables: {
          input: {
            screenId: screen.id,
            name: layoutItem.name,
            widgetName: layoutItem.widgetName,
            x: layoutItem.x,
            y: layoutItem.y,
            z: layoutItem.z,
            width: layoutItem.width,
            height: layoutItem.height,
            widgetData: JSON.stringify(layoutItem.widget.data),
            animations: JSON.stringify(layoutItem.animations),
          },
        },
        optimisticResponse: {
          createLayoutItem: {
            layoutItem: LayoutItemTypeToFragment(layoutItem),
            __typename: "CreateLayoutItemPayload",
          },
        },
        refetchQueries: ["GetPublication"],
      });

      if (!result.data?.createLayoutItem?.layoutItem) return;

      setSelectedLayoutItemId(result.data?.createLayoutItem?.layoutItem.id);
    },
    updateLayoutItem: async (item) => {
      const previousItem = layoutItems.find(
        (layoutItem) => layoutItem.id === item.id
      );
      if (JSON.stringify(item) === JSON.stringify(previousItem)) return;

      const result = await updateLayoutItem({
        variables: {
          input: {
            id: item.id,
            x: item.x,
            y: item.y,
            z: item.z,
            width: item.width,
            height: item.height,
            name: item.name,
            widgetData: JSON.stringify(item.widget.data),
            animations: JSON.stringify(item.animations),
            scale: item.scale,
          },
        },
        optimisticResponse: {
          updateLayoutItem: {
            layoutItem: LayoutItemTypeToFragment(item),
            __typename: "UpdateLayoutItemPayload",
          },
        },
      });

      if (!result.data?.updateLayoutItem?.layoutItem) return;

      if (!(studioContainerRef.current && screen.putThumbnailUrl)) return;

      // const preview = studioContainerRef.current.querySelector(
      //   "#studio-preview"
      // ) as HTMLElement;

      // if (!preview) return null;

      // const canvas = await html2canvas(preview, {
      //   useCORS: true,
      //   allowTaint: true,
      // });
      // const dataUrl = canvas.toDataURL("image/png", 1.0);

      // console.log("Upload Screen Thumbnail");
      // console.log("putThumbnailUrl", screen.putThumbnailUrl);

      // uploadScreenThumbnail(dataUrl, screen.putThumbnailUrl);

      // return LayoutItemFragmentToType(result.data.updateLayoutItem.layoutItem);
    },
    removeLayoutItem: async (item) => {
      const result = await deleteLayoutItem({
        variables: {
          input: {
            id: item.id,
          },
        },
        refetchQueries: ["GetPublication"],
      });
      return result.data?.deleteLayoutItem?.ok || false;
    },
    selectLayoutItem: (itemId) => setSelectedLayoutItemId(itemId),
  };

  const pageService: PageService = {
    onCreatePage,
    onDeletePage,
    onDuplicatePage,
    onEditPage,
    onSelectPage,
  };

  // console.log("screen", screen, "layoutItems", layoutItems);
  return (
    <Box ref={studioContainerRef}>
      <Studio
        layoutItems={layoutItems}
        widgets={widgets}
        loadedFonts={fonts}
        isLocked={isLocked}
        onUpdateScreenHeight={() => null}
        onUpdateScreenWidth={() => null}
        screenHeight={publication.height}
        screenWidth={publication.width}
        selectedLayoutItemId={selectedLayoutItemId}
        layoutService={layoutService}
        showAnimations={showAnimations}
        pageService={pageService}
        pageList={pageList}
      />
    </Box>
  );
};

const uploadScreenThumbnail = async (dataUrl: string, uploadUrl: string) => {
  let blob = await fetch(dataUrl).then((r) => r.blob());
  const file = new File([blob], "", { type: "image/jpeg" });
  uploadFileToS3(uploadUrl, file);
};
