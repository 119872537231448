import { Box } from "@chakra-ui/react";
import { Contact } from "../../components/Contact";

export const ContactPage = () => {
  return (
    <Box p="16px">
      <Box borderRadius="25px" padding="32px" bg="white">
        <Contact />
      </Box>
    </Box>
  );
};
