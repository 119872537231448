import { Box } from "@chakra-ui/layout";
import { useRef } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { DisplayTable } from "./DisplayTable";
import { EditDisplayComputer } from "./EditDisplayComputer";

export const DisplayHomePage = () => {
  const match = useRouteMatch();
  const refRightPanel = useRef<HTMLElement>();
  return (
    <Box display="flex" flexWrap="wrap">
      <Switch>
        <Route path={`${match.url}/display-computer/:displayComputerId`}>
          <EditDisplayComputer
            setMapCenter={() => {}}
            refRightPanel={refRightPanel}
          />
        </Route>
        <Route path={`${match.url}/`} exact>
          <DisplayTable onFlyTo={() => {}} />
        </Route>
      </Switch>
    </Box>
  );
};
