import { Flex } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";

export const ContentOnlyLayout: FunctionComponent = ({ children }) => {
  return (
    <Flex height="100%" align="center" justify="center">
      {children}
    </Flex>
  );
};
