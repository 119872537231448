import { Box, BoxProps, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import {
  MediaContentNode,
  MediaMediaContentContentTypeChoices,
} from "../../generated/graphql";

type MediaCardPreviewProps = BoxProps & {
  media: MediaContentNode;
  onLoadedMetadata?: (e: React.SyntheticEvent<HTMLVideoElement>) => void;
};

export const VideoPreview: FC<MediaCardPreviewProps> = ({
  media,
  onLoadedMetadata,
  ...props
}) => (
  <Box {...props}>
    <video
      style={{
        height: "100%",
        objectFit: "cover",
      }}
      controls
      src={media.signedUrl || ""}
      onLoadedMetadata={onLoadedMetadata}
    ></video>
  </Box>
);

const VideoThumbnailPreview: FC<MediaCardPreviewProps> = ({
  media,
  ...props
}) => (
  <Box {...props}>
    {media.thumbnailUrl ? (
      <Image
        width="100%"
        height="100%"
        objectFit="cover"
        src={media.thumbnailUrl || ""}
      />
    ) : (
      <Spinner />
    )}
  </Box>
);

export const ImagePreview: FC<MediaCardPreviewProps> = ({
  media,
  onLoadedMetadata,
  ...props
}) => (
  <Box {...props}>
    <Image
      width="100%"
      height="100%"
      objectFit="cover"
      src={media.signedUrl || ""}
    />
  </Box>
);

export const IframePreview: FC<MediaCardPreviewProps> = ({
  media,
  onLoadedMetadata,
  ...props
}) => (
  <Box {...props}>
    <iframe
      title="Web Page Preview"
      height="100%"
      src={media.webpageUrl || ""}
      frameBorder="0"
      allowTransparency
      allow="encrypted-media"
    />
  </Box>
);

const Previews = {
  [MediaMediaContentContentTypeChoices.Vd]: VideoPreview,
  [MediaMediaContentContentTypeChoices.Im]: ImagePreview,
  [MediaMediaContentContentTypeChoices.If]: VideoPreview,
};

export const MediaPreviewCard: FC<MediaCardPreviewProps> = ({
  media,
  onLoadedMetadata,
  ...props
}) => {
  const Preview = Previews[media.contentType];
  return (
    <VStack>
      <Preview media={media} onLoadedMetadata={onLoadedMetadata} {...props} />
      <Text overflowWrap="break-word" width={props.boxSize}>
        {media.name}
      </Text>
    </VStack>
  );
};

type MediaCardProps = BoxProps & {
  media: MediaContentNode;
};
export const MediaCard: FC<MediaCardProps> = ({ media, ...props }) => {
  const previews = {
    ...Previews,
    [MediaMediaContentContentTypeChoices.Vd]: VideoThumbnailPreview,
  };
  const Preview = previews[media.contentType];
  return <Preview media={media} {...(props as any)} />;
};
