import axios from "axios";

export const readFile = (file: File) => {
  return new Promise<string | ArrayBuffer>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => reader.result && resolve(reader.result)
    );
    reader.readAsArrayBuffer(file);
  });
};

export const uploadFileToS3 = async (
  presignedUrl: string,
  file: File,
  onUploadProgress?: ((progressEvent: any) => void) | undefined
) => {
  const fileData = await readFile(file);
  return await axios.request({
    method: "PUT",
    url: presignedUrl,
    data: fileData,
    headers: {
      "x-amz-acl": "private",
      "Content-Type": "text/plain",
    },
    onUploadProgress: onUploadProgress,
  });
  // return await fetch(presignedUrl, {
  //   method: "PUT",
  //   mode: "cors",
  //   body: fileData,
  //   headers: {
  //     "x-amz-acl": "private",
  //     "Content-Type": "text/plain",
  //   },
  // });
};
