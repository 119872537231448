import { Spinner } from "@chakra-ui/react";
import { ResponsiveLine } from "@nivo/line";
import { FC } from "react";
import { useRequestLokiQuery } from "../../generated/graphql";
import { prepareData } from "./helpers";

type ComputerStatsLineProps = {
  startDate?: string;
  endDate?: string;
  computerId: string;
};

export const ComputerStatsLine: FC<ComputerStatsLineProps> = ({
  startDate,
  endDate,
  computerId,
}) => {
  const queryCPU = `avg by (computerName) (avg_over_time ({job="platform", computerId="${computerId}"} |~ "cpu_percent" | json | unwrap cpu_percent [1h]))`;
  const cpuMetrics = useRequestLokiQuery({
    variables: { query: queryCPU, start: startDate, end: endDate },
  });
  const cpuLineData = prepareData(
    cpuMetrics?.data?.requestLoki,
    "CPU%",
    60 * 5 * 1000
  );

  const queryMEM = `avg by (computerName) (avg_over_time ({job="platform", computerId="${computerId}"} |~ "memory_percent" | json | unwrap memory_percent [1h]))`;
  const memMetrics = useRequestLokiQuery({
    variables: { query: queryMEM, start: startDate, end: endDate },
  });
  const memLineData = prepareData(
    memMetrics?.data?.requestLoki,
    "MEM%",
    60 * 5 * 1000
  );
  return cpuLineData && memLineData ? (
    <ResponsiveLine
      data={[cpuLineData, memLineData]}
      margin={{ top: 32, right: 24, bottom: 24, left: 32 }}
      xScale={{
        type: "time",
        precision: "hour",
        format: "native",
        min: new Date(startDate || ""),
        max: endDate ? new Date(endDate) : new Date(),
      }}
      xFormat="time: %H:%M"
      yScale={{
        type: "linear",
        stacked: false,
      }}
      axisLeft={{
        // legend: "linear scale",
        legendOffset: 6,
        tickValues: 5,
      }}
      curve="step"
      axisBottom={{
        format: "%Hh",
        tickValues: "every 1 hours",
        // legend: "time scale",
        legendOffset: -12,
      }}
      enablePoints={false}
      useMesh={true}
      enableSlices={false}
      colors={["#61cdbb", "#e8a838"]}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: -32,
          translateY: -24,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  ) : (
    <Spinner />
  );
};
