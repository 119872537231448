import { Box, Text } from "@chakra-ui/layout";
import { Table, Tbody, Td, Tr, Thead, Th } from "@chakra-ui/table";
import { useContext } from "react";
import { useLocation } from "react-router";

import {
  useGetCustomerPublicationsQuery,
  PublicationAllocationFragment,
} from "../../generated/graphql";
import { getNumberOfAllocatedDisplays } from "../../libs/publicationHelpers";
import { CustomerContext } from "../../screens/NavigationContext";
import { PublicationMenu } from "./PublicationMenu";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";

export const PublicationsTable = () => {
  const customerId = useContext(CustomerContext);
  const location = useLocation();
  const { data } = useGetCustomerPublicationsQuery({
    variables: {
      customerId: customerId || "",
      first: 25,
    },
  });
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  return (
    <Box
      borderRadius="12px"
      padding="18px"
      bg="white"
      width="100%"
      minHeight="120px"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Nom</Th>
            {isLargerThan1080 && <Th>Ecrans</Th>}
            {isLargerThan1080 && <Th>Modification</Th>}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.customer?.publicationSet.edges.map((edge, index) => {
            const publication = edge?.node;
            if (!publication) {
              return null;
            }
            const publicationAllocations =
              publication.publicationallocationSet.edges
                .map((edge) => edge?.node)
                .filter(
                  (item): item is PublicationAllocationFragment => !!item
                );
            return (
              <Tr key={"publication-" + index}>
                <Td>
                  <Link
                    to={`${location.pathname}/publication/${publication.id}`}
                  >
                    <Text color="gray.800" fontWeight={"bold"}>
                      {publication.name}
                    </Text>
                  </Link>
                </Td>
                {isLargerThan1080 && (
                  <Td>
                    <Text color="gray.600">
                      {getNumberOfAllocatedDisplays(publicationAllocations)}{" "}
                      écrans actifs
                    </Text>
                  </Td>
                )}
                {isLargerThan1080 && (
                  <Td>
                    <Text color="gray.600">
                      {new Date(publication.updatedAt).toLocaleString()}
                    </Text>
                  </Td>
                )}
                <Td>
                  <PublicationMenu publication={publication} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
