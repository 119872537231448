import { FunctionComponent, useContext } from "react";
import {
  Input,
  HStack,
  Button,
  Divider,
  VStack,
  IconButton,
  Text,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, AddIcon } from "@chakra-ui/icons";
import { useDebouncedCallback } from "use-debounce";
import {
  useSearchDisplaysLazyQuery,
  DisplayGroupNode,
  useAddDisplayGroupToAllocationMutation,
  DisplayNode,
  useAddDisplayToAllocationMutation,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { DisplayGroupCard, DisplayItem } from "./DisplayGroupCard";

type AddDisplayProps = {
  allocationId: string;
  refRatio?: number | null;
};
export const AddDisplay: FunctionComponent<AddDisplayProps> = ({
  allocationId,
  refRatio,
}) => {
  const [searchDisplays, { data }] = useSearchDisplaysLazyQuery();
  const customerId = useContext(CustomerContext);
  const [addDisplayGroup] = useAddDisplayGroupToAllocationMutation({
    refetchQueries: [
      "SearchDisplays",
      "GetAllocation",
      "GetPublicationAllocations",
    ],
  });
  const [addDisplay] = useAddDisplayToAllocationMutation({
    refetchQueries: [
      "SearchDisplays",
      "GetAllocation",
      "GetPublicationAllocations",
    ],
  });
  const debouncedSearchDisplays = useDebouncedCallback(searchDisplays, 400);
  return (
    <VStack>
      <Input
        bg="white"
        placeholder="Rechercher un groupe ou un ecran"
        onChange={(target) =>
          debouncedSearchDisplays({
            variables: {
              customerId: customerId || "",
              search: target.currentTarget.value,
            },
          })
        }
      />
      <VStack divider={<Divider />} alignItems="flex-start">
        {data?.customer?.displaygroupSet?.edges.map(
          (displayGroup) =>
            displayGroup?.node && (
              <DisplayGroupCard
                displayGroup={displayGroup?.node as DisplayGroupNode}
                width="100%"
                minHeight="50px"
                refRatio={refRatio}
                actionButton={
                  displayGroup?.node.publicationallocationSet.edges.find(
                    (allocation) => allocation?.node?.id === allocationId
                  ) ? (
                    <CheckIcon />
                  ) : (
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() =>
                        addDisplayGroup({
                          variables: {
                            id: allocationId,
                            displayGroupId: displayGroup!.node!.id,
                          },
                        })
                      }
                    >
                      Ajouter
                    </Button>
                  )
                }
              />
            )
        )}
        {data?.customer?.displaycomputerSet.edges.length && (
          <Box
            padding="14px 24px "
            bg="white"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="12px"
          >
            <VStack alignItems="flex-start">
              <Text fontSize="sm" fontWeight="bold" color="gray.700">
                Ecrans
              </Text>
              <VStack alignItems="flex-start" divider={<Divider />}>
                {data?.customer?.displaycomputerSet.edges.map(
                  (displayComputer, index) => {
                    const display = displayComputer?.node?.displaySet.edges[0];

                    return (
                      display?.node && (
                        <HStack spacing="4">
                          <DisplayItem
                            key={"display-" + index}
                            name={displayComputer?.node?.name || ""}
                            refRatio={refRatio}
                            display={display?.node as DisplayNode}
                          />
                          {display.node?.publicationallocationSet.edges.find(
                            (allocation) =>
                              allocation?.node?.id === allocationId
                          ) ? (
                            <CheckIcon />
                          ) : (
                            <IconButton
                              aria-label="Add Display"
                              icon={<AddIcon />}
                              size="sm"
                              variant="outline"
                              onClick={() =>
                                addDisplay({
                                  variables: {
                                    id: allocationId,
                                    displayId: display.node!.id,
                                  },
                                })
                              }
                            >
                              Ajouter
                            </IconButton>
                          )}
                        </HStack>
                      )
                    );
                  }
                )}
              </VStack>
            </VStack>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};
