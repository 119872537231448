import { Box } from "@chakra-ui/react";
import { WidgetMediaNode } from "../../../generated/graphql";
import { parseMediaMetaData } from "../metaData";

export const VideoPreview = (widgetMedia: WidgetMediaNode) => {
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  return (
    <Box
      key={"video-container-" + widgetMedia.id}
      position="relative"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <video
        key={"video" + widgetMedia.id}
        muted
        autoPlay
        loop={true}
        src={widgetMedia.mediaContent.signedUrl || ""}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          objectFit: (mediaMetaData.object_fit || "fill") as
            | "fill"
            | "contain"
            | "cover",
        }}
      ></video>
    </Box>
  );
};
