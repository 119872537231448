import { Select } from "@chakra-ui/select";
import moment from "moment";

export const SelectWeek = () => {
  const currentWeek = moment().isoWeek();

  return (
    <Select
      placeholder="Select option"
      defaultValue={`week-${currentWeek}`}
      width="200px"
    >
      <option value={`week-${currentWeek}`}>Semaine {currentWeek}</option>
      <option value={`week-${currentWeek - 1}`}>
        Semaine {currentWeek - 1}
      </option>
    </Select>
  );
};
