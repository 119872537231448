import { WidgetType } from "@govisupro/studio";
import { useEffect, useState } from "react";
import { PlaylistWidget } from "../Playlist";
import { WeatherWidget } from "@govisupro/weather-widget";
import { ScrollWidget } from "@govisupro/scroll-widget";
import { TextWidget } from "@govisupro/text-widget";
import { PdfWidget } from "@govisupro/pdf-widget";
import { YoutubeWidget } from "@govisupro/youtube-widget";
import { useGetActivatedWidgetsQuery } from "../../generated/graphql";
import { ClockWidget } from "@govisupro/clock-widget";

const WIDGETS = {
  [PlaylistWidget.name]: PlaylistWidget,
  [ScrollWidget.name]: ScrollWidget,
  [WeatherWidget.name]: WeatherWidget,
  [PdfWidget.name]: PdfWidget,
  [TextWidget.name]: TextWidget,
  [YoutubeWidget.name]: YoutubeWidget,
  [ClockWidget.name]: ClockWidget,
};

export const useActivatedWidgets = (customerId: string) => {
  const { data } = useGetActivatedWidgetsQuery({ variables: { customerId } });
  const [widgets, setWidgets] = useState<WidgetType[]>([]);
  useEffect(() => {
    if (data && data.customer?.activatedWidgets) {
      const widgets = Object.values(WIDGETS).filter((widget) =>
        data.customer?.activatedWidgets.edges.some(
          (activatedWidget) => activatedWidget?.node?.name === widget.name
        )
      );
      setWidgets(widgets);
    }
  }, [data]);

  return widgets;
};
