import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FunctionComponent, useContext } from "react";
import { FiEdit2, FiSliders, FiTrash } from "react-icons/fi";
import {
  MediaMediaContentContentTypeChoices,
  useConvertMediaContentMutation,
  useDeleteWidgetMediaMutation,
  WidgetMediaNode,
} from "../../../generated/graphql";
import { UserContext } from "../../../screens/NavigationContext";
import { MediaUpdate } from "./UpdateMedia";

type MediaMoreMenuProps = IconButtonProps & {
  widgetMedia: WidgetMediaNode;
};

export const MediaMoreMenu: FunctionComponent<MediaMoreMenuProps> = ({
  widgetMedia,
  ...menuButtonProps
}) => {
  const [deleteWidgetMedia] = useDeleteWidgetMediaMutation({
    refetchQueries: ["LayoutItem"],
  });
  const [convertMedia] = useConvertMediaContentMutation();
  const user = useContext(UserContext);
  console.log("user:", user);
  return (
    <Menu>
      <MenuButton as={IconButton} {...menuButtonProps} />
      <MenuList>
        <MediaUpdate icon={<FiEdit2 />} widgetMedia={widgetMedia}>
          Modifier
        </MediaUpdate>
        {widgetMedia.mediaContent.contentType ===
          MediaMediaContentContentTypeChoices.Vd &&
          user?.isStaff && (
            <MenuItem
              icon={<FiSliders />}
              onClick={() =>
                convertMedia({ variables: { id: widgetMedia.mediaContent.id } })
              }
            >
              Convertir
            </MenuItem>
          )}
        <MenuItem
          icon={<FiTrash />}
          onClick={() =>
            deleteWidgetMedia({ variables: { input: { id: widgetMedia.id } } })
          }
        >
          Supprimer
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
