export const getSoftwareVersionColor = (version: string) => {
  let color = "gray";
  switch (version) {
    case "latest":
      color = "blue";
      break;
    case "outdated":
      color = "yellow";
      break;
    case "deprecated":
      color = "red";
      break;
    case "stable":
      color = "green";
      break;
    case "main":
      color = "purple";
      break;
  }
  return color;
};
