import {
  Button,
  VStack,
  StackDivider,
  Avatar,
  Badge,
  Box,
  Input,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import {
  useAddUserMutation,
  useGetCustomerUsersQuery,
  UserProfile,
  useSearchUsersQuery,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";

export const AddUser = () => {
  const customerId = useContext(CustomerContext);
  const [searchEmail, setSearchEmail] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useSearchUsersQuery({
    variables: { search: searchEmail, first: 10 },
  });
  const { data: CustomerUsers } = useGetCustomerUsersQuery({
    variables: { customerId: customerId || "" },
  });
  const [addUser] = useAddUserMutation({
    refetchQueries: ["GetCustomerUsers"],
  });

  return (
    <>
      <Button variant="outline" bg="white" onClick={onOpen}>
        Ajouter
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Chercher un utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Input
                placeholder="name@mail.com"
                onChange={(e) => setSearchEmail(e.currentTarget.value)}
              />
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                alignItems="flex-start"
                width="100%"
              >
                {data?.users?.edges.map((user, index) => (
                  <HStack
                    width="100%"
                    key={`user-item-${user?.node?.id ?? index}`}
                  >
                    <Avatar
                      name={`${user?.node?.firstName} ${user?.node?.lastName}`}
                    />
                    <Box p="6" flexGrow={1}>
                      <Badge
                        variant="outline"
                        colorScheme={
                          user?.node?.profile === UserProfile.Admin
                            ? "orange"
                            : "gray"
                        }
                      >
                        {user?.node?.profile}
                      </Badge>
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                        isTruncated
                      >
                        {`${user?.node?.firstName} ${user?.node?.lastName}`}
                      </Box>
                      <Box>{user?.node?.email}</Box>
                    </Box>
                    {CustomerUsers?.customer?.userSet.edges.findIndex(
                      (customerUser) =>
                        customerUser?.node?.id === user?.node?.id
                    ) === -1 ? (
                      <Button
                        onClick={() =>
                          addUser({
                            variables: {
                              id: user!.node!.id,
                              customerId: customerId || "",
                            },
                          })
                        }
                      >
                        Ajouter
                      </Button>
                    ) : (
                      <Box>Déjà present</Box>
                    )}
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
