import { FC, useContext } from "react";
import {
  useLayoutItemQuery,
  WidgetMediaNode,
  MediaMediaContentContentTypeChoices,
} from "../../../generated/graphql";
import { WidgetPlayerPreviewProps } from "@govisupro/studio";
import { PlaylistContext } from "../context";
import { IframePreview } from "./Iframe";
import { ImagePreview } from "./ImagePreview";
import { VideoPreview } from "./VideoPreview";

const MediaContentPreview = {
  [MediaMediaContentContentTypeChoices.Im]: ImagePreview,
  [MediaMediaContentContentTypeChoices.Vd]: VideoPreview,
  [MediaMediaContentContentTypeChoices.If]: IframePreview,
};

interface PreviewPlayerProps extends WidgetPlayerPreviewProps {}

export const PreviewPlayer: FC<PreviewPlayerProps> = ({ layoutItem }) => {
  const { data } = useLayoutItemQuery({
    variables: { id: layoutItem.id },
  });
  const { selectedMedia } = useContext(PlaylistContext);
  const widget = data?.layoutItem?.widget;
  const widgetMediaNode =
    widget?.widgetmediaSet.edges.find(
      (edge) => edge?.node?.id === selectedMedia
    ) || widget?.widgetmediaSet.edges[0];
  const widgetMedia = widgetMediaNode?.node;
  const mediaContent = widgetMedia?.mediaContent;
  return mediaContent && widgetMedia ? (
    MediaContentPreview[mediaContent.contentType](
      widgetMedia as WidgetMediaNode
    )
  ) : (
    <div></div>
  );
};
