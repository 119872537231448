import { createIcon } from "@chakra-ui/icons";
// using `path`
export const AtelierIcon = createIcon({
  displayName: "AtelierIcon",
  viewBox: "0 0 21 21",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <rect
        x="1"
        y="1"
        width="19"
        height="19"
        rx="3"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
      />
      <path d="M8 2v17M19 11H7" stroke="currentColor" strokeWidth="2" />
    </g>
  ),
});

export const DashboardIcon = createIcon({
  displayName: "DashboardIcon",
  viewBox: "0 0 21 21",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        d="M5.5 12.5l3-3 3 2 3-3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.79 1H4a4 4 0 00-4 4v11a4 4 0 004 4h12a4 4 0 004-4V6.21a3.988 3.988 0 01-2 .26V16a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h10.53a4.044 4.044 0 01.26-2z"
        fill="currentColor"
      />
      <circle cx="18.5" cy="2.5" r="2" stroke="currentColor" />
    </g>
  ),
});

export const ScreenIcon = createIcon({
  displayName: "ScreenIcon",
  viewBox: "0 0 24 20",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5H4a2 2 0 00-2 2v6a2 2 0 002 2h13a2 2 0 002-2V7a2 2 0 00-2-2zM4 3a4 4 0 00-4 4v6a4 4 0 004 4h13a4 4 0 004-4V7a4 4 0 00-4-4H4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2h2a2 2 0 012 2v8c0 .587-.126 1.144-.354 1.646A4 4 0 0024 10V4a4 4 0 00-4-4H7a4 4 0 00-3.646 2.353A3.986 3.986 0 015 2h13z"
        fill="currentColor"
      />
    </g>
  ),
});

export const PublicationIcon = createIcon({
  displayName: "PublicationIcon",
  viewBox: "0 0 21 21",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <rect
        x="1"
        y="1"
        width="19"
        height="19"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
      />
      <path
        fill="currentColor"
        d="M5 16h2v1H5zM8 16h2v1H8zM11 16h2v1h-2zM14 16h2v1h-2zM8 5.934v6.132a.5.5 0 00.777.416l4.599-3.066a.5.5 0 000-.832L8.777 5.518A.5.5 0 008 5.934z"
      />
    </g>
  ),
});
