import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForgottenPasswordMutation } from "../../generated/graphql";

export const ForgottenPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const [forgottenPassword] = useForgottenPasswordMutation({
    errorPolicy: "all",
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const { data, errors } = await forgottenPassword({ variables: { email } });
    if (data?.forgottenPassword?.success) {
      toast({
        title: "Email envoyé, veuillez verifier votre boite de reception.",
        status: "success",
        isClosable: true,
        duration: 5000,
        onCloseComplete: () => history.push("/"),
      });
    } else {
      if (errors) {
        toast({
          title: "Email non valide.",
          status: "error",
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
    setEmail("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing="6" alignItems="flex-start">
        <Text>
          Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de
          passe.
        </Text>
        <FormControl isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            type={"email"}
            name="email"
            placeholder="your@email.com"
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </FormControl>

        <Button width="full" mt={4} colorScheme="orange" type="submit">
          {isLoading ? (
            <CircularProgress isIndeterminate size="24px" color="white" />
          ) : (
            "Réinitialiser mot de passe"
          )}
        </Button>
      </VStack>
    </form>
  );
};
