import { Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/layout";
import { useContext } from "react";
import { SelectWeek } from "../../components/SelectWeek";
import { useGetDisplayComputersQuery } from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import DataMedia from "./data-media.json";
import { inTimeRange } from "../../libs/schedule";

type LokiStat = [number, string];
const calculateNbMinutesDownInRange = (
  values: LokiStat[],
  start: string | null,
  end: string | null
) => {
  const prevStat: LokiStat = [0, ""];
  let index = 0;
  let nbMinutesDown = 0;
  for (const stat of values) {
    if (index > 0) {
      const currentDate = new Date(stat[0] * 1000);
      const durationSinceLastRotation = stat[0] - prevStat[0];
      if (
        inTimeRange(start, end, currentDate) &&
        durationSinceLastRotation > 60
      ) {
        nbMinutesDown += Math.floor(durationSinceLastRotation / 60);
      }
    }
    index++;
    prevStat[0] = stat[0];
    prevStat[1] = stat[1];
  }
  return nbMinutesDown;
};

export const WeekDisplaysStats = () => {
  const customerId = useContext(CustomerContext);
  const { data } = useGetDisplayComputersQuery({
    variables: { customerId: customerId || "" },
    pollInterval: 30000,
  });
  let totalDownTime = 0;
  for (const result of DataMedia.data.result) {
    const computer = data?.customer?.displaycomputerSet.edges.find(
      (cp) => cp?.node?.name === result.metric.computerName
    );
    if (computer) {
      totalDownTime += calculateNbMinutesDownInRange(
        result.values[0] as any,
        computer.node?.startTime,
        computer.node?.endTime
      );
    }
  }
  return (
    <VStack spacing="31px">
      <Flex justifyContent="space-between" width="100%">
        <Text fontSize="md" fontWeight="bold" alignSelf="center">
          Affichages
        </Text>
        <SelectWeek />
      </Flex>
      <Grid
        alignSelf="flex-start"
        h="400px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
        width="100%"
      >
        <GridItem rowSpan={1} colSpan={2}>
          <Text fontSize="5xl" fontWeight="bold" color="gray.800">
            100%
          </Text>
          <Text fontSize="sm" color="gray.600">
            De remplissage sur les creneaux alloués.
          </Text>
        </GridItem>
        <GridItem rowSpan={2} colSpan={3}>
          {/* <ResponsiveBar
            data={dataStat}
            keys={["salle1", "salle2", "salle3", "salle4", "rue1", "rue2"]}
            indexBy="day"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "rue1",
                },
                id: "dots",
              },
              {
                match: {
                  id: "salle3",
                },
                id: "lines",
              },
            ]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Semaine",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Minutes",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          /> */}
        </GridItem>
        <GridItem rowSpan={1} colSpan={2}>
          <Text fontSize="4xl" fontWeight="bold" color="gray.800">
            {totalDownTime} Minutes
          </Text>
          <Text fontSize="sm" color="gray.600">
            Temps d'ecrans hors de services.
          </Text>
        </GridItem>
      </Grid>
    </VStack>
  );
};
