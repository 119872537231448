import { Icon, useMediaQuery } from "@chakra-ui/react";
import { FiActivity, FiCreditCard, FiGitCommit, FiHome } from "react-icons/fi";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Header } from "../components/Header";
import { MenuBar, MenuBarItemType } from "../components/MenuBar";
import { HeaderContentBottomBar } from "../layouts/HeaderContentBottomBar";
import { HeaderSideMenuContent } from "../layouts/HeaderSideMenuContent";
import { ComposerList } from "../pages/Admin/ComposerList";
import { SoftwareVersions } from "../pages/Admin/SoftwareVersions";
import { DisplayMonitoringPage } from "../pages/Monitoring";

export const AdminScreen = () => {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  const Layout = isLargerThan1080
    ? HeaderSideMenuContent
    : HeaderContentBottomBar;

  const menubarItems: MenuBarItemType[] = [
    {
      id: "composers",
      text: "Composers",
      icon: (props) => <Icon as={FiHome} {...props} />,
      active:
        location.pathname.startsWith(`${match.url}/composers`) ||
        location.pathname === match.url,
      onSelect: () => history.push(`${match.url}/composers`),
    },
    {
      id: "monitoring",
      text: "Monitoring",
      icon: (props) => <Icon as={FiActivity} {...props} />,
      active: location.pathname.startsWith(`${match.url}/monitoring`),
      onSelect: () => history.push(`${match.url}/monitoring`),
    },
    {
      id: "versions",
      text: "Features",
      icon: (props) => <Icon as={FiGitCommit} {...props} />,
      active: location.pathname.startsWith(`${match.url}/softwareversions`),
      onSelect: () => history.push(`${match.url}/softwareversions`),
    },
    {
      id: "compta",
      text: "Compta",
      icon: (props) => <Icon as={FiCreditCard} {...props} />,
      active: location.pathname.startsWith(`${match.url}/compta`),
      onSelect: () => history.push(`${match.url}/compta`),
    },
  ];
  return (
    <Layout
      header={<Header />}
      bar={
        <MenuBar
          direction={isLargerThan1080 ? "column" : "row"}
          items={menubarItems}
        />
      }
    >
      <Switch>
        <Route
          path={`${match.url}/monitoring/:selectedComputer?`}
          component={DisplayMonitoringPage}
        />
        <Route
          path={`${match.url}/softwareversions/`}
          component={SoftwareVersions}
        />
        <Route path={`${match.url}/composers`} component={ComposerList} />
        <Route path={`${match.url}`} component={ComposerList} />
      </Switch>
    </Layout>
  );
};
