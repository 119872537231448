import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

type ConfirmModalProps = {
  bodyText: string;
  confirmButtonText: string;
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  bodyText,
  confirmButtonText,
  onConfirm,
  onClose,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attention !</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Fermer
          </Button>
          <Button colorScheme="orange" onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
