import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { FunctionComponent, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  MediaContentNode,
  useLayoutItemQuery,
  useUpdateWidgetMediaMutation,
  WidgetLayoutItemNode,
  WidgetMediaNode,
} from "../../../generated/graphql";
import { reorder } from "../../../libs/list";
import { WidgetControlPanelProps } from "@govisupro/studio";
import { dumpMediaMetaData, parseMediaMetaData } from "../metaData";
import { isMediaNotExpired, isMediaReady } from "../utils";
import { PlaylistContext } from "../context";
import { MediaCard } from "./MediaCard";
import { AddMedia } from "./AddMedia";

interface ControlPanelProps extends WidgetControlPanelProps {}

export const ControlPanel: FunctionComponent<ControlPanelProps> = ({
  layoutItem,
}) => {
  const layoutItemNode = useLayoutItemQuery({
    variables: { id: layoutItem.id },
  });
  const [updateWidgetMedia] = useUpdateWidgetMediaMutation();
  const mediaSet =
    layoutItemNode.data?.layoutItem?.widget?.widgetmediaSet?.edges
      .map((edge) => edge?.node)
      .sort((a, b) => {
        const aMetaData = parseMediaMetaData(a?.data);
        const bMetaData = parseMediaMetaData(b?.data);
        return (aMetaData.order || 0) - (bMetaData.order || 0);
      }) || [];
  const { selectedMedia, setSelectedMedia } = useContext(PlaylistContext);
  const widgetLayoutItem = layoutItemNode.data?.layoutItem?.widget;
  return (
    <VStack alignItems="flex-start" maxHeight="100%" width="100%">
      <Flex justifyContent="space-between" width="100%" alignItems="baseline">
        <Text color="gray.600">Medias</Text>
        {widgetLayoutItem && (
          <AddMedia
            widgetLayoutItem={widgetLayoutItem as WidgetLayoutItemNode}
          />
        )}
      </Flex>

      <DragDropContext
        onDragEnd={async (result) => {
          if (!result.destination || !mediaSet) {
            return;
          }

          const items = reorder(
            mediaSet,
            result.source.index,
            result.destination.index
          );
          let order = 0;
          for (const item of items) {
            if (item) {
              const itemMetaData = parseMediaMetaData(item.data);
              if (itemMetaData.order !== order) {
                await updateWidgetMedia({
                  variables: {
                    input: {
                      id: item.id,
                      data: dumpMediaMetaData({
                        ...itemMetaData,
                        order: order,
                      }),
                    },
                  },
                });
              }
            }
            order++;
          }
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <VStack
              {...provided.droppableProps}
              ref={provided.innerRef}
              alignItems="flex-start"
              spacing="0px"
              width="100%"
            >
              {mediaSet?.map((media, index) => {
                const mediaContent = media?.mediaContent as MediaContentNode;
                const mediaMetaData = parseMediaMetaData(media?.data);
                const mediaNotExpired = isMediaNotExpired(mediaMetaData);
                const mediaReady = isMediaReady(mediaMetaData);
                return (
                  media &&
                  mediaContent && (
                    <Draggable
                      key={mediaContent.id}
                      draggableId={mediaContent.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          width="100%"
                        >
                          <MediaCard
                            isSelected={selectedMedia === media.id}
                            isExpired={!mediaNotExpired}
                            isReady={mediaReady}
                            widgetMedia={media as WidgetMediaNode}
                            shadow={snapshot.isDragging ? "lg" : "none"}
                            onClick={() => {
                              media && setSelectedMedia(media.id);
                            }}
                          />
                        </Box>
                      )}
                    </Draggable>
                  )
                );
              })}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    </VStack>
  );
};
