import {
  PriorityEnum,
  PublicationAllocationFragment,
  PublicationsPublicationAllocationPriorityChoices,
} from "../generated/graphql";

export const getNumberOfAllocatedDisplays = (
  allocation: PublicationAllocationFragment[]
): number => {
  const displayList = [
    // list all display ids from allocated Display Groups
    ...allocation
      .map((allocation) =>
        allocation.displayGroup?.edges.map((displayGroup) =>
          displayGroup?.node?.displaycomputerSet.edges.map((computerSet) =>
            computerSet?.node?.displaySet.edges.map(
              (display) => display?.node?.id
            )
          )
        )
      )
      .flat(4),
    // list all display ids from direct allocation
    ...allocation
      .map((allocation) =>
        allocation.display?.edges.map((display) => display?.node?.id)
      )
      .flat(2),
  ]
    // filter null and undefined from the list
    .filter((id) => !!id);

  // remove duplicates using Set
  const displayListWithoutDuplicate = new Set(displayList);
  return displayListWithoutDuplicate.size;
};

export const prioritiesValues = {
  [PublicationsPublicationAllocationPriorityChoices.A_4]: 4,
  [PublicationsPublicationAllocationPriorityChoices.A_3]: 3,
  [PublicationsPublicationAllocationPriorityChoices.A_2]: 2,
  [PublicationsPublicationAllocationPriorityChoices.A_1]: 1,
  [PublicationsPublicationAllocationPriorityChoices.A_0]: 0,
};
export const prioritiesNames = [
  "Très élevé",
  "Elevé",
  "Modéré",
  "Basse",
  "Très basse",
];
export const prioritiesMap = {
  [PublicationsPublicationAllocationPriorityChoices.A_4]: "Très élevé",
  [PublicationsPublicationAllocationPriorityChoices.A_3]: "Elevé",
  [PublicationsPublicationAllocationPriorityChoices.A_2]: "Modéré",
  [PublicationsPublicationAllocationPriorityChoices.A_1]: "Basse",
  [PublicationsPublicationAllocationPriorityChoices.A_0]: "Très basse",
};

export const prioritiesToPriorityEnum = {
  [PublicationsPublicationAllocationPriorityChoices.A_4]: PriorityEnum.VeryHigh,
  [PublicationsPublicationAllocationPriorityChoices.A_3]: PriorityEnum.High,
  [PublicationsPublicationAllocationPriorityChoices.A_2]: PriorityEnum.Medium,
  [PublicationsPublicationAllocationPriorityChoices.A_1]: PriorityEnum.Low,
  [PublicationsPublicationAllocationPriorityChoices.A_0]: PriorityEnum.VeryLow,
};

export const getPriority = (
  allocationEdge: PublicationAllocationFragment[]
): string => {
  // return the mean priority of all allocations

  const sumOfPriorities = allocationEdge.reduce<number>(
    (prev, currentValue) =>
      prev +
      prioritiesValues[
        currentValue.priority ||
          PublicationsPublicationAllocationPriorityChoices.A_2
      ],
    0
  );
  return prioritiesNames[sumOfPriorities / allocationEdge.length];
};

const mediaTypes: any = {
  IM: "Image",
  VD: "Video",
  IF: "Web",
};
export const getMediaType = (mediaType: string) => mediaTypes[mediaType];
