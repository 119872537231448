import { LayoutItemType } from "@govisupro/studio";
import { LayoutItemFragment } from "../../generated/graphql";

export function LayoutItemFragmentToType(
  node: LayoutItemFragment
): LayoutItemType {
  return {
    id: node.id,
    name: node.name,
    x: node.x,
    y: node.y,
    z: parseInt(node.z),
    width: node.width,
    height: node.height,
    unit: node.width.endsWith("%") ? "%" : "px",
    widgetName: node.widget?.widgetType.name || "",
    widget: {
      id: "widget" + node.id,
      data:
        node.widget && node.widget?.data !== ""
          ? JSON.parse(node.widget?.data)
          : {},
    },
    animations: JSON.parse(node?.animations),
    scale: node.scale ?? undefined,
  };
}

export function LayoutItemTypeToFragment(
  item: LayoutItemType
): LayoutItemFragment {
  return {
    __typename: "LayoutItemNode",
    id: item.id,
    name: item.name,
    x: item.x,
    y: item.y,
    z: item.z.toString(),
    width: item.width,
    height: item.height,
    scale: item.scale,
    animations: JSON.stringify(item.animations),
    widget: {
      __typename: "WidgetLayoutItemNode",
      id: item.widget.id,
      widgetType: {
        __typename: "WidgetNode",
        id: item.widget.id,
        name: item.widgetName,
      },
      data: JSON.stringify(item.widget.data),
      widgetmediaSet: {
        __typename: "WidgetMediaNodeConnection",
        edges: [],
      },
      // @ts-ignore
      layoutItem: null,
    },
  };
}
