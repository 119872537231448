import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CircularProgress,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useResetPasswordMutation } from "../../generated/graphql";
import { useQueryParams } from "../../libs/queryParamsHook";

export const ResetPassword = () => {
  const queryParams = useQueryParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const [resetPassword] = useResetPasswordMutation({
    errorPolicy: "all",
    onError: (error) => {
      console.log(error);
    },
  });
  const token = queryParams.get("token");
  const userId = queryParams.get("uid");

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    setIsLoading(true);
    if (newPassword === confirmPassword) {
      const { data, errors } = await resetPassword({
        variables: {
          userId: userId || "",
          newPassword: newPassword,
          token: token || "",
        },
      });
      if (data?.resetPassword?.success) {
        toast({
          title: "Mot de passe réinitialisé correctement.",
          status: "success",
          isClosable: true,
          duration: 2000,
          onCloseComplete: () => history.push("/"),
        });
      } else {
        console.log(errors);
        if (errors) {
          setErrors(
            errors.map((error) =>
              error.message
                .replaceAll("[", "")
                .replaceAll("]", "")
                .replaceAll("'", "")
                .replaceAll(",", "")
            )
          );
        }
      }
    } else {
      toast({
        title: "mot de passe différents.",
        status: "error",
        isClosable: true,
      });
    }

    setIsLoading(false);
  };
  return token && userId ? (
    <form onSubmit={handleSubmit}>
      <VStack spacing="6" alignItems="flex-start">
        <Text>Veuillez entrer votre nouveau mot de passe.</Text>
        {errors.length && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreurs!</AlertTitle>
            <AlertDescription>{errors.join(", ")}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setErrors([])}
            />
          </Alert>
        )}
        <FormControl isRequired>
          <FormLabel htmlFor="email">Nouveau mot de passe</FormLabel>
          <Input
            type="password"
            name="password"
            onChange={(event) => setNewPassword(event.currentTarget.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="email">Confirmez votre mot de passe</FormLabel>
          <Input
            type="password"
            name="confirm_password"
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
          />
        </FormControl>

        <Button width="full" mt={4} colorScheme="orange" type="submit">
          {isLoading ? (
            <CircularProgress isIndeterminate size="24px" color="white" />
          ) : (
            "Réinitialiser mot de passe"
          )}
        </Button>
      </VStack>
    </form>
  ) : (
    <Text> Invalid Reset Link.</Text>
  );
};
