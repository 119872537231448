import { Serie } from "@nivo/line";

export const prepareData = (data: any, name: string, period: number) => {
  if (data === null || data === undefined) {
    return {
      id: name,
      data: [],
    };
  }
  const jsonData = JSON.parse(data);
  if (jsonData.status === "success") {
    if (jsonData?.data?.result?.length > 0) {
      return {
        id: name,
        data: jsonData.data.result[0].values
          .map((value: any) => {
            return {
              x: new Date(value[0] * 1000),
              y: value[1],
            };
          })
          .reduce((acc: { x: Date; y: number }[], value: any) => {
            if (acc.length > 0) {
              const last = acc[acc.length - 1];
              const missings =
                Math.floor((value.x.getTime() - last.x.getTime()) / period) - 1;
              for (let i = 0; i < missings; i++) {
                acc.push({
                  x: new Date(last.x.getTime() + period * (i + 1)),
                  y: 0,
                });
              }
            }
            acc.push(value);
            return acc;
          }, []),
      } as Serie;
    } else {
      return {
        id: name,
        data: [{ x: new Date(), y: 0 }],
      } as Serie;
    }
  }
  return {
    id: name,
    data: [],
  } as Serie;
};
