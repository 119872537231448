import { Box, Center, Flex, Image, Wrap } from "@chakra-ui/react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Contact } from "../components/Contact";
import { ContentOnlyLayout } from "../layouts/ContentOnlyLayout";
import { ForgottenPassword } from "../pages/Login/ForgottenPassword";
import { LoginPage } from "../pages/Login/LoginPage";
import { ResetPassword } from "../pages/Login/ResetPassword";

export const Login = () => {
  const match = useRouteMatch();
  return (
    <ContentOnlyLayout>
      <Center>
        <Wrap width="full" justify="center">
          <Box p="8" bg={"white"} maxW="500px" borderWidth={1} borderRadius={8}>
            <Flex pb={6} justify="center" flexDir="row">
              <Image src="/brand.png" maxWidth="240px" />
            </Flex>
            <Switch>
              <Route path={`${match.url}/`} exact component={LoginPage} />
              <Route
                path={`${match.url}/forgotten-password`}
                component={ForgottenPassword}
              />
              <Route
                path={`${match.url}/reset-password`}
                component={ResetPassword}
              />
            </Switch>
          </Box>
          <Box p="8" maxW="500px" height="100%">
            <Contact />
          </Box>
        </Wrap>
      </Center>
    </ContentOnlyLayout>
  );
};
