export function reorder<Type>(
  list: Array<Type>,
  startIndex: number,
  endIndex: number
): Array<Type> {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
