import { HeaderContent } from "../layouts/HeaderContent";
import { Header } from "../components/Header";
import { ComposerList } from "../pages/Admin/ComposerList";

export const CustomerList = () => {
  return (
    <HeaderContent header={<Header />}>
      <ComposerList />
    </HeaderContent>
  );
};
