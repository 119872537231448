import { Box } from "@chakra-ui/layout";
import { Route, Switch, useRouteMatch } from "react-router";
import { DisplayHomePage } from "./HomePage";

export const DisplaysPage = () => {
  const match = useRouteMatch();
  return (
    <Box p="16px">
      <Switch>
        <Route path={`${match.url}/`} component={DisplayHomePage} />
      </Switch>
    </Box>
  );
};
