import {
  AspectRatio,
  Box,
  BoxProps,
  HStack,
  Image,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC, FunctionComponent } from "react";
import {
  FiCalendar,
  FiChrome,
  FiFilm,
  FiImage,
  FiMoreVertical,
  FiPlayCircle,
} from "react-icons/fi";
import { ImFileVideo } from "react-icons/im";
import {
  MediaContentNode,
  MediaMediaContentContentTypeChoices,
  WidgetMediaNode,
} from "../../../generated/graphql";
import { parseMediaMetaData } from "../metaData";
import { MediaMoreMenu } from "./MediaMoreMenu";

type MediaCardProps = BoxProps & {
  widgetMedia: WidgetMediaNode;
  isExpired: boolean;
  isReady: boolean;
  isSelected: boolean;
};

type VideoThumbnailProps = {
  mediaContent: MediaContentNode;
};

const VideoThumbnail: FC<VideoThumbnailProps> = ({ mediaContent }) => {
  if (mediaContent.contentConverted && mediaContent.thumbnailUrl) {
    return (
      <Image
        boxSize="60px"
        objectFit="cover"
        src={mediaContent.thumbnailUrl}
        borderRadius="4px"
      />
    );
  } else {
    return (
      <Box boxSize="60px" padding="10px">
        <FiPlayCircle size="40px" strokeWidth="0.6" stroke="#718096" />
      </Box>
    );
  }
};

export const MediaCard: FunctionComponent<MediaCardProps> = ({
  widgetMedia,
  isExpired,
  isReady,
  isSelected,
  ...boxProps
}) => {
  const mediaContent = widgetMedia.mediaContent;
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  const backgroundColor =
    !isExpired && isReady
      ? isSelected
        ? "orange.50"
        : "whiteAlpha.100"
      : isSelected
      ? "gray.300"
      : "gray.200";
  return (
    <Box
      key={"media-card-" + mediaContent.id}
      bg={backgroundColor}
      paddingY="4px"
      // borderRadius="12px"
      width="100%"
      cursor="pointer"
      {...boxProps}
    >
      <HStack spacing="16px" width="100%">
        {mediaContent.signedUrl && (
          <HStack spacing="8px" flexGrow={1} overflow="hidden">
            {mediaContent.contentType ===
              MediaMediaContentContentTypeChoices.Im && (
              <Image
                boxSize="60px"
                objectFit="cover"
                src={mediaContent.signedUrl}
                borderRadius="4px"
              />
            )}
            {mediaContent.contentType ===
              MediaMediaContentContentTypeChoices.Vd && (
              <VideoThumbnail mediaContent={mediaContent} />
            )}
            {mediaContent.contentType ===
              MediaMediaContentContentTypeChoices.If && (
              <Box borderRadius="12px">
                <AspectRatio boxSize="50px">
                  <iframe
                    title="Web Page Preview"
                    height="300"
                    src={mediaContent.webpageUrl || ""}
                    frameBorder="0"
                    allowTransparency
                    allow="encrypted-media"
                  />
                </AspectRatio>
              </Box>
            )}
            <VStack flexGrow={1} alignItems="flex-start">
              <HStack height="32px">
                {mediaContent.contentType ===
                  MediaMediaContentContentTypeChoices.Vd && (
                  <Tag colorScheme="blue">
                    <FiFilm />
                  </Tag>
                )}
                {mediaContent.contentType ===
                  MediaMediaContentContentTypeChoices.Im && (
                  <Tag colorScheme="yellow">
                    <FiImage />
                  </Tag>
                )}
                {mediaContent.contentType ===
                  MediaMediaContentContentTypeChoices.If && (
                  <Tag colorScheme="purple">
                    <FiChrome />
                  </Tag>
                )}
                <Tag>{mediaMetaData.duration}s</Tag>
                {isExpired && (
                  <Tag colorScheme={"red"}>
                    <FiCalendar />
                  </Tag>
                )}
                {!isReady && (
                  <Tag colorScheme={"red"}>
                    <FiCalendar />
                  </Tag>
                )}
                {mediaContent.contentType ===
                  MediaMediaContentContentTypeChoices.Vd &&
                  mediaContent.contentConverted && (
                    <Tag colorScheme={"green"}>
                      <ImFileVideo />
                    </Tag>
                  )}
              </HStack>
              <Box marginTop="-3px !important" width="100%">
                <Text
                  fontSize="sm"
                  color="gray.700"
                  lineHeight="20px"
                  isTruncated
                >
                  {mediaContent.name}
                </Text>
              </Box>
            </VStack>
          </HStack>
        )}
        <MediaMoreMenu
          aria-label="options"
          icon={<FiMoreVertical />}
          variant="ghost"
          widgetMedia={widgetMedia}
        />
      </HStack>
    </Box>
  );
};
