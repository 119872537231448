import { FC } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { FiCopy, FiEdit2, FiTrash2 } from "react-icons/fi";
import {
  PublicationFragment,
  useDeletePublicationMutation,
} from "../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import { ConfirmModal } from "../../components/ConfirmModal";

type PublicationMenuProps = {
  publication: PublicationFragment;
};

export const PublicationMenu: FC<PublicationMenuProps> = ({ publication }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const history = useHistory();
  const location = useLocation();
  const [deletePublication] = useDeletePublicationMutation({
    refetchQueries: ["GetCustomerPublications"],
    onError: (error) => console.log(error),
  });
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        rounded={100}
        aria-label="Options"
        icon={<AiOutlineEllipsis />}
        variant="outline"
      />
      <MenuList>
        <MenuItem
          icon={<FiEdit2 />}
          onClick={() =>
            history.push(`${location.pathname}/publication/${publication.id}`)
          }
        >
          Modifier
        </MenuItem>
        <MenuItem icon={<FiCopy />} disabled>
          Dupliquer
        </MenuItem>
        <MenuItem icon={<FiTrash2 />} onClick={onOpen}>
          <ConfirmModal
            bodyText={`Voulez-vous supprimer ${publication.name} ?`}
            confirmButtonText="Supprimer"
            onConfirm={() => {
              onClose();
              deletePublication({
                variables: { id: publication.id },
              });
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
          Supprimer
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
