import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { VStack } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { Input } from "@chakra-ui/input";
import { MediaTypeEnum } from "../../generated/graphql";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/number-input";
import { HStack, Select } from "@chakra-ui/react";
import { MediaFormProps } from "./MediaTypes";
import { MediaListUploadState, MediaUpload } from "./MediaUpload";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export const MediaImageForm: FunctionComponent<MediaFormProps> = ({
  onSubmitted,
  defaultValues,
  create,
}) => {
  const [duration, setDuration] = useState(
    defaultValues?.duration.toString() || "10"
  );
  const [objectFit, setObjectFit] = useState(
    defaultValues?.objectFit || "fill"
  );
  const [endDate, setEndDate] = useState<string | null>(
    defaultValues?.endDate || null
  );
  const [startDate, setStartDate] = useState<string | null>(
    defaultValues?.startDate || null
  );
  const [uploadedMediaList, setUploadedMediaList] =
    useState<MediaListUploadState>(
      defaultValues?.media
        ? {
            [defaultValues.media.id]: {
              media: defaultValues.media,
              duration: defaultValues.duration,
              progress: null,
            },
          }
        : {}
    );
  return (
    <form
      id="media-form"
      style={{ width: "100%" }}
      onSubmit={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        Object.values(uploadedMediaList).forEach((media) => {
          media.media &&
            onSubmitted({
              media: media.media,
              duration: parseInt(duration),
              objectFit,
              endDate: endDate || null,
              startDate: startDate || null,
            });
        });
      }}
    >
      <VStack alignSelf="flex-start">
        <FormControl>
          <FormLabel>Disposition</FormLabel>
          <Select
            placeholder="Select option"
            defaultValue={objectFit}
            onChange={(e) => setObjectFit(e.currentTarget.value)}
          >
            <option value="fill">Etirer</option>
            <option value="contain">Contenir</option>
            <option value="cover">Rogné</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Validité</FormLabel>
          <HStack>
            <Input
              type="date"
              onChange={(e) => setStartDate(e.currentTarget.value)}
              defaultValue={startDate || ""}
            />
            <Flex justifyContent="center" height="100%">
              <ArrowForwardIcon color="gray.500" alignSelf="center" />
            </Flex>
            <Input
              type="date"
              onChange={(e) => setEndDate(e.currentTarget.value)}
              defaultValue={endDate || ""}
            />
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel>Durée d'affichage (seconde)</FormLabel>
          <NumberInput
            onChange={(valueString) => setDuration(valueString)}
            value={duration}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        {create && (
          <FormControl>
            <FormLabel>Image</FormLabel>
            <MediaUpload
              onMediaUpdate={(media) =>
                setUploadedMediaList((prevState) => ({
                  ...prevState,
                  [media.media!.id]: {
                    ...media,
                  },
                }))
              }
              mediaType={MediaTypeEnum.Image}
            />
          </FormControl>
        )}
      </VStack>
    </form>
  );
};
