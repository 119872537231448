import { FontInfo, Loader } from "@govisupro/studio/dist/studio/types";
import { useEffect } from "react";
import WebFont from "webfontloader";

export const useLoadFonts = () => {
  useEffect(() => {
    console.log("FONT loading");

    loadFonts();
  }, []);
};

const loadFonts = () => {
  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: fonts
          .filter((font) => font.provider === "google")
          .map((font) => font.name + ":" + font.weights.join(",")),
      },
      active: () => resolve(null),
      inactive: reject,
    });
  });
};

export const fonts: FontInfo[] = [
  {
    name: "Inter",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Poppins",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Montserrat",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Rubik",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Mulish",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Lato",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Space Grotesk",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Raleway",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Open Sans",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Alegreya",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Oswald",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Lobster",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["400", "700"],
  },
  {
    name: "Arial",
    loader: Loader.system,
    provider: "google",
    weights: ["300", "400", "700"],
  },
];
