import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Box, VStack, Flex, HStack } from "@chakra-ui/layout";
import { FunctionComponent, useContext, useState } from "react";
import {
  MediaContentNode,
  MediaTypeEnum,
  useCreateMediaContentMutation,
} from "../../generated/graphql";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/number-input";
import { Input } from "@chakra-ui/input";
import { CustomerContext } from "../../screens/NavigationContext";
import { useToast } from "@chakra-ui/toast";
import { MediaFormProps } from "./MediaTypes";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export const MediaWebpageForm: FunctionComponent<MediaFormProps> = ({
  create,
  defaultValues,
  onSubmitted,
}) => {
  const [createMediaContent] = useCreateMediaContentMutation();
  const [duration, setDuration] = useState(
    defaultValues?.duration.toString() || "10"
  );
  const [fallbackRefreshPeriod] = useState("24");
  const [webPageUrl, setWebPageUrl] = useState<string | null>(
    defaultValues?.media.webpageUrl || null
  );
  const [startDate, setStartDate] = useState<string | null>(
    defaultValues?.startDate || null
  );
  const [endDate, setEndDate] = useState<string | null>(
    defaultValues?.endDate || null
  );
  const customerId = useContext(CustomerContext);
  const toast = useToast();

  return (
    <form
      id="media-form"
      style={{ width: "100%" }}
      onSubmit={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!webPageUrl || !customerId) {
          return;
        }
        const result = await createMediaContent({
          variables: {
            contentType: MediaTypeEnum.Iframe,
            name: webPageUrl,
            customerId: customerId,
            webpageUrl: webPageUrl,
            fallbackRefreshPeriod: parseInt(fallbackRefreshPeriod),
          },
        });
        if (result.data && result.data!.createMediaContent) {
          onSubmitted({
            media: result.data!.createMediaContent.media as MediaContentNode,
            duration: parseInt(duration),
            objectFit: "",
            startDate: startDate || null,
            endDate: endDate || null,
          });
        } else {
          toast({
            title: `erreur lors de l'enregistrement de ${webPageUrl}`,
            status: "error",
            isClosable: true,
          });
        }
      }}
    >
      <VStack alignSelf="flex-start" spacing="12px">
        <FormControl>
          <FormLabel>Adresse de la page</FormLabel>
          <Input
            type="url"
            placeholder="https://www.exemple.fr"
            onChange={(valueString) =>
              setWebPageUrl(valueString.currentTarget.value)
            }
            defaultValue={webPageUrl || ""}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Durée d'affichage</FormLabel>
          <NumberInput
            onChange={(valueString) => setDuration(valueString)}
            value={duration}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Validité</FormLabel>
          <HStack>
            <Input
              type="date"
              onChange={(e) => setStartDate(e.currentTarget.value)}
              defaultValue={startDate || ""}
            />
            <Flex justifyContent="center" height="100%">
              <ArrowForwardIcon color="gray.500" alignSelf="center" />
            </Flex>
            <Input
              type="date"
              onChange={(e) => setEndDate(e.currentTarget.value)}
              defaultValue={endDate || ""}
            />
          </HStack>
        </FormControl>
        {/* <FormControl>
          <FormLabel>Période de capture (hors ligne)</FormLabel>
          <NumberInput
            onChange={(valueString) =>
              setFallbackRefreshPeriod(parse(valueString, "h"))
            }
            value={format(fallbackRefreshPeriod, "h")}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl> */}
        {webPageUrl && (
          <Box>
            <iframe
              title="Web Page Preview"
              height="300"
              src={webPageUrl}
              frameBorder="0"
              allowTransparency
              allow="encrypted-media"
            />
          </Box>
        )}
      </VStack>
    </form>
  );
};
