import { Box, Flex } from "@chakra-ui/layout";
import React, { FunctionComponent } from "react";

type HeaderSideMenuContentProps = {
  header: React.ReactNode;
  bar: React.ReactNode;
};

export const HeaderSideMenuContent: FunctionComponent<
  HeaderSideMenuContentProps
> = ({ children, header, bar }) => {
  return (
    <Flex flexDir="column" height="100%" margin="0">
      <Flex height={"103px"} bg="white" alignItems="center">
        {header}
      </Flex>
      <Flex flexDir="row" flex={2}>
        <Box
          minWidth={"124px"}
          width="124px"
          p="8px 20px"
          // borderRight="2px solid"
          // borderColor="gray.200"
        >
          {bar}
        </Box>
        <Box flexGrow={1}>{children}</Box>
      </Flex>
    </Flex>
  );
};
