import { ButtonGroup, IconButton, IconButtonProps } from "@chakra-ui/button";
import {
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
} from "@chakra-ui/editable";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Flex, HStack } from "@chakra-ui/layout";
import { FunctionComponent } from "react";

import {
  PublicationNode,
  useUpdatePublicationMutation,
} from "../../generated/graphql";

const EditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        icon={<CheckIcon />}
        {...(getSubmitButtonProps() as IconButtonProps)}
      />
      <IconButton
        icon={<CloseIcon />}
        {...(getCancelButtonProps() as IconButtonProps)}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        size="sm"
        icon={<EditIcon />}
        {...(getEditButtonProps() as IconButtonProps)}
      />
    </Flex>
  );
};

type EditableNameProps = {
  publication: PublicationNode;
};
export const EditableName: FunctionComponent<EditableNameProps> = ({
  publication,
}) => {
  const [updatePublication] = useUpdatePublicationMutation({
    refetchQueries: ["GetPublication"],
  });
  return (
    <Editable
      // fontSize="lg"
      // fontWeight="bold"
      // color="gray.700"
      // lineHeight="40px"
      defaultValue={publication.name}
      isPreviewFocusable={false}
      onSubmit={(nextValue) =>
        updatePublication({
          variables: {
            id: publication.id,
            name: nextValue,
          },
        })
      }
    >
      <HStack>
        <EditablePreview />
        <EditableInput />
        <EditableControls />
      </HStack>
    </Editable>
  );
};
