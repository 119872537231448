import { ButtonProps } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { FunctionComponent, useContext } from "react";
import { client } from "../../api";
import { UploadButton } from "../../components/UploadButton";
import {
  CreateMediaContentDocument,
  CreateMediaContentMutation,
  MediaContentNode,
  MediaTypeEnum,
} from "../../generated/graphql";
import { uploadFileToS3 } from "../../libs/fileHelpers";
import { CustomerContext } from "../../screens/NavigationContext";

const hashFile = async (file: File) => {
  const arrayBufer = await file.arrayBuffer();
  const digest = await crypto.subtle.digest("SHA-256", arrayBufer);
  // convert array buffer to hexa string
  return Array.from(new Uint8Array(digest))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

interface UploadMediaButtonProps extends ButtonProps {
  mediaType: MediaTypeEnum;
  accept: string;
  onUploadFinished: (media: MediaContentNode) => void;
  onFilesSelected?: (files: FileList) => void;
  onUploadUpdate?: (progress: any, id: string) => void;
}

export const UploadMediaButton: FunctionComponent<UploadMediaButtonProps> = ({
  children,
  onUploadFinished,
  onFilesSelected,
  mediaType,
  onUploadUpdate,
  ...props
}) => {
  const customerId = useContext(CustomerContext);

  const toast = useToast();
  return (
    <UploadButton
      {...props}
      onFileChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilesSelected &&
          event.currentTarget.files &&
          onFilesSelected(event.currentTarget.files);
        Array.from(event.currentTarget.files || []).forEach(async (file) => {
          const sha256 = await hashFile(file);
          const { data: mediaContentData } =
            await client.mutate<CreateMediaContentMutation>({
              mutation: CreateMediaContentDocument,
              variables: {
                customerId: customerId,
                name: file.name,
                contentType: mediaType,
                sha256: sha256,
              },
            });
          if (mediaContentData?.createMediaContent?.presignedUrl) {
            const response = await uploadFileToS3(
              mediaContentData?.createMediaContent?.presignedUrl as string,
              file,
              (progress) =>
                onUploadUpdate &&
                onUploadUpdate(
                  progress,
                  mediaContentData.createMediaContent!.media!.id
                )
            );
            if (response.status === 200) {
              onUploadFinished(
                mediaContentData.createMediaContent.media as MediaContentNode
              );
            } else {
              toast({
                title: `erreur lors de l'import de ${file.name}`,
                status: "error",
                isClosable: true,
              });
            }
          }
        });
      }}
    >
      {children}
    </UploadButton>
  );
};
