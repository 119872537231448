import { useState } from "react";
import { useContext } from "react";
import { FunctionComponent } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import Select from "react-select";
import {
  DisplayComputerListItemFragment,
  useGetGroupsQuery,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";

type SelecGroupProps = {
  displayComputer: DisplayComputerListItemFragment;
  field: ControllerRenderProps<FieldValues, "groups">;
};

export const SelectGroup: FunctionComponent<SelecGroupProps> = ({
  displayComputer,
  field,
}) => {
  const [search, setSearch] = useState("");
  const customerId = useContext(CustomerContext);
  const { data } = useGetGroupsQuery({
    variables: {
      customerId: customerId || "",
      first: 10,
      nameStartsWith: search,
    },
  });
  const selectedOptions = displayComputer.groups?.edges?.map((group) => ({
    value: group?.node?.id,
    label: group?.node?.name,
  }));
  return (
    <Select
      {...field}
      isMulti
      defaultValue={selectedOptions}
      options={data?.customer?.displaygroupSet.edges.map((group) => ({
        value: group?.node?.id,
        label: group?.node?.name,
        selected: true,
      }))}
      onInputChange={setSearch}
    />
  );
};
