import {
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React, { FunctionComponent, useContext } from "react";
import { useRemoveUserMutation, UserNode } from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";

type RemoveUserProps = {
  user: UserNode;
};

export const RemoveUser: FunctionComponent<RemoveUserProps> = ({ user }) => {
  const [removeUser] = useRemoveUserMutation({
    refetchQueries: ["GetCustomerUsers"],
  });
  const customerId = useContext(CustomerContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  return (
    <>
      <Button onClick={onOpen}>Retirer</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Retirer un utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {" "}
            Enlever {user.email} de ce parc ? Cela ne supprime pas
            l'utilisateur.
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                removeUser({
                  variables: { id: user.id, customerId: customerId || "" },
                }).then((result) => {
                  result.errors
                    ? toast({
                        title: "erreur lors de la mise à jour.",
                        status: "error",
                        description: (result.errors as any).message,
                        isClosable: true,
                      })
                    : onClose();
                });
              }}
            >
              Retirer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
