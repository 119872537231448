import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
};

export type ActivateWidgetOnCustomerInput = {
  customerIds: Array<Maybe<Scalars['ID']>>;
  widgetId: Scalars['ID'];
  check: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivateWidgetOnCustomerPayload = {
  __typename?: 'ActivateWidgetOnCustomerPayload';
  customers?: Maybe<CustomerConnection>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type ActivateWidgetOnCustomerPayloadCustomersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddDisplayGroupToAllocationInput = {
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayGroupToAllocationPayload = {
  __typename?: 'AddDisplayGroupToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayToAllocationInput = {
  id: Scalars['ID'];
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayToAllocationPayload = {
  __typename?: 'AddDisplayToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddGroupToComputerInput = {
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddGroupToComputerPayload = {
  __typename?: 'AddGroupToComputerPayload';
  displayGroup?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserInput = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignBackOfficeVersionToCustomerInput = {
  customerId: Scalars['ID'];
  backofficeVersionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignBackOfficeVersionToCustomerPayload = {
  __typename?: 'AssignBackOfficeVersionToCustomerPayload';
  customer?: Maybe<CustomerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignPlayerVersionToComputerInput = {
  computerId: Scalars['ID'];
  playerVersionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignPlayerVersionToComputerPayload = {
  __typename?: 'AssignPlayerVersionToComputerPayload';
  computer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BackOfficeVersionNode = Node & {
  __typename?: 'BackOfficeVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  customerSet: CustomerNodeConnection;
};


export type BackOfficeVersionNodeCustomerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};

export type BackOfficeVersionNodeConnection = {
  __typename?: 'BackOfficeVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BackOfficeVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BackOfficeVersionNode` and its cursor. */
export type BackOfficeVersionNodeEdge = {
  __typename?: 'BackOfficeVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BackOfficeVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ConvertVideoMutationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConvertVideoMutationPayload = {
  __typename?: 'ConvertVideoMutationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBackOfficeVersionInput = {
  version: Scalars['String'];
  number?: Maybe<Scalars['Float']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBackOfficeVersionPayload = {
  __typename?: 'CreateBackOfficeVersionPayload';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  customer?: Maybe<CustomerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayComputerInput = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isMuted?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  browserId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayComputerPayload = {
  __typename?: 'CreateDisplayComputerPayload';
  displayComputer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayGroupInput = {
  customerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayGroupPayload = {
  __typename?: 'CreateDisplayGroupPayload';
  displayGroup?: Maybe<DisplayGroupNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayInput = {
  computerId: Scalars['ID'];
  offsetX?: Maybe<Scalars['Int']>;
  offsetY?: Maybe<Scalars['Int']>;
  widthPx: Scalars['Int'];
  heightPx: Scalars['Int'];
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayPayload = {
  __typename?: 'CreateDisplayPayload';
  display?: Maybe<DisplayNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLayoutItemInput = {
  screenId: Scalars['ID'];
  widgetName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['JSONString']>;
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLayoutItemPayload = {
  __typename?: 'CreateLayoutItemPayload';
  layoutItem?: Maybe<LayoutItemNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMediaContentInput = {
  customerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<MediaTypeEnum>;
  webpageUrl?: Maybe<Scalars['String']>;
  fallbackRefreshPeriod?: Maybe<Scalars['Int']>;
  sha256?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMediaContentPayload = {
  __typename?: 'CreateMediaContentPayload';
  media?: Maybe<MediaContentNode>;
  presignedUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePlayerVersionInput = {
  version: Scalars['String'];
  number: Scalars['Float'];
  date?: Maybe<Scalars['String']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePlayerVersionPayload = {
  __typename?: 'CreatePlayerVersionPayload';
  playerVersion?: Maybe<PlayerVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationAllocationInput = {
  publicationId: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationAllocationPayload = {
  __typename?: 'CreatePublicationAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationInput = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationMediaInput = {
  publicationScreenId: Scalars['ID'];
  layoutItemId: Scalars['ID'];
  mediaId?: Maybe<Scalars['ID']>;
  objectFit?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationMediaPayload = {
  __typename?: 'CreatePublicationMediaPayload';
  publicationMedia?: Maybe<PublicationMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationPayload = {
  __typename?: 'CreatePublicationPayload';
  publication?: Maybe<PublicationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationScreenInput = {
  publicationId: Scalars['ID'];
  layout?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationScreenPayload = {
  __typename?: 'CreatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRegistrationPinInput = {
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRegistrationPinPayload = {
  __typename?: 'CreateRegistrationPinPayload';
  pinCode?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWidgetMediaInput = {
  widgetLayoutItemId: Scalars['ID'];
  mediaContentId: Scalars['ID'];
  data?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWidgetMediaPayload = {
  __typename?: 'CreateWidgetMediaPayload';
  widgetMedia?: Maybe<WidgetMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Customer` and its cursor. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerNode = Node & {
  __typename?: 'CustomerNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  userSet: UserNodeConnection;
  mediacontentSet: MediaContentNodeConnection;
  displaygroupSet: DisplayGroupNodeConnection;
  displaycomputerSet: DisplayComputerNodeConnection;
  layoutSet: LayoutNodeConnection;
  publicationSet: PublicationNodeConnection;
  activatedWidgets: WidgetNodeConnection;
};


export type CustomerNodeUserSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
};


export type CustomerNodeMediacontentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type CustomerNodeDisplaygroupSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type CustomerNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type CustomerNodeLayoutSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type CustomerNodePublicationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type CustomerNodeActivatedWidgetsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type CustomerNodeConnection = {
  __typename?: 'CustomerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CustomerNode` and its cursor. */
export type CustomerNodeEdge = {
  __typename?: 'CustomerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};




export type DeleteBackOfficeVersionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteBackOfficeVersionPayload = {
  __typename?: 'DeleteBackOfficeVersionPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayComputerInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayComputerPayload = {
  __typename?: 'DeleteDisplayComputerPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayGroupInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayGroupPayload = {
  __typename?: 'DeleteDisplayGroupPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayPayload = {
  __typename?: 'DeleteDisplayPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteJsonWebTokenCookie = {
  __typename?: 'DeleteJSONWebTokenCookie';
  deleted: Scalars['Boolean'];
};

export type DeleteLayoutItemInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLayoutItemPayload = {
  __typename?: 'DeleteLayoutItemPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlayerVersionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlayerVersionPayload = {
  __typename?: 'DeletePlayerVersionPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationAllocationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationAllocationPayload = {
  __typename?: 'DeletePublicationAllocationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationMediaInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationMediaPayload = {
  __typename?: 'DeletePublicationMediaPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationPayload = {
  __typename?: 'DeletePublicationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationScreenInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationScreenPayload = {
  __typename?: 'DeletePublicationScreenPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRegistrationInput = {
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRegistrationPayload = {
  __typename?: 'DeleteRegistrationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWidgetMediaInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWidgetMediaPayload = {
  __typename?: 'DeleteWidgetMediaPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisplayComputerAdminFieldsNode = Node & {
  __typename?: 'DisplayComputerAdminFieldsNode';
  /** The ID of the object */
  id: Scalars['ID'];
  computer: DisplayComputerNode;
  anydesk: Scalars['String'];
  informations: Scalars['String'];
  serialNumber: Scalars['String'];
  designation: Scalars['String'];
};

export type DisplayComputerNode = Node & {
  __typename?: 'DisplayComputerNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  groups: DisplayGroupNodeConnection;
  name: Scalars['String'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  browserId: Scalars['String'];
  isMuted: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  isActive: Scalars['Boolean'];
  hasLog: Scalars['Boolean'];
  monday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  friday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  playerVersion?: Maybe<PlayerVersionNode>;
  platformVersion?: Maybe<Scalars['Float']>;
  platformVersionFk?: Maybe<PlatformVersionNode>;
  displaycomputeradminfields?: Maybe<DisplayComputerAdminFieldsNode>;
  displaySet: DisplayNodeConnection;
  lastConnection?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DisplayStatusType>;
};


export type DisplayComputerNodeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type DisplayComputerNodeDisplaySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};

export type DisplayComputerNodeConnection = {
  __typename?: 'DisplayComputerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayComputerNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayComputerNode` and its cursor. */
export type DisplayComputerNodeEdge = {
  __typename?: 'DisplayComputerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayComputerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayCredentialNode = Node & {
  __typename?: 'DisplayCredentialNode';
  uuid: Scalars['String'];
  activated: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type DisplayGroupNode = Node & {
  __typename?: 'DisplayGroupNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isContainer: Scalars['Boolean'];
  displaycomputerSet: DisplayComputerNodeConnection;
  publicationallocationSet: PublicationAllocationNodeConnection;
};


export type DisplayGroupNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type DisplayGroupNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};

export type DisplayGroupNodeConnection = {
  __typename?: 'DisplayGroupNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayGroupNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayGroupNode` and its cursor. */
export type DisplayGroupNodeEdge = {
  __typename?: 'DisplayGroupNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayGroupNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayMediaErrorEventNode = Node & {
  __typename?: 'DisplayMediaErrorEventNode';
  /** The ID of the object */
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  media: MediaContentNode;
  display: DisplayNode;
};

export type DisplayMediaErrorEventNodeConnection = {
  __typename?: 'DisplayMediaErrorEventNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayMediaErrorEventNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayMediaErrorEventNode` and its cursor. */
export type DisplayMediaErrorEventNodeEdge = {
  __typename?: 'DisplayMediaErrorEventNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayMediaErrorEventNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayMediaStatusNode = Node & {
  __typename?: 'DisplayMediaStatusNode';
  /** The ID of the object */
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  media: MediaContentNode;
  display: DisplayNode;
  status: DisplayParkDisplayMediaStatusStatusChoices;
};

export type DisplayMediaStatusNodeConnection = {
  __typename?: 'DisplayMediaStatusNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayMediaStatusNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayMediaStatusNode` and its cursor. */
export type DisplayMediaStatusNodeEdge = {
  __typename?: 'DisplayMediaStatusNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayMediaStatusNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayNode = Node & {
  __typename?: 'DisplayNode';
  /** The ID of the object */
  id: Scalars['ID'];
  computer: DisplayComputerNode;
  offsetX: Scalars['Int'];
  offsetY: Scalars['Int'];
  widthPx: Scalars['Int'];
  heightPx: Scalars['Int'];
  pitch?: Maybe<Scalars['Float']>;
  orientation: DisplayParkDisplayOrientationChoices;
  displaycredential?: Maybe<DisplayCredentialNode>;
  displaymediastatusSet: DisplayMediaStatusNodeConnection;
  displaymediaerroreventSet: DisplayMediaErrorEventNodeConnection;
  publicationallocationSet: PublicationAllocationNodeConnection;
};


export type DisplayNodeDisplaymediastatusSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DisplayNodeDisplaymediaerroreventSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DisplayNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};

export type DisplayNodeConnection = {
  __typename?: 'DisplayNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayNode` and its cursor. */
export type DisplayNodeEdge = {
  __typename?: 'DisplayNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum DisplayParkDisplayMediaStatusStatusChoices {
  /** WAITING */
  Wa = 'WA',
  /** DOWNLOADING */
  Do = 'DO',
  /** RETRYING */
  Re = 'RE',
  /** READY */
  Ra = 'RA',
  /** MISMATCH */
  Mi = 'MI'
}

/** An enumeration. */
export enum DisplayParkDisplayOrientationChoices {
  /** UP */
  Up = 'UP',
  /** LEFT */
  Lf = 'LF',
  /** RIGHT */
  Rg = 'RG',
  /** DOWN */
  Dw = 'DW'
}

/** An enumeration. */
export enum DisplayStatusType {
  Disabled = 'DISABLED',
  Standby = 'STANDBY',
  Empty = 'EMPTY',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Live = 'LIVE',
  Error = 'ERROR'
}

export type DuplicatePublicationScreenInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DuplicatePublicationScreenPayload = {
  __typename?: 'DuplicatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForgottenPasswordInput = {
  email?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForgottenPasswordPayload = {
  __typename?: 'ForgottenPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};



export type LayoutItemNode = Node & {
  __typename?: 'LayoutItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<LayoutNode>;
  screen?: Maybe<PublicationScreenNode>;
  name: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['String'];
  width: Scalars['String'];
  height: Scalars['String'];
  alpha: Scalars['Float'];
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  publicationmediaSet: PublicationMediaNodeConnection;
  widget?: Maybe<WidgetLayoutItemNode>;
};


export type LayoutItemNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type LayoutItemNodeConnection = {
  __typename?: 'LayoutItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LayoutItemNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LayoutItemNode` and its cursor. */
export type LayoutItemNodeEdge = {
  __typename?: 'LayoutItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LayoutItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LayoutNode = Node & {
  __typename?: 'LayoutNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  customer?: Maybe<CustomerNode>;
  layoutitemSet: LayoutItemNodeConnection;
  publicationscreenSet: PublicationScreenNodeConnection;
};


export type LayoutNodeLayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type LayoutNodePublicationscreenSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};

export type LayoutNodeConnection = {
  __typename?: 'LayoutNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LayoutNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LayoutNode` and its cursor. */
export type LayoutNodeEdge = {
  __typename?: 'LayoutNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LayoutNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LogDisplayMediaErrorInput = {
  displayId: Scalars['ID'];
  mediaId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LogDisplayMediaErrorPayload = {
  __typename?: 'LogDisplayMediaErrorPayload';
  displayMediaError?: Maybe<DisplayMediaErrorEventNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MediaContentNode = Node & {
  __typename?: 'MediaContentNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer?: Maybe<CustomerNode>;
  name: Scalars['String'];
  contentType: MediaMediaContentContentTypeChoices;
  webpageUrl?: Maybe<Scalars['String']>;
  fallbackUrl?: Maybe<Scalars['String']>;
  fallbackRefreshPeriod?: Maybe<Scalars['Int']>;
  contentConverted: Scalars['Boolean'];
  sha256?: Maybe<Scalars['String']>;
  displaymediastatusSet: DisplayMediaStatusNodeConnection;
  displaymediaerroreventSet: DisplayMediaErrorEventNodeConnection;
  publicationmediaSet: PublicationMediaNodeConnection;
  widgetmediaSet: WidgetMediaNodeConnection;
  signedUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};


export type MediaContentNodeDisplaymediastatusSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MediaContentNodeDisplaymediaerroreventSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MediaContentNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};


export type MediaContentNodeWidgetmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widgetLayoutItem?: Maybe<Scalars['ID']>;
};

export type MediaContentNodeConnection = {
  __typename?: 'MediaContentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MediaContentNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MediaContentNode` and its cursor. */
export type MediaContentNodeEdge = {
  __typename?: 'MediaContentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MediaContentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MediaMediaContentContentTypeChoices {
  /** IMAGE */
  Im = 'IM',
  /** VIDEO */
  Vd = 'VD',
  /** IFRAME */
  If = 'IF'
}

/** An enumeration. */
export enum MediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Iframe = 'IFRAME'
}

export type Mutation = {
  __typename?: 'Mutation';
  createPlayerVersion?: Maybe<CreatePlayerVersionPayload>;
  updatePlayerVersion?: Maybe<UpdatePlayerVersionPayload>;
  deletePlayerVersion?: Maybe<DeletePlayerVersionPayload>;
  assignPlayerVersionToComputer?: Maybe<AssignPlayerVersionToComputerPayload>;
  createBackofficeVersion?: Maybe<CreateBackOfficeVersionPayload>;
  updateBackofficeVersion?: Maybe<UpdateBackOfficeVersionPayload>;
  deleteBackofficeVersion?: Maybe<DeleteBackOfficeVersionPayload>;
  assignBackofficeVersionToCustomer?: Maybe<AssignBackOfficeVersionToCustomerPayload>;
  activateWidgetOnCustomer?: Maybe<ActivateWidgetOnCustomerPayload>;
  createWidgetMedia?: Maybe<CreateWidgetMediaPayload>;
  updateWidgetMedia?: Maybe<UpdateWidgetMediaPayload>;
  deleteWidgetMedia?: Maybe<DeleteWidgetMediaPayload>;
  createPublicationMedia?: Maybe<CreatePublicationMediaPayload>;
  updatePublicationMedia?: Maybe<UpdatePublicationMediaPayload>;
  deletePublicationMedia?: Maybe<DeletePublicationMediaPayload>;
  createPublicationAllocation?: Maybe<CreatePublicationAllocationPayload>;
  updatePublicationAllocation?: Maybe<UpdatePublicationAllocationPayload>;
  deletePublicationAllocation?: Maybe<DeletePublicationAllocationPayload>;
  addDisplayGroupToAllocation?: Maybe<AddDisplayGroupToAllocationPayload>;
  addDisplayToAllocation?: Maybe<AddDisplayToAllocationPayload>;
  removeDisplayGroupToAllocation?: Maybe<RemoveDisplayGroupToAllocationPayload>;
  removeDisplayToAllocation?: Maybe<RemoveDisplayToAllocationPayload>;
  createPublicationScreen?: Maybe<CreatePublicationScreenPayload>;
  updatePublicationScreen?: Maybe<UpdatePublicationScreenPayload>;
  deletePublicationScreen?: Maybe<DeletePublicationScreenPayload>;
  duplicatePublicationScreen?: Maybe<DuplicatePublicationScreenPayload>;
  createPublication?: Maybe<CreatePublicationPayload>;
  updatePublication?: Maybe<UpdatePublicationPayload>;
  deletePublication?: Maybe<DeletePublicationPayload>;
  createLayoutItem?: Maybe<CreateLayoutItemPayload>;
  updateLayoutItem?: Maybe<UpdateLayoutItemPayload>;
  deleteLayoutItem?: Maybe<DeleteLayoutItemPayload>;
  createMediaContent?: Maybe<CreateMediaContentPayload>;
  convertMediaContent?: Maybe<ConvertVideoMutationPayload>;
  updateAdminFields?: Maybe<UpdateAdminFieldsPayload>;
  setDisplayMediaStatus?: Maybe<SetDisplayMediaStatusPayload>;
  logDisplayMediaError?: Maybe<LogDisplayMediaErrorPayload>;
  createDisplayGroup?: Maybe<CreateDisplayGroupPayload>;
  updateDisplayGroup?: Maybe<UpdateDisplayGroupPayload>;
  deleteDisplayGroup?: Maybe<DeleteDisplayGroupPayload>;
  createDisplay?: Maybe<CreateDisplayPayload>;
  updateDisplay?: Maybe<UpdateDisplayPayload>;
  deleteDisplay?: Maybe<DeleteDisplayPayload>;
  createDisplayComputer?: Maybe<CreateDisplayComputerPayload>;
  updateDisplayComputer?: Maybe<UpdateDisplayComputerPayload>;
  deleteDisplayComputer?: Maybe<DeleteDisplayComputerPayload>;
  addGroupToComputer?: Maybe<AddGroupToComputerPayload>;
  removeGroupToComputer?: Maybe<RemoveGroupToComputerPayload>;
  createRegistrationPin?: Maybe<CreateRegistrationPinPayload>;
  deleteRegistration?: Maybe<DeleteRegistrationPayload>;
  registerDisplay?: Maybe<RegisterDisplayPayload>;
  createCustomer?: Maybe<CreateCustomerPayload>;
  addUser?: Maybe<AddUserPayload>;
  removeUser?: Maybe<RemoveUserPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  forgottenPassword?: Maybe<ForgottenPasswordPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  deleteTokenCookie?: Maybe<DeleteJsonWebTokenCookie>;
};


export type MutationCreatePlayerVersionArgs = {
  input: CreatePlayerVersionInput;
};


export type MutationUpdatePlayerVersionArgs = {
  input: UpdatePlayerVersionInput;
};


export type MutationDeletePlayerVersionArgs = {
  input: DeletePlayerVersionInput;
};


export type MutationAssignPlayerVersionToComputerArgs = {
  input: AssignPlayerVersionToComputerInput;
};


export type MutationCreateBackofficeVersionArgs = {
  input: CreateBackOfficeVersionInput;
};


export type MutationUpdateBackofficeVersionArgs = {
  input: UpdateBackOfficeVersionInput;
};


export type MutationDeleteBackofficeVersionArgs = {
  input: DeleteBackOfficeVersionInput;
};


export type MutationAssignBackofficeVersionToCustomerArgs = {
  input: AssignBackOfficeVersionToCustomerInput;
};


export type MutationActivateWidgetOnCustomerArgs = {
  input: ActivateWidgetOnCustomerInput;
};


export type MutationCreateWidgetMediaArgs = {
  input: CreateWidgetMediaInput;
};


export type MutationUpdateWidgetMediaArgs = {
  input: UpdateWidgetMediaInput;
};


export type MutationDeleteWidgetMediaArgs = {
  input: DeleteWidgetMediaInput;
};


export type MutationCreatePublicationMediaArgs = {
  input: CreatePublicationMediaInput;
};


export type MutationUpdatePublicationMediaArgs = {
  input: UpdatePublicationMediaInput;
};


export type MutationDeletePublicationMediaArgs = {
  input: DeletePublicationMediaInput;
};


export type MutationCreatePublicationAllocationArgs = {
  input: CreatePublicationAllocationInput;
};


export type MutationUpdatePublicationAllocationArgs = {
  input: UpdatePublicationAllocationInput;
};


export type MutationDeletePublicationAllocationArgs = {
  input: DeletePublicationAllocationInput;
};


export type MutationAddDisplayGroupToAllocationArgs = {
  input: AddDisplayGroupToAllocationInput;
};


export type MutationAddDisplayToAllocationArgs = {
  input: AddDisplayToAllocationInput;
};


export type MutationRemoveDisplayGroupToAllocationArgs = {
  input: RemoveDisplayGroupToAllocationInput;
};


export type MutationRemoveDisplayToAllocationArgs = {
  input: RemoveDisplayToAllocationInput;
};


export type MutationCreatePublicationScreenArgs = {
  input: CreatePublicationScreenInput;
};


export type MutationUpdatePublicationScreenArgs = {
  input: UpdatePublicationScreenInput;
};


export type MutationDeletePublicationScreenArgs = {
  input: DeletePublicationScreenInput;
};


export type MutationDuplicatePublicationScreenArgs = {
  input: DuplicatePublicationScreenInput;
};


export type MutationCreatePublicationArgs = {
  input: CreatePublicationInput;
};


export type MutationUpdatePublicationArgs = {
  input: UpdatePublicationInput;
};


export type MutationDeletePublicationArgs = {
  input: DeletePublicationInput;
};


export type MutationCreateLayoutItemArgs = {
  input: CreateLayoutItemInput;
};


export type MutationUpdateLayoutItemArgs = {
  input: UpdateLayoutItemInput;
};


export type MutationDeleteLayoutItemArgs = {
  input: DeleteLayoutItemInput;
};


export type MutationCreateMediaContentArgs = {
  input: CreateMediaContentInput;
};


export type MutationConvertMediaContentArgs = {
  input: ConvertVideoMutationInput;
};


export type MutationUpdateAdminFieldsArgs = {
  input: UpdateAdminFieldsInput;
};


export type MutationSetDisplayMediaStatusArgs = {
  input: SetDisplayMediaStatusInput;
};


export type MutationLogDisplayMediaErrorArgs = {
  input: LogDisplayMediaErrorInput;
};


export type MutationCreateDisplayGroupArgs = {
  input: CreateDisplayGroupInput;
};


export type MutationUpdateDisplayGroupArgs = {
  input: UpdateDisplayGroupInput;
};


export type MutationDeleteDisplayGroupArgs = {
  input: DeleteDisplayGroupInput;
};


export type MutationCreateDisplayArgs = {
  input: CreateDisplayInput;
};


export type MutationUpdateDisplayArgs = {
  input: UpdateDisplayInput;
};


export type MutationDeleteDisplayArgs = {
  input: DeleteDisplayInput;
};


export type MutationCreateDisplayComputerArgs = {
  input: CreateDisplayComputerInput;
};


export type MutationUpdateDisplayComputerArgs = {
  input: UpdateDisplayComputerInput;
};


export type MutationDeleteDisplayComputerArgs = {
  input: DeleteDisplayComputerInput;
};


export type MutationAddGroupToComputerArgs = {
  input: AddGroupToComputerInput;
};


export type MutationRemoveGroupToComputerArgs = {
  input: RemoveGroupToComputerInput;
};


export type MutationCreateRegistrationPinArgs = {
  input: CreateRegistrationPinInput;
};


export type MutationDeleteRegistrationArgs = {
  input: DeleteRegistrationInput;
};


export type MutationRegisterDisplayArgs = {
  input: RegisterDisplayInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationForgottenPasswordArgs = {
  input: ForgottenPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PlatformVersionNode = Node & {
  __typename?: 'PlatformVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  windowsInstaller?: Maybe<Scalars['String']>;
  linuxInstaller?: Maybe<Scalars['String']>;
  raspberryInstaller?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  displaycomputerSet: DisplayComputerNodeConnection;
};


export type PlatformVersionNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PlatformVersionNodeConnection = {
  __typename?: 'PlatformVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlatformVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PlatformVersionNode` and its cursor. */
export type PlatformVersionNodeEdge = {
  __typename?: 'PlatformVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlatformVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PlayerVersionNode = Node & {
  __typename?: 'PlayerVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  minimumBackofficeVersion: Scalars['Float'];
  displaycomputerSet: DisplayComputerNodeConnection;
};


export type PlayerVersionNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PlayerVersionNodeConnection = {
  __typename?: 'PlayerVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlayerVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PlayerVersionNode` and its cursor. */
export type PlayerVersionNodeEdge = {
  __typename?: 'PlayerVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlayerVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PriorityEnum {
  VeryHigh = 'VERY_HIGH',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  VeryLow = 'VERY_LOW'
}

export type PublicationAllocationNode = Node & {
  __typename?: 'PublicationAllocationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publication: PublicationNode;
  priority: PublicationsPublicationAllocationPriorityChoices;
  active: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  display: DisplayNodeConnection;
  displayGroup: DisplayGroupNodeConnection;
};


export type PublicationAllocationNodeDisplayArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};


export type PublicationAllocationNodeDisplayGroupArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};

export type PublicationAllocationNodeConnection = {
  __typename?: 'PublicationAllocationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationAllocationNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationAllocationNode` and its cursor. */
export type PublicationAllocationNodeEdge = {
  __typename?: 'PublicationAllocationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationAllocationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationMediaNode = Node & {
  __typename?: 'PublicationMediaNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publicationScreen: PublicationScreenNode;
  order: Scalars['Int'];
  layoutItem?: Maybe<LayoutItemNode>;
  duration: Scalars['Int'];
  objectFit: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  media?: Maybe<MediaContentNode>;
};

export type PublicationMediaNodeConnection = {
  __typename?: 'PublicationMediaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationMediaNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationMediaNode` and its cursor. */
export type PublicationMediaNodeEdge = {
  __typename?: 'PublicationMediaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationMediaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationNode = Node & {
  __typename?: 'PublicationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  publicationallocationSet: PublicationAllocationNodeConnection;
  publicationscreenSet: PublicationScreenNodeConnection;
};


export type PublicationNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};


export type PublicationNodePublicationscreenSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};

export type PublicationNodeConnection = {
  __typename?: 'PublicationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationNode` and its cursor. */
export type PublicationNodeEdge = {
  __typename?: 'PublicationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationScreenNode = Node & {
  __typename?: 'PublicationScreenNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publication: PublicationNode;
  layout?: Maybe<LayoutNode>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor: Scalars['String'];
  layoutitemSet: LayoutItemNodeConnection;
  publicationmediaSet: PublicationMediaNodeConnection;
  thumbnailUrl?: Maybe<Scalars['String']>;
  putThumbnailUrl?: Maybe<Scalars['String']>;
};


export type PublicationScreenNodeLayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type PublicationScreenNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type PublicationScreenNodeConnection = {
  __typename?: 'PublicationScreenNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationScreenNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationScreenNode` and its cursor. */
export type PublicationScreenNodeEdge = {
  __typename?: 'PublicationScreenNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationScreenNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PublicationsPublicationAllocationPriorityChoices {
  /** VERY_HIGH */
  A_4 = 'A_4',
  /** HIGH */
  A_3 = 'A_3',
  /** MEDIUM */
  A_2 = 'A_2',
  /** LOW */
  A_1 = 'A_1',
  /** VERY_LOW */
  A_0 = 'A_0'
}

export type Query = {
  __typename?: 'Query';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  playerVersion?: Maybe<PlayerVersionNode>;
  platformVersion?: Maybe<PlatformVersionNode>;
  backofficeVersionList?: Maybe<BackOfficeVersionNodeConnection>;
  playerVersionList?: Maybe<PlayerVersionNodeConnection>;
  platformVersionList?: Maybe<PlatformVersionNodeConnection>;
  requestLoki?: Maybe<Scalars['String']>;
  widget?: Maybe<WidgetNode>;
  allWidgets?: Maybe<WidgetNodeConnection>;
  mediaContent?: Maybe<MediaContentNode>;
  mediaContents?: Maybe<MediaContentNodeConnection>;
  layout?: Maybe<LayoutNode>;
  layouts?: Maybe<LayoutNodeConnection>;
  layoutItem?: Maybe<LayoutItemNode>;
  layoutItems?: Maybe<LayoutItemNodeConnection>;
  display?: Maybe<DisplayNode>;
  displayProgram?: Maybe<DisplayNode>;
  displays?: Maybe<DisplayNodeConnection>;
  displayComputer?: Maybe<DisplayComputerNode>;
  displayComputers?: Maybe<DisplayComputerNodeConnection>;
  displayGroup?: Maybe<DisplayGroupNode>;
  displayGroups?: Maybe<DisplayGroupNodeConnection>;
  me?: Maybe<UserNode>;
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
  customer?: Maybe<CustomerNode>;
  customers?: Maybe<CustomerNodeConnection>;
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  publicationAllocations?: Maybe<PublicationAllocationNodeConnection>;
  publication?: Maybe<PublicationNode>;
  publications?: Maybe<PublicationNodeConnection>;
  publicationScreen?: Maybe<PublicationScreenNode>;
  publicationScreens?: Maybe<PublicationScreenNodeConnection>;
  publicationMedia?: Maybe<PublicationMediaNode>;
  publicationMedias?: Maybe<PublicationMediaNodeConnection>;
};


export type QueryBackofficeVersionArgs = {
  id: Scalars['ID'];
};


export type QueryPlayerVersionArgs = {
  id: Scalars['ID'];
};


export type QueryPlatformVersionArgs = {
  id: Scalars['ID'];
};


export type QueryBackofficeVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPlayerVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPlatformVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryRequestLokiArgs = {
  query: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryWidgetArgs = {
  id: Scalars['ID'];
};


export type QueryAllWidgetsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryMediaContentArgs = {
  id: Scalars['ID'];
};


export type QueryMediaContentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryLayoutArgs = {
  id: Scalars['ID'];
};


export type QueryLayoutsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type QueryLayoutItemArgs = {
  id: Scalars['ID'];
};


export type QueryLayoutItemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type QueryDisplayArgs = {
  id: Scalars['ID'];
};


export type QueryDisplaysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};


export type QueryDisplayComputerArgs = {
  id: Scalars['ID'];
};


export type QueryDisplayComputersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type QueryDisplayGroupArgs = {
  id: Scalars['ID'];
};


export type QueryDisplayGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPublicationAllocationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationAllocationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};


export type QueryPublicationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type QueryPublicationScreenArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationScreensArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};


export type QueryPublicationMediaArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationMediasArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RegisterDisplayInput = {
  pinCode: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterDisplayPayload = {
  __typename?: 'RegisterDisplayPayload';
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  customerId: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayGroupToAllocationInput = {
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayGroupToAllocationPayload = {
  __typename?: 'RemoveDisplayGroupToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayToAllocationInput = {
  id: Scalars['ID'];
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayToAllocationPayload = {
  __typename?: 'RemoveDisplayToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveGroupToComputerInput = {
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveGroupToComputerPayload = {
  __typename?: 'RemoveGroupToComputerPayload';
  displayGroup?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserInput = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  userId?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDisplayMediaStatusInput = {
  displayId: Scalars['ID'];
  mediaId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDisplayMediaStatusPayload = {
  __typename?: 'SetDisplayMediaStatusPayload';
  displayMediaStatus?: Maybe<DisplayMediaStatusNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type UpdateAdminFieldsInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  anydesk?: Maybe<Scalars['String']>;
  informations?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAdminFieldsPayload = {
  __typename?: 'UpdateAdminFieldsPayload';
  adminFields?: Maybe<DisplayComputerAdminFieldsNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBackOfficeVersionInput = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBackOfficeVersionPayload = {
  __typename?: 'UpdateBackOfficeVersionPayload';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayComputerInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  isMuted?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  browserId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  isActive?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayComputerPayload = {
  __typename?: 'UpdateDisplayComputerPayload';
  displayComputer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayGroupInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayGroupPayload = {
  __typename?: 'UpdateDisplayGroupPayload';
  displayGroup?: Maybe<DisplayGroupNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayInput = {
  id: Scalars['ID'];
  offsetX?: Maybe<Scalars['Int']>;
  offsetY?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayPayload = {
  __typename?: 'UpdateDisplayPayload';
  display?: Maybe<DisplayNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLayoutItemInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['JSONString']>;
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLayoutItemPayload = {
  __typename?: 'UpdateLayoutItemPayload';
  layoutItem?: Maybe<LayoutItemNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePlayerVersionInput = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  minimumBackofficeVersion?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePlayerVersionPayload = {
  __typename?: 'UpdatePlayerVersionPayload';
  playerVersion?: Maybe<PlayerVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationAllocationInput = {
  id: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationAllocationPayload = {
  __typename?: 'UpdatePublicationAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationMediaInput = {
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  objectFit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationMediaPayload = {
  __typename?: 'UpdatePublicationMediaPayload';
  publicationMedia?: Maybe<PublicationMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationPayload = {
  __typename?: 'UpdatePublicationPayload';
  publication?: Maybe<PublicationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationScreenInput = {
  id: Scalars['ID'];
  layoutId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationScreenPayload = {
  __typename?: 'UpdatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  userId: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWidgetMediaInput = {
  id: Scalars['ID'];
  data?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWidgetMediaPayload = {
  __typename?: 'UpdateWidgetMediaPayload';
  widgetMedia?: Maybe<WidgetMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  avatar: Scalars['String'];
  customers: CustomerNodeConnection;
  email: Scalars['String'];
  profile?: Maybe<UserProfile>;
};


export type UserNodeCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum UserProfile {
  Admin = 'ADMIN',
  ParkManager = 'PARK_MANAGER',
  ContentManager = 'CONTENT_MANAGER'
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type WidgetLayoutItemNode = Node & {
  __typename?: 'WidgetLayoutItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  widgetType: WidgetNode;
  layoutItem: LayoutItemNode;
  data?: Maybe<Scalars['JSONString']>;
  widgetmediaSet: WidgetMediaNodeConnection;
};


export type WidgetLayoutItemNodeWidgetmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widgetLayoutItem?: Maybe<Scalars['ID']>;
};

export type WidgetLayoutItemNodeConnection = {
  __typename?: 'WidgetLayoutItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetLayoutItemNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetLayoutItemNode` and its cursor. */
export type WidgetLayoutItemNodeEdge = {
  __typename?: 'WidgetLayoutItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetLayoutItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WidgetMediaNode = Node & {
  __typename?: 'WidgetMediaNode';
  /** The ID of the object */
  id: Scalars['ID'];
  widgetLayoutItem: WidgetLayoutItemNode;
  mediaContent: MediaContentNode;
  data?: Maybe<Scalars['JSONString']>;
};

export type WidgetMediaNodeConnection = {
  __typename?: 'WidgetMediaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetMediaNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetMediaNode` and its cursor. */
export type WidgetMediaNodeEdge = {
  __typename?: 'WidgetMediaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetMediaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WidgetNode = Node & {
  __typename?: 'WidgetNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  package: Scalars['String'];
  description: Scalars['String'];
  config?: Maybe<Scalars['JSONString']>;
  isActive: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  customers: CustomerNodeConnection;
  minimumBackofficeVersion: Scalars['Float'];
  widgetlayoutitemSet: WidgetLayoutItemNodeConnection;
};


export type WidgetNodeCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type WidgetNodeWidgetlayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WidgetNodeConnection = {
  __typename?: 'WidgetNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetNode` and its cursor. */
export type WidgetNodeEdge = {
  __typename?: 'WidgetNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerListItemFragment = (
  { __typename?: 'CustomerNode' }
  & Pick<CustomerNode, 'id' | 'name' | 'address'>
  & { userSet: (
    { __typename?: 'UserNodeConnection' }
    & Pick<UserNodeConnection, 'edgeCount'>
  ), displaycomputerSet: (
    { __typename?: 'DisplayComputerNodeConnection' }
    & Pick<DisplayComputerNodeConnection, 'edgeCount'>
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayComputerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayComputerNode' }
        & Pick<DisplayComputerNode, 'status'>
      )> }
    )>> }
  ) }
);

export type GetCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers?: Maybe<(
    { __typename?: 'CustomerNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CustomerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CustomerNode' }
        & CustomerListItemFragment
      )> }
    )>> }
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'id' | 'name'>
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CreateCustomerPayload' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & Pick<CustomerNode, 'id' | 'name'>
    )> }
  )> }
);

export type DisplayComputerMonitoringFragment = (
  { __typename?: 'DisplayComputerNode' }
  & Pick<DisplayComputerNode, 'id' | 'name' | 'status' | 'startTime' | 'endTime' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
  & { customer: (
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'name'>
  ), displaycomputeradminfields?: Maybe<(
    { __typename?: 'DisplayComputerAdminFieldsNode' }
    & Pick<DisplayComputerAdminFieldsNode, 'anydesk'>
  )> }
);

export type GetDisplayComputersStatusQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetDisplayComputersStatusQuery = (
  { __typename?: 'Query' }
  & { displayComputers?: Maybe<(
    { __typename?: 'DisplayComputerNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayComputerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayComputerNode' }
        & DisplayComputerMonitoringFragment
      )> }
    )>> }
  )> }
);

export type RequestLokiQueryVariables = Exact<{
  query: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
}>;


export type RequestLokiQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'requestLoki'>
);

export type LayoutWidgetFragment = (
  { __typename?: 'WidgetLayoutItemNode' }
  & Pick<WidgetLayoutItemNode, 'id' | 'data'>
  & { widgetType: (
    { __typename?: 'WidgetNode' }
    & Pick<WidgetNode, 'id' | 'name'>
  ), widgetmediaSet: (
    { __typename?: 'WidgetMediaNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WidgetMediaNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'WidgetMediaNode' }
        & WidgetMediaFragmentFragment
      )> }
    )>> }
  ) }
);

export type LayoutItemFragment = (
  { __typename?: 'LayoutItemNode' }
  & Pick<LayoutItemNode, 'id' | 'name' | 'x' | 'y' | 'z' | 'width' | 'height' | 'alpha' | 'animations' | 'scale'>
  & { widget?: Maybe<(
    { __typename?: 'WidgetLayoutItemNode' }
    & LayoutWidgetFragment
  )> }
);

export type LayoutItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LayoutItemQuery = (
  { __typename?: 'Query' }
  & { layoutItem?: Maybe<(
    { __typename?: 'LayoutItemNode' }
    & LayoutItemFragment
  )> }
);

export type CreateLayoutItemMutationVariables = Exact<{
  input: CreateLayoutItemInput;
}>;


export type CreateLayoutItemMutation = (
  { __typename?: 'Mutation' }
  & { createLayoutItem?: Maybe<(
    { __typename?: 'CreateLayoutItemPayload' }
    & { layoutItem?: Maybe<(
      { __typename?: 'LayoutItemNode' }
      & LayoutItemFragment
    )> }
  )> }
);

export type UpdateLayoutItemMutationVariables = Exact<{
  input: UpdateLayoutItemInput;
}>;


export type UpdateLayoutItemMutation = (
  { __typename?: 'Mutation' }
  & { updateLayoutItem?: Maybe<(
    { __typename?: 'UpdateLayoutItemPayload' }
    & { layoutItem?: Maybe<(
      { __typename?: 'LayoutItemNode' }
      & LayoutItemFragment
    )> }
  )> }
);

export type DeleteLayoutItemMutationVariables = Exact<{
  input: DeleteLayoutItemInput;
}>;


export type DeleteLayoutItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteLayoutItem?: Maybe<(
    { __typename?: 'DeleteLayoutItemPayload' }
    & Pick<DeleteLayoutItemPayload, 'ok'>
  )> }
);

export type UserDataFragment = (
  { __typename?: 'UserNode' }
  & Pick<UserNode, 'id' | 'firstName' | 'lastName' | 'isStaff' | 'email'>
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserNode' }
    & { customers: (
      { __typename?: 'CustomerNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'CustomerNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'CustomerNode' }
          & Pick<CustomerNode, 'id' | 'name'>
          & { backofficeVersion?: Maybe<(
            { __typename?: 'BackOfficeVersionNode' }
            & BackofficeSoftwareVersionFragment
          )> }
        )> }
      )>> }
    ) }
    & UserDataFragment
  )> }
);

export type GetTokenAuthMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GetTokenAuthMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'token' | 'refreshExpiresIn'>
  )> }
);

export type ForgottenPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgottenPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgottenPassword?: Maybe<(
    { __typename?: 'ForgottenPasswordPayload' }
    & Pick<ForgottenPasswordPayload, 'success'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['ID'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
  )> }
);

export type GetCustomerMediasQueryVariables = Exact<{
  customerId: Scalars['ID'];
  contentType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetCustomerMediasQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & { mediacontentSet: (
      { __typename?: 'MediaContentNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MediaContentNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MediaContentNode' }
          & Pick<MediaContentNode, 'id' | 'name' | 'contentType' | 'contentConverted' | 'webpageUrl' | 'signedUrl' | 'thumbnailUrl'>
        )> }
      )>> }
    ) }
  )> }
);

export type CreateMediaContentMutationVariables = Exact<{
  customerId: Scalars['ID'];
  contentType: MediaTypeEnum;
  name: Scalars['String'];
  webpageUrl?: Maybe<Scalars['String']>;
  fallbackRefreshPeriod?: Maybe<Scalars['Int']>;
  sha256?: Maybe<Scalars['String']>;
}>;


export type CreateMediaContentMutation = (
  { __typename?: 'Mutation' }
  & { createMediaContent?: Maybe<(
    { __typename?: 'CreateMediaContentPayload' }
    & Pick<CreateMediaContentPayload, 'presignedUrl'>
    & { media?: Maybe<(
      { __typename?: 'MediaContentNode' }
      & Pick<MediaContentNode, 'id' | 'name' | 'signedUrl' | 'thumbnailUrl' | 'contentType'>
    )> }
  )> }
);

export type ConvertMediaContentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConvertMediaContentMutation = (
  { __typename?: 'Mutation' }
  & { convertMediaContent?: Maybe<(
    { __typename?: 'ConvertVideoMutationPayload' }
    & Pick<ConvertVideoMutationPayload, 'ok'>
  )> }
);

export type PublicationDisplayFragment = (
  { __typename?: 'DisplayNode' }
  & Pick<DisplayNode, 'id' | 'orientation' | 'widthPx' | 'heightPx'>
  & { computer: (
    { __typename?: 'DisplayComputerNode' }
    & Pick<DisplayComputerNode, 'id' | 'name'>
  ) }
);

export type PublicationAllocationFragment = (
  { __typename?: 'PublicationAllocationNode' }
  & Pick<PublicationAllocationNode, 'id' | 'priority' | 'startDate' | 'endDate' | 'startTime' | 'endTime' | 'active'>
  & { display: (
    { __typename?: 'DisplayNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayNode' }
        & PublicationDisplayFragment
      )> }
    )>> }
  ), displayGroup: (
    { __typename?: 'DisplayGroupNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayGroupNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayGroupNode' }
        & Pick<DisplayGroupNode, 'id' | 'name'>
        & { displaycomputerSet: (
          { __typename?: 'DisplayComputerNodeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'DisplayComputerNodeEdge' }
            & { node?: Maybe<(
              { __typename?: 'DisplayComputerNode' }
              & Pick<DisplayComputerNode, 'id' | 'name'>
              & { displaySet: (
                { __typename?: 'DisplayNodeConnection' }
                & { edges: Array<Maybe<(
                  { __typename?: 'DisplayNodeEdge' }
                  & { node?: Maybe<(
                    { __typename?: 'DisplayNode' }
                    & PublicationDisplayFragment
                  )> }
                )>> }
              ) }
            )> }
          )>> }
        ) }
      )> }
    )>> }
  ) }
);

export type MediaContentFragmentFragment = (
  { __typename?: 'MediaContentNode' }
  & Pick<MediaContentNode, 'id' | 'contentType' | 'name' | 'signedUrl' | 'webpageUrl' | 'fallbackUrl' | 'fallbackRefreshPeriod' | 'thumbnailUrl' | 'contentConverted'>
);

export type ActivatedWidgetsFragment = (
  { __typename?: 'CustomerNode' }
  & { activatedWidgets: (
    { __typename?: 'WidgetNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WidgetNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'WidgetNode' }
        & Pick<WidgetNode, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type PublicationScreenFragment = (
  { __typename?: 'PublicationScreenNode' }
  & Pick<PublicationScreenNode, 'id' | 'backgroundColor' | 'duration' | 'thumbnailUrl' | 'putThumbnailUrl'>
  & { layoutitemSet: (
    { __typename?: 'LayoutItemNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'LayoutItemNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'LayoutItemNode' }
        & LayoutItemFragment
      )> }
    )>> }
  ) }
);

export type PublicationFragment = (
  { __typename?: 'PublicationNode' }
  & Pick<PublicationNode, 'id' | 'active' | 'name' | 'width' | 'height' | 'updatedAt'>
  & { publicationscreenSet: (
    { __typename?: 'PublicationScreenNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PublicationScreenNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'PublicationScreenNode' }
        & PublicationScreenFragment
      )> }
    )>> }
  ) }
);

export type GetCustomerPublicationsQueryVariables = Exact<{
  customerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
}>;


export type GetCustomerPublicationsQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'name'>
    & { publicationSet: (
      { __typename?: 'PublicationNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PublicationNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'PublicationNode' }
          & { publicationallocationSet: (
            { __typename?: 'PublicationAllocationNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'PublicationAllocationNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'PublicationAllocationNode' }
                & PublicationAllocationFragment
              )> }
            )>> }
          ) }
          & PublicationFragment
        )> }
      )>> }
    ) }
  )> }
);

export type GetPublicationAllocationsQueryVariables = Exact<{
  publicationId: Scalars['ID'];
}>;


export type GetPublicationAllocationsQuery = (
  { __typename?: 'Query' }
  & { publication?: Maybe<(
    { __typename?: 'PublicationNode' }
    & Pick<PublicationNode, 'id' | 'name'>
    & { publicationallocationSet: (
      { __typename?: 'PublicationAllocationNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PublicationAllocationNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'PublicationAllocationNode' }
          & PublicationAllocationFragment
        )> }
      )>> }
    ) }
  )> }
);

export type GetAllocationQueryVariables = Exact<{
  publicationAllocationId: Scalars['ID'];
}>;


export type GetAllocationQuery = (
  { __typename?: 'Query' }
  & { publicationAllocation?: Maybe<(
    { __typename?: 'PublicationAllocationNode' }
    & { publication: (
      { __typename?: 'PublicationNode' }
      & Pick<PublicationNode, 'id' | 'name' | 'width' | 'height'>
    ) }
    & PublicationAllocationFragment
  )> }
);

export type GetPublicationQueryVariables = Exact<{
  publicationId: Scalars['ID'];
}>;


export type GetPublicationQuery = (
  { __typename?: 'Query' }
  & { publication?: Maybe<(
    { __typename?: 'PublicationNode' }
    & PublicationFragment
  )> }
);

export type GetLayoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLayoutsQuery = (
  { __typename?: 'Query' }
  & { layouts?: Maybe<(
    { __typename?: 'LayoutNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'LayoutNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'LayoutNode' }
        & Pick<LayoutNode, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetActivatedWidgetsQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetActivatedWidgetsQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & ActivatedWidgetsFragment
  )> }
);

export type CreatePublicationScreenMutationVariables = Exact<{
  publicationId: Scalars['ID'];
  layoutId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
}>;


export type CreatePublicationScreenMutation = (
  { __typename?: 'Mutation' }
  & { createPublicationScreen?: Maybe<(
    { __typename?: 'CreatePublicationScreenPayload' }
    & { publicationScreen?: Maybe<(
      { __typename?: 'PublicationScreenNode' }
      & Pick<PublicationScreenNode, 'duration'>
      & { publication: (
        { __typename?: 'PublicationNode' }
        & Pick<PublicationNode, 'id'>
      ), layout?: Maybe<(
        { __typename?: 'LayoutNode' }
        & Pick<LayoutNode, 'id'>
      )> }
    )> }
  )> }
);

export type UpdatePublicationScreenMutationVariables = Exact<{
  id: Scalars['ID'];
  layoutId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
}>;


export type UpdatePublicationScreenMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicationScreen?: Maybe<(
    { __typename?: 'UpdatePublicationScreenPayload' }
    & { publicationScreen?: Maybe<(
      { __typename?: 'PublicationScreenNode' }
      & Pick<PublicationScreenNode, 'duration'>
    )> }
  )> }
);

export type DeletePublicationScreenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicationScreenMutation = (
  { __typename?: 'Mutation' }
  & { deletePublicationScreen?: Maybe<(
    { __typename?: 'DeletePublicationScreenPayload' }
    & Pick<DeletePublicationScreenPayload, 'ok'>
  )> }
);

export type CreatePublicationMutationVariables = Exact<{
  customerId: Scalars['ID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}>;


export type CreatePublicationMutation = (
  { __typename?: 'Mutation' }
  & { createPublication?: Maybe<(
    { __typename?: 'CreatePublicationPayload' }
    & { publication?: Maybe<(
      { __typename?: 'PublicationNode' }
      & Pick<PublicationNode, 'id' | 'name'>
    )> }
  )> }
);

export type UpdatePublicationMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}>;


export type UpdatePublicationMutation = (
  { __typename?: 'Mutation' }
  & { updatePublication?: Maybe<(
    { __typename?: 'UpdatePublicationPayload' }
    & { publication?: Maybe<(
      { __typename?: 'PublicationNode' }
      & Pick<PublicationNode, 'id' | 'name' | 'active' | 'width' | 'height'>
    )> }
  )> }
);

export type DeletePublicationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicationMutation = (
  { __typename?: 'Mutation' }
  & { deletePublication?: Maybe<(
    { __typename?: 'DeletePublicationPayload' }
    & Pick<DeletePublicationPayload, 'ok'>
  )> }
);

export type CreatePublicationAllocationMutationVariables = Exact<{
  publicationId: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
}>;


export type CreatePublicationAllocationMutation = (
  { __typename?: 'Mutation' }
  & { createPublicationAllocation?: Maybe<(
    { __typename?: 'CreatePublicationAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'id' | 'priority' | 'startDate' | 'endDate' | 'startTime' | 'endTime'>
      & { publication: (
        { __typename?: 'PublicationNode' }
        & Pick<PublicationNode, 'id'>
      ) }
    )> }
  )> }
);

export type UpdatePublicationAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePublicationAllocationMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicationAllocation?: Maybe<(
    { __typename?: 'UpdatePublicationAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'priority' | 'startDate' | 'endDate' | 'startTime' | 'endTime' | 'active'>
    )> }
  )> }
);

export type DeletePublicationAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicationAllocationMutation = (
  { __typename?: 'Mutation' }
  & { deletePublicationAllocation?: Maybe<(
    { __typename?: 'DeletePublicationAllocationPayload' }
    & Pick<DeletePublicationAllocationPayload, 'ok'>
  )> }
);

export type AddDisplayToAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  displayId: Scalars['ID'];
}>;


export type AddDisplayToAllocationMutation = (
  { __typename?: 'Mutation' }
  & { addDisplayToAllocation?: Maybe<(
    { __typename?: 'AddDisplayToAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'id'>
      & { display: (
        { __typename?: 'DisplayNodeConnection' }
        & Pick<DisplayNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type RemoveDisplayToAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  displayId: Scalars['ID'];
}>;


export type RemoveDisplayToAllocationMutation = (
  { __typename?: 'Mutation' }
  & { removeDisplayToAllocation?: Maybe<(
    { __typename?: 'RemoveDisplayToAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'id'>
      & { display: (
        { __typename?: 'DisplayNodeConnection' }
        & Pick<DisplayNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type AddDisplayGroupToAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
}>;


export type AddDisplayGroupToAllocationMutation = (
  { __typename?: 'Mutation' }
  & { addDisplayGroupToAllocation?: Maybe<(
    { __typename?: 'AddDisplayGroupToAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'id'>
      & { displayGroup: (
        { __typename?: 'DisplayGroupNodeConnection' }
        & Pick<DisplayGroupNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type RemoveDisplayGroupToAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
}>;


export type RemoveDisplayGroupToAllocationMutation = (
  { __typename?: 'Mutation' }
  & { removeDisplayGroupToAllocation?: Maybe<(
    { __typename?: 'RemoveDisplayGroupToAllocationPayload' }
    & { publicationAllocation?: Maybe<(
      { __typename?: 'PublicationAllocationNode' }
      & Pick<PublicationAllocationNode, 'id'>
      & { displayGroup: (
        { __typename?: 'DisplayGroupNodeConnection' }
        & Pick<DisplayGroupNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type CreatePublicationMediaMutationVariables = Exact<{
  publicationScreenId: Scalars['ID'];
  layoutItemId: Scalars['ID'];
  mediaId: Scalars['ID'];
  duration?: Maybe<Scalars['Int']>;
  objectFit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type CreatePublicationMediaMutation = (
  { __typename?: 'Mutation' }
  & { createPublicationMedia?: Maybe<(
    { __typename?: 'CreatePublicationMediaPayload' }
    & { publicationMedia?: Maybe<(
      { __typename?: 'PublicationMediaNode' }
      & Pick<PublicationMediaNode, 'id'>
    )> }
  )> }
);

export type UpdatePublicationMediaMutationVariables = Exact<{
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  objectFit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type UpdatePublicationMediaMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicationMedia?: Maybe<(
    { __typename?: 'UpdatePublicationMediaPayload' }
    & { publicationMedia?: Maybe<(
      { __typename?: 'PublicationMediaNode' }
      & Pick<PublicationMediaNode, 'id' | 'objectFit' | 'duration' | 'order' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type DeletePublicationMediaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicationMediaMutation = (
  { __typename?: 'Mutation' }
  & { deletePublicationMedia?: Maybe<(
    { __typename?: 'DeletePublicationMediaPayload' }
    & Pick<DeletePublicationMediaPayload, 'ok'>
  )> }
);

export type DisplayItemFragment = (
  { __typename?: 'DisplayNode' }
  & Pick<DisplayNode, 'id' | 'pitch' | 'widthPx' | 'heightPx'>
  & { displaycredential?: Maybe<(
    { __typename?: 'DisplayCredentialNode' }
    & Pick<DisplayCredentialNode, 'activated'>
  )> }
);

export type DisplayGroupFragment = (
  { __typename?: 'DisplayGroupNode' }
  & Pick<DisplayGroupNode, 'id' | 'name' | 'description'>
  & { displaycomputerSet: (
    { __typename?: 'DisplayComputerNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayComputerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayComputerNode' }
        & Pick<DisplayComputerNode, 'name' | 'description'>
      )> }
    )>> }
  ) }
);

export type DisplayComputerListItemFragment = (
  { __typename?: 'DisplayComputerNode' }
  & Pick<DisplayComputerNode, 'id' | 'name' | 'startTime' | 'endTime' | 'description' | 'ipAddress' | 'isMuted' | 'latitude' | 'longitude' | 'status' | 'isActive' | 'lastConnection' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
  & { groups: (
    { __typename?: 'DisplayGroupNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayGroupNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayGroupNode' }
        & { publicationallocationSet: (
          { __typename?: 'PublicationAllocationNodeConnection' }
          & Pick<PublicationAllocationNodeConnection, 'totalCount'>
        ) }
        & DisplayGroupFragment
      )> }
    )>> }
  ), displaySet: (
    { __typename?: 'DisplayNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayNode' }
        & DisplayItemFragment
      )> }
    )>> }
  ), displaycomputeradminfields?: Maybe<(
    { __typename?: 'DisplayComputerAdminFieldsNode' }
    & Pick<DisplayComputerAdminFieldsNode, 'id' | 'anydesk' | 'informations' | 'serialNumber' | 'designation'>
  )> }
);

export type GetDisplayGroupsQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetDisplayGroupsQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'name'>
    & { displaygroupSet: (
      { __typename?: 'DisplayGroupNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DisplayGroupNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'DisplayGroupNode' }
          & DisplayGroupFragment
        )> }
      )>> }
    ) }
  )> }
);

export type SearchDisplaysQueryVariables = Exact<{
  customerId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
}>;


export type SearchDisplaysQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & { displaygroupSet: (
      { __typename?: 'DisplayGroupNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DisplayGroupNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'DisplayGroupNode' }
          & Pick<DisplayGroupNode, 'id' | 'name'>
          & { publicationallocationSet: (
            { __typename?: 'PublicationAllocationNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'PublicationAllocationNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'PublicationAllocationNode' }
                & Pick<PublicationAllocationNode, 'id'>
              )> }
            )>> }
          ), displaycomputerSet: (
            { __typename?: 'DisplayComputerNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'DisplayComputerNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'DisplayComputerNode' }
                & Pick<DisplayComputerNode, 'id' | 'name'>
                & { displaySet: (
                  { __typename?: 'DisplayNodeConnection' }
                  & { edges: Array<Maybe<(
                    { __typename?: 'DisplayNodeEdge' }
                    & { node?: Maybe<(
                      { __typename?: 'DisplayNode' }
                      & DisplayItemFragment
                    )> }
                  )>> }
                ) }
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ), displaycomputerSet: (
      { __typename?: 'DisplayComputerNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DisplayComputerNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'DisplayComputerNode' }
          & Pick<DisplayComputerNode, 'id' | 'name'>
          & { displaySet: (
            { __typename?: 'DisplayNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'DisplayNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'DisplayNode' }
                & { publicationallocationSet: (
                  { __typename?: 'PublicationAllocationNodeConnection' }
                  & { edges: Array<Maybe<(
                    { __typename?: 'PublicationAllocationNodeEdge' }
                    & { node?: Maybe<(
                      { __typename?: 'PublicationAllocationNode' }
                      & Pick<PublicationAllocationNode, 'id'>
                    )> }
                  )>> }
                ) }
                & DisplayItemFragment
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type GetDisplayComputersQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetDisplayComputersQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & { displaycomputerSet: (
      { __typename?: 'DisplayComputerNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DisplayComputerNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'DisplayComputerNode' }
          & DisplayComputerListItemFragment
        )> }
      )>> }
    ) }
  )> }
);

export type GetDisplayComputerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDisplayComputerQuery = (
  { __typename?: 'Query' }
  & { displayComputer?: Maybe<(
    { __typename?: 'DisplayComputerNode' }
    & DisplayComputerListItemFragment
  )> }
);

export type GetGroupsQueryVariables = Exact<{
  customerId: Scalars['ID'];
  nameStartsWith?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & { displaygroupSet: (
      { __typename?: 'DisplayGroupNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DisplayGroupNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'DisplayGroupNode' }
          & { customer: (
            { __typename?: 'CustomerNode' }
            & Pick<CustomerNode, 'id'>
          ) }
          & DisplayGroupFragment
        )> }
      )>> }
    ) }
  )> }
);

export type CreateRegistrationPinMutationVariables = Exact<{
  displayId: Scalars['ID'];
}>;


export type CreateRegistrationPinMutation = (
  { __typename?: 'Mutation' }
  & { createRegistrationPin?: Maybe<(
    { __typename?: 'CreateRegistrationPinPayload' }
    & Pick<CreateRegistrationPinPayload, 'pinCode'>
  )> }
);

export type DeleteRegistrationMutationVariables = Exact<{
  displayId: Scalars['ID'];
}>;


export type DeleteRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { deleteRegistration?: Maybe<(
    { __typename?: 'DeleteRegistrationPayload' }
    & Pick<DeleteRegistrationPayload, 'ok'>
  )> }
);

export type CreateDisplayComputerMutationVariables = Exact<{
  customerId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateDisplayComputerMutation = (
  { __typename?: 'Mutation' }
  & { createDisplayComputer?: Maybe<(
    { __typename?: 'CreateDisplayComputerPayload' }
    & { displayComputer?: Maybe<(
      { __typename?: 'DisplayComputerNode' }
      & Pick<DisplayComputerNode, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateDisplayComputerMutationVariables = Exact<{
  input: UpdateDisplayComputerInput;
}>;


export type UpdateDisplayComputerMutation = (
  { __typename?: 'Mutation' }
  & { updateDisplayComputer?: Maybe<(
    { __typename?: 'UpdateDisplayComputerPayload' }
    & { displayComputer?: Maybe<(
      { __typename?: 'DisplayComputerNode' }
      & DisplayComputerListItemFragment
    )> }
  )> }
);

export type DeleteDisplayComputerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDisplayComputerMutation = (
  { __typename?: 'Mutation' }
  & { deleteDisplayComputer?: Maybe<(
    { __typename?: 'DeleteDisplayComputerPayload' }
    & Pick<DeleteDisplayComputerPayload, 'ok'>
  )> }
);

export type AddGroupToComputerMutationVariables = Exact<{
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type AddGroupToComputerMutation = (
  { __typename?: 'Mutation' }
  & { addGroupToComputer?: Maybe<(
    { __typename?: 'AddGroupToComputerPayload' }
    & { displayGroup?: Maybe<(
      { __typename?: 'DisplayComputerNode' }
      & { groups: (
        { __typename?: 'DisplayGroupNodeConnection' }
        & Pick<DisplayGroupNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type RemoveGroupToComputerMutationVariables = Exact<{
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type RemoveGroupToComputerMutation = (
  { __typename?: 'Mutation' }
  & { removeGroupToComputer?: Maybe<(
    { __typename?: 'RemoveGroupToComputerPayload' }
    & { displayGroup?: Maybe<(
      { __typename?: 'DisplayComputerNode' }
      & { groups: (
        { __typename?: 'DisplayGroupNodeConnection' }
        & Pick<DisplayGroupNodeConnection, 'edgeCount'>
      ) }
    )> }
  )> }
);

export type CreateDisplayMutationVariables = Exact<{
  computerId: Scalars['ID'];
  height: Scalars['Int'];
  width: Scalars['Int'];
}>;


export type CreateDisplayMutation = (
  { __typename?: 'Mutation' }
  & { createDisplay?: Maybe<(
    { __typename?: 'CreateDisplayPayload' }
    & { display?: Maybe<(
      { __typename?: 'DisplayNode' }
      & DisplayItemFragment
    )> }
  )> }
);

export type UpdateDisplayMutationVariables = Exact<{
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}>;


export type UpdateDisplayMutation = (
  { __typename?: 'Mutation' }
  & { updateDisplay?: Maybe<(
    { __typename?: 'UpdateDisplayPayload' }
    & { display?: Maybe<(
      { __typename?: 'DisplayNode' }
      & DisplayItemFragment
    )> }
  )> }
);

export type DeleteDisplayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDisplayMutation = (
  { __typename?: 'Mutation' }
  & { deleteDisplay?: Maybe<(
    { __typename?: 'DeleteDisplayPayload' }
    & Pick<DeleteDisplayPayload, 'ok'>
  )> }
);

export type UpdateAdminFieldsMutationVariables = Exact<{
  id: Scalars['ID'];
  anydesk?: Maybe<Scalars['String']>;
  informations?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
}>;


export type UpdateAdminFieldsMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminFields?: Maybe<(
    { __typename?: 'UpdateAdminFieldsPayload' }
    & { adminFields?: Maybe<(
      { __typename?: 'DisplayComputerAdminFieldsNode' }
      & Pick<DisplayComputerAdminFieldsNode, 'anydesk' | 'informations' | 'serialNumber' | 'designation'>
    )> }
  )> }
);

export type BackofficeSoftwareVersionFragment = (
  { __typename?: 'BackOfficeVersionNode' }
  & Pick<BackOfficeVersionNode, 'id' | 'version' | 'date' | 'number' | 'dockerImageUrl' | 'dockerImageTag' | 'releaseNote'>
);

export type PlayerSoftwareVersionFragment = (
  { __typename?: 'PlayerVersionNode' }
  & Pick<PlayerVersionNode, 'id' | 'version' | 'date' | 'number' | 'dockerImageUrl' | 'dockerImageTag' | 'releaseNote'>
);

export type WidgetTypeFragment = (
  { __typename?: 'WidgetNode' }
  & Pick<WidgetNode, 'id' | 'name' | 'isActive' | 'config'>
);

export type CustomerSoftwareVersionFragment = (
  { __typename?: 'CustomerNode' }
  & Pick<CustomerNode, 'id' | 'name'>
  & { backofficeVersion?: Maybe<(
    { __typename?: 'BackOfficeVersionNode' }
    & BackofficeSoftwareVersionFragment
  )>, activatedWidgets: (
    { __typename?: 'WidgetNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WidgetNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'WidgetNode' }
        & WidgetTypeFragment
      )> }
    )>> }
  ) }
);

export type ComputerSoftwareVersionFragment = (
  { __typename?: 'DisplayComputerNode' }
  & Pick<DisplayComputerNode, 'id' | 'name'>
  & { customer: (
    { __typename?: 'CustomerNode' }
    & CustomerSoftwareVersionFragment
  ), playerVersion?: Maybe<(
    { __typename?: 'PlayerVersionNode' }
    & PlayerSoftwareVersionFragment
  )> }
);

export type GetCustomersSoftwareVersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersSoftwareVersionsQuery = (
  { __typename?: 'Query' }
  & { customers?: Maybe<(
    { __typename?: 'CustomerNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CustomerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CustomerNode' }
        & CustomerSoftwareVersionFragment
      )> }
    )>> }
  )> }
);

export type GetCustomerVersionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerVersionQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'id'>
    & { backofficeVersion?: Maybe<(
      { __typename?: 'BackOfficeVersionNode' }
      & BackofficeSoftwareVersionFragment
    )> }
  )> }
);

export type GetDisplayComputersSoftwareVersionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetDisplayComputersSoftwareVersionsQuery = (
  { __typename?: 'Query' }
  & { displayComputers?: Maybe<(
    { __typename?: 'DisplayComputerNodeConnection' }
    & Pick<DisplayComputerNodeConnection, 'edgeCount'>
    & { edges: Array<Maybe<(
      { __typename?: 'DisplayComputerNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'DisplayComputerNode' }
        & ComputerSoftwareVersionFragment
      )> }
    )>> }
  )> }
);

export type GetWidgetTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWidgetTypesQuery = (
  { __typename?: 'Query' }
  & { allWidgets?: Maybe<(
    { __typename?: 'WidgetNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WidgetNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'WidgetNode' }
        & WidgetTypeFragment
      )> }
    )>> }
  )> }
);

export type GetSoftwareVersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSoftwareVersionsQuery = (
  { __typename?: 'Query' }
  & { backofficeVersionList?: Maybe<(
    { __typename?: 'BackOfficeVersionNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'BackOfficeVersionNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'BackOfficeVersionNode' }
        & BackofficeSoftwareVersionFragment
      )> }
    )>> }
  )>, playerVersionList?: Maybe<(
    { __typename?: 'PlayerVersionNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PlayerVersionNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'PlayerVersionNode' }
        & PlayerSoftwareVersionFragment
      )> }
    )>> }
  )> }
);

export type ActivateWidgetOnCustomerMutationVariables = Exact<{
  input: ActivateWidgetOnCustomerInput;
}>;


export type ActivateWidgetOnCustomerMutation = (
  { __typename?: 'Mutation' }
  & { activateWidgetOnCustomer?: Maybe<(
    { __typename?: 'ActivateWidgetOnCustomerPayload' }
    & { customers?: Maybe<(
      { __typename?: 'CustomerConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'CustomerEdge' }
        & { node?: Maybe<(
          { __typename?: 'CustomerNode' }
          & CustomerSoftwareVersionFragment
        )> }
      )>> }
    )> }
  )> }
);

export type AssignBackOfficeVersionToCustomerMutationVariables = Exact<{
  customerId: Scalars['ID'];
  backofficeVersionId: Scalars['ID'];
}>;


export type AssignBackOfficeVersionToCustomerMutation = (
  { __typename?: 'Mutation' }
  & { assignBackofficeVersionToCustomer?: Maybe<(
    { __typename?: 'AssignBackOfficeVersionToCustomerPayload' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & CustomerSoftwareVersionFragment
    )> }
  )> }
);

export type AssignPlayerVersionToComputerMutationVariables = Exact<{
  computerId: Scalars['ID'];
  playerVersionId: Scalars['ID'];
}>;


export type AssignPlayerVersionToComputerMutation = (
  { __typename?: 'Mutation' }
  & { assignPlayerVersionToComputer?: Maybe<(
    { __typename?: 'AssignPlayerVersionToComputerPayload' }
    & { computer?: Maybe<(
      { __typename?: 'DisplayComputerNode' }
      & ComputerSoftwareVersionFragment
    )> }
  )> }
);

export type GetCustomerUsersQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetCustomerUsersQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & { userSet: (
      { __typename?: 'UserNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'UserNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'id' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'isStaff' | 'profile'>
        )> }
      )>> }
    ) }
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'UserNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'UserNode' }
        & Pick<UserNode, 'id' | 'firstName' | 'lastName' | 'email' | 'profile'>
      )> }
    )>> }
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
  customerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profile: UserProfile;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser?: Maybe<(
    { __typename?: 'RegisterUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'email'>
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'firstName' | 'lastName' | 'profile'>
    )> }
  )> }
);

export type AddUserMutationVariables = Exact<{
  id: Scalars['ID'];
  customerId: Scalars['ID'];
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'AddUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'email'>
      & { customers: (
        { __typename?: 'CustomerNodeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'CustomerNodeEdge' }
          & { node?: Maybe<(
            { __typename?: 'CustomerNode' }
            & Pick<CustomerNode, 'id' | 'name'>
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
  customerId: Scalars['ID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUser?: Maybe<(
    { __typename?: 'RemoveUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id' | 'email'>
      & { customers: (
        { __typename?: 'CustomerNodeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'CustomerNodeEdge' }
          & { node?: Maybe<(
            { __typename?: 'CustomerNode' }
            & Pick<CustomerNode, 'id' | 'name'>
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type WidgetMediaFragmentFragment = (
  { __typename?: 'WidgetMediaNode' }
  & Pick<WidgetMediaNode, 'id' | 'data'>
  & { mediaContent: (
    { __typename?: 'MediaContentNode' }
    & MediaContentFragmentFragment
  ) }
);

export type CreateWidgetMediaMutationVariables = Exact<{
  input: CreateWidgetMediaInput;
}>;


export type CreateWidgetMediaMutation = (
  { __typename?: 'Mutation' }
  & { createWidgetMedia?: Maybe<(
    { __typename?: 'CreateWidgetMediaPayload' }
    & { widgetMedia?: Maybe<(
      { __typename?: 'WidgetMediaNode' }
      & WidgetMediaFragmentFragment
    )> }
  )> }
);

export type UpdateWidgetMediaMutationVariables = Exact<{
  input: UpdateWidgetMediaInput;
}>;


export type UpdateWidgetMediaMutation = (
  { __typename?: 'Mutation' }
  & { updateWidgetMedia?: Maybe<(
    { __typename?: 'UpdateWidgetMediaPayload' }
    & { widgetMedia?: Maybe<(
      { __typename?: 'WidgetMediaNode' }
      & WidgetMediaFragmentFragment
    )> }
  )> }
);

export type DeleteWidgetMediaMutationVariables = Exact<{
  input: DeleteWidgetMediaInput;
}>;


export type DeleteWidgetMediaMutation = (
  { __typename?: 'Mutation' }
  & { deleteWidgetMedia?: Maybe<(
    { __typename?: 'DeleteWidgetMediaPayload' }
    & Pick<DeleteWidgetMediaPayload, 'ok'>
  )> }
);

export const CustomerListItemFragmentDoc = gql`
    fragment CustomerListItem on CustomerNode {
  id
  name
  userSet {
    edgeCount
  }
  displaycomputerSet {
    edgeCount
    edges {
      node {
        status
      }
    }
  }
  address
}
    `;
export const DisplayComputerMonitoringFragmentDoc = gql`
    fragment DisplayComputerMonitoring on DisplayComputerNode {
  id
  name
  customer {
    name
  }
  status
  startTime
  endTime
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
  displaycomputeradminfields {
    anydesk
  }
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on UserNode {
  id
  firstName
  lastName
  isStaff
  email
}
    `;
export const PublicationDisplayFragmentDoc = gql`
    fragment PublicationDisplay on DisplayNode {
  id
  computer {
    id
    name
  }
  orientation
  widthPx
  heightPx
}
    `;
export const PublicationAllocationFragmentDoc = gql`
    fragment PublicationAllocation on PublicationAllocationNode {
  id
  priority
  startDate
  endDate
  startTime
  endTime
  active
  display {
    edges {
      node {
        ...PublicationDisplay
      }
    }
  }
  displayGroup {
    edges {
      node {
        id
        name
        displaycomputerSet {
          edges {
            node {
              id
              name
              displaySet {
                edges {
                  node {
                    ...PublicationDisplay
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${PublicationDisplayFragmentDoc}`;
export const ActivatedWidgetsFragmentDoc = gql`
    fragment ActivatedWidgets on CustomerNode {
  activatedWidgets {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const MediaContentFragmentFragmentDoc = gql`
    fragment MediaContentFragment on MediaContentNode {
  id
  contentType
  name
  signedUrl
  webpageUrl
  fallbackUrl
  fallbackRefreshPeriod
  thumbnailUrl
  contentConverted
}
    `;
export const WidgetMediaFragmentFragmentDoc = gql`
    fragment WidgetMediaFragment on WidgetMediaNode {
  id
  mediaContent {
    ...MediaContentFragment
  }
  data
}
    ${MediaContentFragmentFragmentDoc}`;
export const LayoutWidgetFragmentDoc = gql`
    fragment LayoutWidget on WidgetLayoutItemNode {
  id
  widgetType {
    id
    name
  }
  data
  widgetmediaSet {
    edges {
      node {
        ...WidgetMediaFragment
      }
    }
  }
}
    ${WidgetMediaFragmentFragmentDoc}`;
export const LayoutItemFragmentDoc = gql`
    fragment LayoutItem on LayoutItemNode {
  id
  name
  x
  y
  z
  width
  height
  alpha
  widget {
    ...LayoutWidget
  }
  animations
  scale
}
    ${LayoutWidgetFragmentDoc}`;
export const PublicationScreenFragmentDoc = gql`
    fragment PublicationScreen on PublicationScreenNode {
  id
  backgroundColor
  duration
  thumbnailUrl
  putThumbnailUrl
  layoutitemSet {
    edges {
      node {
        ...LayoutItem
      }
    }
  }
}
    ${LayoutItemFragmentDoc}`;
export const PublicationFragmentDoc = gql`
    fragment Publication on PublicationNode {
  id
  active
  name
  width
  height
  updatedAt
  publicationscreenSet {
    edges {
      node {
        ...PublicationScreen
      }
    }
  }
}
    ${PublicationScreenFragmentDoc}`;
export const DisplayGroupFragmentDoc = gql`
    fragment DisplayGroup on DisplayGroupNode {
  id
  name
  description
  displaycomputerSet {
    edges {
      node {
        name
        description
      }
    }
  }
}
    `;
export const DisplayItemFragmentDoc = gql`
    fragment DisplayItem on DisplayNode {
  id
  pitch
  displaycredential {
    activated
  }
  widthPx
  heightPx
}
    `;
export const DisplayComputerListItemFragmentDoc = gql`
    fragment DisplayComputerListItem on DisplayComputerNode {
  id
  name
  startTime
  endTime
  description
  ipAddress
  isMuted
  latitude
  longitude
  status
  isActive
  lastConnection
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
  groups {
    edges {
      node {
        ...DisplayGroup
        publicationallocationSet(active: true) {
          totalCount
        }
      }
    }
  }
  displaySet {
    edges {
      node {
        ...DisplayItem
      }
    }
  }
  displaycomputeradminfields {
    id
    anydesk
    informations
    serialNumber
    designation
  }
}
    ${DisplayGroupFragmentDoc}
${DisplayItemFragmentDoc}`;
export const BackofficeSoftwareVersionFragmentDoc = gql`
    fragment BackofficeSoftwareVersion on BackOfficeVersionNode {
  id
  version
  date
  number
  dockerImageUrl
  dockerImageTag
  releaseNote
}
    `;
export const WidgetTypeFragmentDoc = gql`
    fragment WidgetType on WidgetNode {
  id
  name
  isActive
  config
}
    `;
export const CustomerSoftwareVersionFragmentDoc = gql`
    fragment CustomerSoftwareVersion on CustomerNode {
  id
  name
  backofficeVersion {
    ...BackofficeSoftwareVersion
  }
  activatedWidgets {
    edges {
      node {
        ...WidgetType
      }
    }
  }
}
    ${BackofficeSoftwareVersionFragmentDoc}
${WidgetTypeFragmentDoc}`;
export const PlayerSoftwareVersionFragmentDoc = gql`
    fragment PlayerSoftwareVersion on PlayerVersionNode {
  id
  version
  date
  number
  dockerImageUrl
  dockerImageTag
  releaseNote
}
    `;
export const ComputerSoftwareVersionFragmentDoc = gql`
    fragment ComputerSoftwareVersion on DisplayComputerNode {
  id
  name
  customer {
    ...CustomerSoftwareVersion
  }
  playerVersion {
    ...PlayerSoftwareVersion
  }
}
    ${CustomerSoftwareVersionFragmentDoc}
${PlayerSoftwareVersionFragmentDoc}`;
export const GetCustomersDocument = gql`
    query GetCustomers {
  customers {
    edges {
      node {
        ...CustomerListItem
      }
    }
  }
}
    ${CustomerListItemFragmentDoc}`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = ApolloReactCommon.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: ID!) {
  customer(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($name: String!) {
  createCustomer(input: {name: $name}) {
    customer {
      id
      name
    }
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetDisplayComputersStatusDocument = gql`
    query GetDisplayComputersStatus($first: Int, $after: String) {
  displayComputers(first: $first, after: $after) {
    edges {
      node {
        ...DisplayComputerMonitoring
      }
    }
  }
}
    ${DisplayComputerMonitoringFragmentDoc}`;

/**
 * __useGetDisplayComputersStatusQuery__
 *
 * To run a query within a React component, call `useGetDisplayComputersStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayComputersStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayComputersStatusQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDisplayComputersStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisplayComputersStatusQuery, GetDisplayComputersStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayComputersStatusQuery, GetDisplayComputersStatusQueryVariables>(GetDisplayComputersStatusDocument, options);
      }
export function useGetDisplayComputersStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayComputersStatusQuery, GetDisplayComputersStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayComputersStatusQuery, GetDisplayComputersStatusQueryVariables>(GetDisplayComputersStatusDocument, options);
        }
export type GetDisplayComputersStatusQueryHookResult = ReturnType<typeof useGetDisplayComputersStatusQuery>;
export type GetDisplayComputersStatusLazyQueryHookResult = ReturnType<typeof useGetDisplayComputersStatusLazyQuery>;
export type GetDisplayComputersStatusQueryResult = ApolloReactCommon.QueryResult<GetDisplayComputersStatusQuery, GetDisplayComputersStatusQueryVariables>;
export const RequestLokiDocument = gql`
    query RequestLoki($query: String!, $start: DateTime, $end: DateTime) {
  requestLoki(query: $query, start: $start, end: $end)
}
    `;

/**
 * __useRequestLokiQuery__
 *
 * To run a query within a React component, call `useRequestLokiQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestLokiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestLokiQuery({
 *   variables: {
 *      query: // value for 'query'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useRequestLokiQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RequestLokiQuery, RequestLokiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RequestLokiQuery, RequestLokiQueryVariables>(RequestLokiDocument, options);
      }
export function useRequestLokiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequestLokiQuery, RequestLokiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RequestLokiQuery, RequestLokiQueryVariables>(RequestLokiDocument, options);
        }
export type RequestLokiQueryHookResult = ReturnType<typeof useRequestLokiQuery>;
export type RequestLokiLazyQueryHookResult = ReturnType<typeof useRequestLokiLazyQuery>;
export type RequestLokiQueryResult = ApolloReactCommon.QueryResult<RequestLokiQuery, RequestLokiQueryVariables>;
export const LayoutItemDocument = gql`
    query LayoutItem($id: ID!) {
  layoutItem(id: $id) {
    ...LayoutItem
  }
}
    ${LayoutItemFragmentDoc}`;

/**
 * __useLayoutItemQuery__
 *
 * To run a query within a React component, call `useLayoutItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLayoutItemQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LayoutItemQuery, LayoutItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LayoutItemQuery, LayoutItemQueryVariables>(LayoutItemDocument, options);
      }
export function useLayoutItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LayoutItemQuery, LayoutItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LayoutItemQuery, LayoutItemQueryVariables>(LayoutItemDocument, options);
        }
export type LayoutItemQueryHookResult = ReturnType<typeof useLayoutItemQuery>;
export type LayoutItemLazyQueryHookResult = ReturnType<typeof useLayoutItemLazyQuery>;
export type LayoutItemQueryResult = ApolloReactCommon.QueryResult<LayoutItemQuery, LayoutItemQueryVariables>;
export const CreateLayoutItemDocument = gql`
    mutation CreateLayoutItem($input: CreateLayoutItemInput!) {
  createLayoutItem(input: $input) {
    layoutItem {
      ...LayoutItem
    }
  }
}
    ${LayoutItemFragmentDoc}`;
export type CreateLayoutItemMutationFn = ApolloReactCommon.MutationFunction<CreateLayoutItemMutation, CreateLayoutItemMutationVariables>;

/**
 * __useCreateLayoutItemMutation__
 *
 * To run a mutation, you first call `useCreateLayoutItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayoutItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayoutItemMutation, { data, loading, error }] = useCreateLayoutItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLayoutItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLayoutItemMutation, CreateLayoutItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateLayoutItemMutation, CreateLayoutItemMutationVariables>(CreateLayoutItemDocument, options);
      }
export type CreateLayoutItemMutationHookResult = ReturnType<typeof useCreateLayoutItemMutation>;
export type CreateLayoutItemMutationResult = ApolloReactCommon.MutationResult<CreateLayoutItemMutation>;
export type CreateLayoutItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLayoutItemMutation, CreateLayoutItemMutationVariables>;
export const UpdateLayoutItemDocument = gql`
    mutation UpdateLayoutItem($input: UpdateLayoutItemInput!) {
  updateLayoutItem(input: $input) {
    layoutItem {
      ...LayoutItem
    }
  }
}
    ${LayoutItemFragmentDoc}`;
export type UpdateLayoutItemMutationFn = ApolloReactCommon.MutationFunction<UpdateLayoutItemMutation, UpdateLayoutItemMutationVariables>;

/**
 * __useUpdateLayoutItemMutation__
 *
 * To run a mutation, you first call `useUpdateLayoutItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayoutItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayoutItemMutation, { data, loading, error }] = useUpdateLayoutItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLayoutItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLayoutItemMutation, UpdateLayoutItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateLayoutItemMutation, UpdateLayoutItemMutationVariables>(UpdateLayoutItemDocument, options);
      }
export type UpdateLayoutItemMutationHookResult = ReturnType<typeof useUpdateLayoutItemMutation>;
export type UpdateLayoutItemMutationResult = ApolloReactCommon.MutationResult<UpdateLayoutItemMutation>;
export type UpdateLayoutItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLayoutItemMutation, UpdateLayoutItemMutationVariables>;
export const DeleteLayoutItemDocument = gql`
    mutation DeleteLayoutItem($input: DeleteLayoutItemInput!) {
  deleteLayoutItem(input: $input) {
    ok
  }
}
    `;
export type DeleteLayoutItemMutationFn = ApolloReactCommon.MutationFunction<DeleteLayoutItemMutation, DeleteLayoutItemMutationVariables>;

/**
 * __useDeleteLayoutItemMutation__
 *
 * To run a mutation, you first call `useDeleteLayoutItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayoutItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayoutItemMutation, { data, loading, error }] = useDeleteLayoutItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLayoutItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLayoutItemMutation, DeleteLayoutItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteLayoutItemMutation, DeleteLayoutItemMutationVariables>(DeleteLayoutItemDocument, options);
      }
export type DeleteLayoutItemMutationHookResult = ReturnType<typeof useDeleteLayoutItemMutation>;
export type DeleteLayoutItemMutationResult = ApolloReactCommon.MutationResult<DeleteLayoutItemMutation>;
export type DeleteLayoutItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLayoutItemMutation, DeleteLayoutItemMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...UserData
    customers {
      edges {
        node {
          id
          name
          backofficeVersion {
            ...BackofficeSoftwareVersion
          }
        }
      }
    }
  }
}
    ${UserDataFragmentDoc}
${BackofficeSoftwareVersionFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = ApolloReactCommon.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetTokenAuthDocument = gql`
    mutation getTokenAuth($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    token
    refreshExpiresIn
  }
}
    `;
export type GetTokenAuthMutationFn = ApolloReactCommon.MutationFunction<GetTokenAuthMutation, GetTokenAuthMutationVariables>;

/**
 * __useGetTokenAuthMutation__
 *
 * To run a mutation, you first call `useGetTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTokenAuthMutation, { data, loading, error }] = useGetTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetTokenAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetTokenAuthMutation, GetTokenAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GetTokenAuthMutation, GetTokenAuthMutationVariables>(GetTokenAuthDocument, options);
      }
export type GetTokenAuthMutationHookResult = ReturnType<typeof useGetTokenAuthMutation>;
export type GetTokenAuthMutationResult = ApolloReactCommon.MutationResult<GetTokenAuthMutation>;
export type GetTokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<GetTokenAuthMutation, GetTokenAuthMutationVariables>;
export const ForgottenPasswordDocument = gql`
    mutation ForgottenPassword($email: String!) {
  forgottenPassword(input: {email: $email}) {
    success
  }
}
    `;
export type ForgottenPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;

/**
 * __useForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordMutation, { data, loading, error }] = useForgottenPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgottenPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>(ForgottenPasswordDocument, options);
      }
export type ForgottenPasswordMutationHookResult = ReturnType<typeof useForgottenPasswordMutation>;
export type ForgottenPasswordMutationResult = ApolloReactCommon.MutationResult<ForgottenPasswordMutation>;
export type ForgottenPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($userId: ID!, $token: String!, $newPassword: String!) {
  resetPassword(
    input: {userId: $userId, token: $token, newPassword: $newPassword}
  ) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetCustomerMediasDocument = gql`
    query GetCustomerMedias($customerId: ID!, $contentType: String, $name: String, $first: Int) {
  customer(id: $customerId) {
    mediacontentSet(contentType: $contentType, name_Icontains: $name, first: $first) {
      edges {
        node {
          id
          name
          contentType
          contentConverted
          webpageUrl
          signedUrl
          thumbnailUrl
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerMediasQuery__
 *
 * To run a query within a React component, call `useGetCustomerMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerMediasQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      contentType: // value for 'contentType'
 *      name: // value for 'name'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCustomerMediasQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerMediasQuery, GetCustomerMediasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomerMediasQuery, GetCustomerMediasQueryVariables>(GetCustomerMediasDocument, options);
      }
export function useGetCustomerMediasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerMediasQuery, GetCustomerMediasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomerMediasQuery, GetCustomerMediasQueryVariables>(GetCustomerMediasDocument, options);
        }
export type GetCustomerMediasQueryHookResult = ReturnType<typeof useGetCustomerMediasQuery>;
export type GetCustomerMediasLazyQueryHookResult = ReturnType<typeof useGetCustomerMediasLazyQuery>;
export type GetCustomerMediasQueryResult = ApolloReactCommon.QueryResult<GetCustomerMediasQuery, GetCustomerMediasQueryVariables>;
export const CreateMediaContentDocument = gql`
    mutation CreateMediaContent($customerId: ID!, $contentType: MediaTypeEnum!, $name: String!, $webpageUrl: String, $fallbackRefreshPeriod: Int, $sha256: String) {
  createMediaContent(
    input: {customerId: $customerId, contentType: $contentType, name: $name, webpageUrl: $webpageUrl, fallbackRefreshPeriod: $fallbackRefreshPeriod, sha256: $sha256}
  ) {
    media {
      id
      name
      signedUrl
      thumbnailUrl
      contentType
    }
    presignedUrl
  }
}
    `;
export type CreateMediaContentMutationFn = ApolloReactCommon.MutationFunction<CreateMediaContentMutation, CreateMediaContentMutationVariables>;

/**
 * __useCreateMediaContentMutation__
 *
 * To run a mutation, you first call `useCreateMediaContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaContentMutation, { data, loading, error }] = useCreateMediaContentMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      contentType: // value for 'contentType'
 *      name: // value for 'name'
 *      webpageUrl: // value for 'webpageUrl'
 *      fallbackRefreshPeriod: // value for 'fallbackRefreshPeriod'
 *      sha256: // value for 'sha256'
 *   },
 * });
 */
export function useCreateMediaContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMediaContentMutation, CreateMediaContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMediaContentMutation, CreateMediaContentMutationVariables>(CreateMediaContentDocument, options);
      }
export type CreateMediaContentMutationHookResult = ReturnType<typeof useCreateMediaContentMutation>;
export type CreateMediaContentMutationResult = ApolloReactCommon.MutationResult<CreateMediaContentMutation>;
export type CreateMediaContentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMediaContentMutation, CreateMediaContentMutationVariables>;
export const ConvertMediaContentDocument = gql`
    mutation ConvertMediaContent($id: ID!) {
  convertMediaContent(input: {id: $id}) {
    ok
  }
}
    `;
export type ConvertMediaContentMutationFn = ApolloReactCommon.MutationFunction<ConvertMediaContentMutation, ConvertMediaContentMutationVariables>;

/**
 * __useConvertMediaContentMutation__
 *
 * To run a mutation, you first call `useConvertMediaContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertMediaContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertMediaContentMutation, { data, loading, error }] = useConvertMediaContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConvertMediaContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConvertMediaContentMutation, ConvertMediaContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConvertMediaContentMutation, ConvertMediaContentMutationVariables>(ConvertMediaContentDocument, options);
      }
export type ConvertMediaContentMutationHookResult = ReturnType<typeof useConvertMediaContentMutation>;
export type ConvertMediaContentMutationResult = ApolloReactCommon.MutationResult<ConvertMediaContentMutation>;
export type ConvertMediaContentMutationOptions = ApolloReactCommon.BaseMutationOptions<ConvertMediaContentMutation, ConvertMediaContentMutationVariables>;
export const GetCustomerPublicationsDocument = gql`
    query GetCustomerPublications($customerId: ID!, $first: Int) {
  customer(id: $customerId) {
    name
    publicationSet(first: $first) {
      edges {
        node {
          ...Publication
          publicationallocationSet {
            edges {
              node {
                ...PublicationAllocation
              }
            }
          }
        }
      }
    }
  }
}
    ${PublicationFragmentDoc}
${PublicationAllocationFragmentDoc}`;

/**
 * __useGetCustomerPublicationsQuery__
 *
 * To run a query within a React component, call `useGetCustomerPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPublicationsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCustomerPublicationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerPublicationsQuery, GetCustomerPublicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomerPublicationsQuery, GetCustomerPublicationsQueryVariables>(GetCustomerPublicationsDocument, options);
      }
export function useGetCustomerPublicationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerPublicationsQuery, GetCustomerPublicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomerPublicationsQuery, GetCustomerPublicationsQueryVariables>(GetCustomerPublicationsDocument, options);
        }
export type GetCustomerPublicationsQueryHookResult = ReturnType<typeof useGetCustomerPublicationsQuery>;
export type GetCustomerPublicationsLazyQueryHookResult = ReturnType<typeof useGetCustomerPublicationsLazyQuery>;
export type GetCustomerPublicationsQueryResult = ApolloReactCommon.QueryResult<GetCustomerPublicationsQuery, GetCustomerPublicationsQueryVariables>;
export const GetPublicationAllocationsDocument = gql`
    query GetPublicationAllocations($publicationId: ID!) {
  publication(id: $publicationId) {
    id
    name
    publicationallocationSet {
      edges {
        node {
          ...PublicationAllocation
        }
      }
    }
  }
}
    ${PublicationAllocationFragmentDoc}`;

/**
 * __useGetPublicationAllocationsQuery__
 *
 * To run a query within a React component, call `useGetPublicationAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationAllocationsQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useGetPublicationAllocationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPublicationAllocationsQuery, GetPublicationAllocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPublicationAllocationsQuery, GetPublicationAllocationsQueryVariables>(GetPublicationAllocationsDocument, options);
      }
export function useGetPublicationAllocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicationAllocationsQuery, GetPublicationAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPublicationAllocationsQuery, GetPublicationAllocationsQueryVariables>(GetPublicationAllocationsDocument, options);
        }
export type GetPublicationAllocationsQueryHookResult = ReturnType<typeof useGetPublicationAllocationsQuery>;
export type GetPublicationAllocationsLazyQueryHookResult = ReturnType<typeof useGetPublicationAllocationsLazyQuery>;
export type GetPublicationAllocationsQueryResult = ApolloReactCommon.QueryResult<GetPublicationAllocationsQuery, GetPublicationAllocationsQueryVariables>;
export const GetAllocationDocument = gql`
    query GetAllocation($publicationAllocationId: ID!) {
  publicationAllocation(id: $publicationAllocationId) {
    ...PublicationAllocation
    publication {
      id
      name
      width
      height
    }
  }
}
    ${PublicationAllocationFragmentDoc}`;

/**
 * __useGetAllocationQuery__
 *
 * To run a query within a React component, call `useGetAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllocationQuery({
 *   variables: {
 *      publicationAllocationId: // value for 'publicationAllocationId'
 *   },
 * });
 */
export function useGetAllocationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllocationQuery, GetAllocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllocationQuery, GetAllocationQueryVariables>(GetAllocationDocument, options);
      }
export function useGetAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllocationQuery, GetAllocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllocationQuery, GetAllocationQueryVariables>(GetAllocationDocument, options);
        }
export type GetAllocationQueryHookResult = ReturnType<typeof useGetAllocationQuery>;
export type GetAllocationLazyQueryHookResult = ReturnType<typeof useGetAllocationLazyQuery>;
export type GetAllocationQueryResult = ApolloReactCommon.QueryResult<GetAllocationQuery, GetAllocationQueryVariables>;
export const GetPublicationDocument = gql`
    query GetPublication($publicationId: ID!) {
  publication(id: $publicationId) {
    ...Publication
  }
}
    ${PublicationFragmentDoc}`;

/**
 * __useGetPublicationQuery__
 *
 * To run a query within a React component, call `useGetPublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useGetPublicationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPublicationQuery, GetPublicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPublicationQuery, GetPublicationQueryVariables>(GetPublicationDocument, options);
      }
export function useGetPublicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicationQuery, GetPublicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPublicationQuery, GetPublicationQueryVariables>(GetPublicationDocument, options);
        }
export type GetPublicationQueryHookResult = ReturnType<typeof useGetPublicationQuery>;
export type GetPublicationLazyQueryHookResult = ReturnType<typeof useGetPublicationLazyQuery>;
export type GetPublicationQueryResult = ApolloReactCommon.QueryResult<GetPublicationQuery, GetPublicationQueryVariables>;
export const GetLayoutsDocument = gql`
    query GetLayouts {
  layouts {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetLayoutsQuery__
 *
 * To run a query within a React component, call `useGetLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayoutsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLayoutsQuery, GetLayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLayoutsQuery, GetLayoutsQueryVariables>(GetLayoutsDocument, options);
      }
export function useGetLayoutsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLayoutsQuery, GetLayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLayoutsQuery, GetLayoutsQueryVariables>(GetLayoutsDocument, options);
        }
export type GetLayoutsQueryHookResult = ReturnType<typeof useGetLayoutsQuery>;
export type GetLayoutsLazyQueryHookResult = ReturnType<typeof useGetLayoutsLazyQuery>;
export type GetLayoutsQueryResult = ApolloReactCommon.QueryResult<GetLayoutsQuery, GetLayoutsQueryVariables>;
export const GetActivatedWidgetsDocument = gql`
    query GetActivatedWidgets($customerId: ID!) {
  customer(id: $customerId) {
    ...ActivatedWidgets
  }
}
    ${ActivatedWidgetsFragmentDoc}`;

/**
 * __useGetActivatedWidgetsQuery__
 *
 * To run a query within a React component, call `useGetActivatedWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivatedWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivatedWidgetsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetActivatedWidgetsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetActivatedWidgetsQuery, GetActivatedWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetActivatedWidgetsQuery, GetActivatedWidgetsQueryVariables>(GetActivatedWidgetsDocument, options);
      }
export function useGetActivatedWidgetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActivatedWidgetsQuery, GetActivatedWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetActivatedWidgetsQuery, GetActivatedWidgetsQueryVariables>(GetActivatedWidgetsDocument, options);
        }
export type GetActivatedWidgetsQueryHookResult = ReturnType<typeof useGetActivatedWidgetsQuery>;
export type GetActivatedWidgetsLazyQueryHookResult = ReturnType<typeof useGetActivatedWidgetsLazyQuery>;
export type GetActivatedWidgetsQueryResult = ApolloReactCommon.QueryResult<GetActivatedWidgetsQuery, GetActivatedWidgetsQueryVariables>;
export const CreatePublicationScreenDocument = gql`
    mutation CreatePublicationScreen($publicationId: ID!, $layoutId: ID, $duration: Int) {
  createPublicationScreen(
    input: {publicationId: $publicationId, layout: $layoutId, duration: $duration}
  ) {
    publicationScreen {
      publication {
        id
      }
      layout {
        id
      }
      duration
    }
  }
}
    `;
export type CreatePublicationScreenMutationFn = ApolloReactCommon.MutationFunction<CreatePublicationScreenMutation, CreatePublicationScreenMutationVariables>;

/**
 * __useCreatePublicationScreenMutation__
 *
 * To run a mutation, you first call `useCreatePublicationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationScreenMutation, { data, loading, error }] = useCreatePublicationScreenMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *      layoutId: // value for 'layoutId'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useCreatePublicationScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublicationScreenMutation, CreatePublicationScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePublicationScreenMutation, CreatePublicationScreenMutationVariables>(CreatePublicationScreenDocument, options);
      }
export type CreatePublicationScreenMutationHookResult = ReturnType<typeof useCreatePublicationScreenMutation>;
export type CreatePublicationScreenMutationResult = ApolloReactCommon.MutationResult<CreatePublicationScreenMutation>;
export type CreatePublicationScreenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublicationScreenMutation, CreatePublicationScreenMutationVariables>;
export const UpdatePublicationScreenDocument = gql`
    mutation UpdatePublicationScreen($id: ID!, $layoutId: ID, $duration: Int, $backgroundColor: String) {
  updatePublicationScreen(
    input: {id: $id, layoutId: $layoutId, duration: $duration, backgroundColor: $backgroundColor}
  ) {
    publicationScreen {
      duration
    }
  }
}
    `;
export type UpdatePublicationScreenMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicationScreenMutation, UpdatePublicationScreenMutationVariables>;

/**
 * __useUpdatePublicationScreenMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationScreenMutation, { data, loading, error }] = useUpdatePublicationScreenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      layoutId: // value for 'layoutId'
 *      duration: // value for 'duration'
 *      backgroundColor: // value for 'backgroundColor'
 *   },
 * });
 */
export function useUpdatePublicationScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicationScreenMutation, UpdatePublicationScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePublicationScreenMutation, UpdatePublicationScreenMutationVariables>(UpdatePublicationScreenDocument, options);
      }
export type UpdatePublicationScreenMutationHookResult = ReturnType<typeof useUpdatePublicationScreenMutation>;
export type UpdatePublicationScreenMutationResult = ApolloReactCommon.MutationResult<UpdatePublicationScreenMutation>;
export type UpdatePublicationScreenMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicationScreenMutation, UpdatePublicationScreenMutationVariables>;
export const DeletePublicationScreenDocument = gql`
    mutation DeletePublicationScreen($id: ID!) {
  deletePublicationScreen(input: {id: $id}) {
    ok
  }
}
    `;
export type DeletePublicationScreenMutationFn = ApolloReactCommon.MutationFunction<DeletePublicationScreenMutation, DeletePublicationScreenMutationVariables>;

/**
 * __useDeletePublicationScreenMutation__
 *
 * To run a mutation, you first call `useDeletePublicationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationScreenMutation, { data, loading, error }] = useDeletePublicationScreenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicationScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePublicationScreenMutation, DeletePublicationScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePublicationScreenMutation, DeletePublicationScreenMutationVariables>(DeletePublicationScreenDocument, options);
      }
export type DeletePublicationScreenMutationHookResult = ReturnType<typeof useDeletePublicationScreenMutation>;
export type DeletePublicationScreenMutationResult = ApolloReactCommon.MutationResult<DeletePublicationScreenMutation>;
export type DeletePublicationScreenMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePublicationScreenMutation, DeletePublicationScreenMutationVariables>;
export const CreatePublicationDocument = gql`
    mutation CreatePublication($customerId: ID!, $name: String!, $active: Boolean, $width: Int, $height: Int) {
  createPublication(
    input: {customerId: $customerId, name: $name, active: $active, width: $width, height: $height}
  ) {
    publication {
      id
      name
    }
  }
}
    `;
export type CreatePublicationMutationFn = ApolloReactCommon.MutationFunction<CreatePublicationMutation, CreatePublicationMutationVariables>;

/**
 * __useCreatePublicationMutation__
 *
 * To run a mutation, you first call `useCreatePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationMutation, { data, loading, error }] = useCreatePublicationMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useCreatePublicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublicationMutation, CreatePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePublicationMutation, CreatePublicationMutationVariables>(CreatePublicationDocument, options);
      }
export type CreatePublicationMutationHookResult = ReturnType<typeof useCreatePublicationMutation>;
export type CreatePublicationMutationResult = ApolloReactCommon.MutationResult<CreatePublicationMutation>;
export type CreatePublicationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublicationMutation, CreatePublicationMutationVariables>;
export const UpdatePublicationDocument = gql`
    mutation UpdatePublication($id: ID!, $name: String, $active: Boolean, $width: Int, $height: Int) {
  updatePublication(
    input: {id: $id, name: $name, active: $active, width: $width, height: $height}
  ) {
    publication {
      id
      name
      active
      width
      height
      width
    }
  }
}
    `;
export type UpdatePublicationMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicationMutation, UpdatePublicationMutationVariables>;

/**
 * __useUpdatePublicationMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationMutation, { data, loading, error }] = useUpdatePublicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useUpdatePublicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicationMutation, UpdatePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePublicationMutation, UpdatePublicationMutationVariables>(UpdatePublicationDocument, options);
      }
export type UpdatePublicationMutationHookResult = ReturnType<typeof useUpdatePublicationMutation>;
export type UpdatePublicationMutationResult = ApolloReactCommon.MutationResult<UpdatePublicationMutation>;
export type UpdatePublicationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicationMutation, UpdatePublicationMutationVariables>;
export const DeletePublicationDocument = gql`
    mutation DeletePublication($id: ID!) {
  deletePublication(input: {id: $id}) {
    ok
  }
}
    `;
export type DeletePublicationMutationFn = ApolloReactCommon.MutationFunction<DeletePublicationMutation, DeletePublicationMutationVariables>;

/**
 * __useDeletePublicationMutation__
 *
 * To run a mutation, you first call `useDeletePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationMutation, { data, loading, error }] = useDeletePublicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePublicationMutation, DeletePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePublicationMutation, DeletePublicationMutationVariables>(DeletePublicationDocument, options);
      }
export type DeletePublicationMutationHookResult = ReturnType<typeof useDeletePublicationMutation>;
export type DeletePublicationMutationResult = ApolloReactCommon.MutationResult<DeletePublicationMutation>;
export type DeletePublicationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePublicationMutation, DeletePublicationMutationVariables>;
export const CreatePublicationAllocationDocument = gql`
    mutation CreatePublicationAllocation($publicationId: ID!, $priority: PriorityEnum, $startDate: DateTime, $endDate: DateTime, $startTime: Time, $endTime: Time) {
  createPublicationAllocation(
    input: {publicationId: $publicationId, priority: $priority, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime}
  ) {
    publicationAllocation {
      id
      publication {
        id
      }
      priority
      startDate
      endDate
      startTime
      endTime
    }
  }
}
    `;
export type CreatePublicationAllocationMutationFn = ApolloReactCommon.MutationFunction<CreatePublicationAllocationMutation, CreatePublicationAllocationMutationVariables>;

/**
 * __useCreatePublicationAllocationMutation__
 *
 * To run a mutation, you first call `useCreatePublicationAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationAllocationMutation, { data, loading, error }] = useCreatePublicationAllocationMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *      priority: // value for 'priority'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useCreatePublicationAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublicationAllocationMutation, CreatePublicationAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePublicationAllocationMutation, CreatePublicationAllocationMutationVariables>(CreatePublicationAllocationDocument, options);
      }
export type CreatePublicationAllocationMutationHookResult = ReturnType<typeof useCreatePublicationAllocationMutation>;
export type CreatePublicationAllocationMutationResult = ApolloReactCommon.MutationResult<CreatePublicationAllocationMutation>;
export type CreatePublicationAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublicationAllocationMutation, CreatePublicationAllocationMutationVariables>;
export const UpdatePublicationAllocationDocument = gql`
    mutation UpdatePublicationAllocation($id: ID!, $priority: PriorityEnum, $startDate: DateTime, $endDate: DateTime, $startTime: Time, $endTime: Time, $active: Boolean) {
  updatePublicationAllocation(
    input: {id: $id, priority: $priority, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, active: $active}
  ) {
    publicationAllocation {
      priority
      startDate
      endDate
      startTime
      endTime
      active
    }
  }
}
    `;
export type UpdatePublicationAllocationMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicationAllocationMutation, UpdatePublicationAllocationMutationVariables>;

/**
 * __useUpdatePublicationAllocationMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationAllocationMutation, { data, loading, error }] = useUpdatePublicationAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priority: // value for 'priority'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdatePublicationAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicationAllocationMutation, UpdatePublicationAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePublicationAllocationMutation, UpdatePublicationAllocationMutationVariables>(UpdatePublicationAllocationDocument, options);
      }
export type UpdatePublicationAllocationMutationHookResult = ReturnType<typeof useUpdatePublicationAllocationMutation>;
export type UpdatePublicationAllocationMutationResult = ApolloReactCommon.MutationResult<UpdatePublicationAllocationMutation>;
export type UpdatePublicationAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicationAllocationMutation, UpdatePublicationAllocationMutationVariables>;
export const DeletePublicationAllocationDocument = gql`
    mutation DeletePublicationAllocation($id: ID!) {
  deletePublicationAllocation(input: {id: $id}) {
    ok
  }
}
    `;
export type DeletePublicationAllocationMutationFn = ApolloReactCommon.MutationFunction<DeletePublicationAllocationMutation, DeletePublicationAllocationMutationVariables>;

/**
 * __useDeletePublicationAllocationMutation__
 *
 * To run a mutation, you first call `useDeletePublicationAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationAllocationMutation, { data, loading, error }] = useDeletePublicationAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicationAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePublicationAllocationMutation, DeletePublicationAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePublicationAllocationMutation, DeletePublicationAllocationMutationVariables>(DeletePublicationAllocationDocument, options);
      }
export type DeletePublicationAllocationMutationHookResult = ReturnType<typeof useDeletePublicationAllocationMutation>;
export type DeletePublicationAllocationMutationResult = ApolloReactCommon.MutationResult<DeletePublicationAllocationMutation>;
export type DeletePublicationAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePublicationAllocationMutation, DeletePublicationAllocationMutationVariables>;
export const AddDisplayToAllocationDocument = gql`
    mutation AddDisplayToAllocation($id: ID!, $displayId: ID!) {
  addDisplayToAllocation(input: {id: $id, displayId: $displayId}) {
    publicationAllocation {
      id
      display {
        edgeCount
      }
    }
  }
}
    `;
export type AddDisplayToAllocationMutationFn = ApolloReactCommon.MutationFunction<AddDisplayToAllocationMutation, AddDisplayToAllocationMutationVariables>;

/**
 * __useAddDisplayToAllocationMutation__
 *
 * To run a mutation, you first call `useAddDisplayToAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDisplayToAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDisplayToAllocationMutation, { data, loading, error }] = useAddDisplayToAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayId: // value for 'displayId'
 *   },
 * });
 */
export function useAddDisplayToAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDisplayToAllocationMutation, AddDisplayToAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddDisplayToAllocationMutation, AddDisplayToAllocationMutationVariables>(AddDisplayToAllocationDocument, options);
      }
export type AddDisplayToAllocationMutationHookResult = ReturnType<typeof useAddDisplayToAllocationMutation>;
export type AddDisplayToAllocationMutationResult = ApolloReactCommon.MutationResult<AddDisplayToAllocationMutation>;
export type AddDisplayToAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDisplayToAllocationMutation, AddDisplayToAllocationMutationVariables>;
export const RemoveDisplayToAllocationDocument = gql`
    mutation RemoveDisplayToAllocation($id: ID!, $displayId: ID!) {
  removeDisplayToAllocation(input: {id: $id, displayId: $displayId}) {
    publicationAllocation {
      id
      display {
        edgeCount
      }
    }
  }
}
    `;
export type RemoveDisplayToAllocationMutationFn = ApolloReactCommon.MutationFunction<RemoveDisplayToAllocationMutation, RemoveDisplayToAllocationMutationVariables>;

/**
 * __useRemoveDisplayToAllocationMutation__
 *
 * To run a mutation, you first call `useRemoveDisplayToAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDisplayToAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDisplayToAllocationMutation, { data, loading, error }] = useRemoveDisplayToAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayId: // value for 'displayId'
 *   },
 * });
 */
export function useRemoveDisplayToAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDisplayToAllocationMutation, RemoveDisplayToAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveDisplayToAllocationMutation, RemoveDisplayToAllocationMutationVariables>(RemoveDisplayToAllocationDocument, options);
      }
export type RemoveDisplayToAllocationMutationHookResult = ReturnType<typeof useRemoveDisplayToAllocationMutation>;
export type RemoveDisplayToAllocationMutationResult = ApolloReactCommon.MutationResult<RemoveDisplayToAllocationMutation>;
export type RemoveDisplayToAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDisplayToAllocationMutation, RemoveDisplayToAllocationMutationVariables>;
export const AddDisplayGroupToAllocationDocument = gql`
    mutation AddDisplayGroupToAllocation($id: ID!, $displayGroupId: ID!) {
  addDisplayGroupToAllocation(input: {id: $id, displayGroupId: $displayGroupId}) {
    publicationAllocation {
      id
      displayGroup {
        edgeCount
      }
    }
  }
}
    `;
export type AddDisplayGroupToAllocationMutationFn = ApolloReactCommon.MutationFunction<AddDisplayGroupToAllocationMutation, AddDisplayGroupToAllocationMutationVariables>;

/**
 * __useAddDisplayGroupToAllocationMutation__
 *
 * To run a mutation, you first call `useAddDisplayGroupToAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDisplayGroupToAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDisplayGroupToAllocationMutation, { data, loading, error }] = useAddDisplayGroupToAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayGroupId: // value for 'displayGroupId'
 *   },
 * });
 */
export function useAddDisplayGroupToAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDisplayGroupToAllocationMutation, AddDisplayGroupToAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddDisplayGroupToAllocationMutation, AddDisplayGroupToAllocationMutationVariables>(AddDisplayGroupToAllocationDocument, options);
      }
export type AddDisplayGroupToAllocationMutationHookResult = ReturnType<typeof useAddDisplayGroupToAllocationMutation>;
export type AddDisplayGroupToAllocationMutationResult = ApolloReactCommon.MutationResult<AddDisplayGroupToAllocationMutation>;
export type AddDisplayGroupToAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDisplayGroupToAllocationMutation, AddDisplayGroupToAllocationMutationVariables>;
export const RemoveDisplayGroupToAllocationDocument = gql`
    mutation RemoveDisplayGroupToAllocation($id: ID!, $displayGroupId: ID!) {
  removeDisplayGroupToAllocation(
    input: {id: $id, displayGroupId: $displayGroupId}
  ) {
    publicationAllocation {
      id
      displayGroup {
        edgeCount
      }
    }
  }
}
    `;
export type RemoveDisplayGroupToAllocationMutationFn = ApolloReactCommon.MutationFunction<RemoveDisplayGroupToAllocationMutation, RemoveDisplayGroupToAllocationMutationVariables>;

/**
 * __useRemoveDisplayGroupToAllocationMutation__
 *
 * To run a mutation, you first call `useRemoveDisplayGroupToAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDisplayGroupToAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDisplayGroupToAllocationMutation, { data, loading, error }] = useRemoveDisplayGroupToAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayGroupId: // value for 'displayGroupId'
 *   },
 * });
 */
export function useRemoveDisplayGroupToAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDisplayGroupToAllocationMutation, RemoveDisplayGroupToAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveDisplayGroupToAllocationMutation, RemoveDisplayGroupToAllocationMutationVariables>(RemoveDisplayGroupToAllocationDocument, options);
      }
export type RemoveDisplayGroupToAllocationMutationHookResult = ReturnType<typeof useRemoveDisplayGroupToAllocationMutation>;
export type RemoveDisplayGroupToAllocationMutationResult = ApolloReactCommon.MutationResult<RemoveDisplayGroupToAllocationMutation>;
export type RemoveDisplayGroupToAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDisplayGroupToAllocationMutation, RemoveDisplayGroupToAllocationMutationVariables>;
export const CreatePublicationMediaDocument = gql`
    mutation CreatePublicationMedia($publicationScreenId: ID!, $layoutItemId: ID!, $mediaId: ID!, $duration: Int, $objectFit: String, $startDate: Date, $endDate: Date) {
  createPublicationMedia(
    input: {publicationScreenId: $publicationScreenId, layoutItemId: $layoutItemId, mediaId: $mediaId, duration: $duration, objectFit: $objectFit, startDate: $startDate, endDate: $endDate}
  ) {
    publicationMedia {
      id
    }
  }
}
    `;
export type CreatePublicationMediaMutationFn = ApolloReactCommon.MutationFunction<CreatePublicationMediaMutation, CreatePublicationMediaMutationVariables>;

/**
 * __useCreatePublicationMediaMutation__
 *
 * To run a mutation, you first call `useCreatePublicationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationMediaMutation, { data, loading, error }] = useCreatePublicationMediaMutation({
 *   variables: {
 *      publicationScreenId: // value for 'publicationScreenId'
 *      layoutItemId: // value for 'layoutItemId'
 *      mediaId: // value for 'mediaId'
 *      duration: // value for 'duration'
 *      objectFit: // value for 'objectFit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreatePublicationMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublicationMediaMutation, CreatePublicationMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePublicationMediaMutation, CreatePublicationMediaMutationVariables>(CreatePublicationMediaDocument, options);
      }
export type CreatePublicationMediaMutationHookResult = ReturnType<typeof useCreatePublicationMediaMutation>;
export type CreatePublicationMediaMutationResult = ApolloReactCommon.MutationResult<CreatePublicationMediaMutation>;
export type CreatePublicationMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublicationMediaMutation, CreatePublicationMediaMutationVariables>;
export const UpdatePublicationMediaDocument = gql`
    mutation UpdatePublicationMedia($id: ID!, $order: Int, $duration: Int, $objectFit: String, $startDate: Date, $endDate: Date) {
  updatePublicationMedia(
    input: {id: $id, order: $order, duration: $duration, objectFit: $objectFit, startDate: $startDate, endDate: $endDate}
  ) {
    publicationMedia {
      id
      objectFit
      duration
      order
      startDate
      endDate
    }
  }
}
    `;
export type UpdatePublicationMediaMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicationMediaMutation, UpdatePublicationMediaMutationVariables>;

/**
 * __useUpdatePublicationMediaMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationMediaMutation, { data, loading, error }] = useUpdatePublicationMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *      duration: // value for 'duration'
 *      objectFit: // value for 'objectFit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdatePublicationMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicationMediaMutation, UpdatePublicationMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePublicationMediaMutation, UpdatePublicationMediaMutationVariables>(UpdatePublicationMediaDocument, options);
      }
export type UpdatePublicationMediaMutationHookResult = ReturnType<typeof useUpdatePublicationMediaMutation>;
export type UpdatePublicationMediaMutationResult = ApolloReactCommon.MutationResult<UpdatePublicationMediaMutation>;
export type UpdatePublicationMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicationMediaMutation, UpdatePublicationMediaMutationVariables>;
export const DeletePublicationMediaDocument = gql`
    mutation DeletePublicationMedia($id: ID!) {
  deletePublicationMedia(input: {id: $id}) {
    ok
  }
}
    `;
export type DeletePublicationMediaMutationFn = ApolloReactCommon.MutationFunction<DeletePublicationMediaMutation, DeletePublicationMediaMutationVariables>;

/**
 * __useDeletePublicationMediaMutation__
 *
 * To run a mutation, you first call `useDeletePublicationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationMediaMutation, { data, loading, error }] = useDeletePublicationMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicationMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePublicationMediaMutation, DeletePublicationMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePublicationMediaMutation, DeletePublicationMediaMutationVariables>(DeletePublicationMediaDocument, options);
      }
export type DeletePublicationMediaMutationHookResult = ReturnType<typeof useDeletePublicationMediaMutation>;
export type DeletePublicationMediaMutationResult = ApolloReactCommon.MutationResult<DeletePublicationMediaMutation>;
export type DeletePublicationMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePublicationMediaMutation, DeletePublicationMediaMutationVariables>;
export const GetDisplayGroupsDocument = gql`
    query GetDisplayGroups($customerId: ID!) {
  customer(id: $customerId) {
    name
    displaygroupSet(isContainer: true) {
      edges {
        node {
          ...DisplayGroup
        }
      }
    }
  }
}
    ${DisplayGroupFragmentDoc}`;

/**
 * __useGetDisplayGroupsQuery__
 *
 * To run a query within a React component, call `useGetDisplayGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayGroupsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetDisplayGroupsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDisplayGroupsQuery, GetDisplayGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayGroupsQuery, GetDisplayGroupsQueryVariables>(GetDisplayGroupsDocument, options);
      }
export function useGetDisplayGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayGroupsQuery, GetDisplayGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayGroupsQuery, GetDisplayGroupsQueryVariables>(GetDisplayGroupsDocument, options);
        }
export type GetDisplayGroupsQueryHookResult = ReturnType<typeof useGetDisplayGroupsQuery>;
export type GetDisplayGroupsLazyQueryHookResult = ReturnType<typeof useGetDisplayGroupsLazyQuery>;
export type GetDisplayGroupsQueryResult = ApolloReactCommon.QueryResult<GetDisplayGroupsQuery, GetDisplayGroupsQueryVariables>;
export const SearchDisplaysDocument = gql`
    query SearchDisplays($customerId: ID!, $search: String) {
  customer(id: $customerId) {
    displaygroupSet(name_Icontains: $search) {
      edges {
        node {
          id
          name
          publicationallocationSet {
            edges {
              node {
                id
              }
            }
          }
          displaycomputerSet {
            edges {
              node {
                id
                name
                displaySet {
                  edges {
                    node {
                      ...DisplayItem
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    displaycomputerSet(name_Icontains: $search) {
      edges {
        node {
          id
          name
          displaySet {
            edges {
              node {
                ...DisplayItem
                publicationallocationSet {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DisplayItemFragmentDoc}`;

/**
 * __useSearchDisplaysQuery__
 *
 * To run a query within a React component, call `useSearchDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDisplaysQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchDisplaysQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchDisplaysQuery, SearchDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchDisplaysQuery, SearchDisplaysQueryVariables>(SearchDisplaysDocument, options);
      }
export function useSearchDisplaysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchDisplaysQuery, SearchDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchDisplaysQuery, SearchDisplaysQueryVariables>(SearchDisplaysDocument, options);
        }
export type SearchDisplaysQueryHookResult = ReturnType<typeof useSearchDisplaysQuery>;
export type SearchDisplaysLazyQueryHookResult = ReturnType<typeof useSearchDisplaysLazyQuery>;
export type SearchDisplaysQueryResult = ApolloReactCommon.QueryResult<SearchDisplaysQuery, SearchDisplaysQueryVariables>;
export const GetDisplayComputersDocument = gql`
    query GetDisplayComputers($customerId: ID!) {
  customer(id: $customerId) {
    displaycomputerSet {
      edges {
        node {
          ...DisplayComputerListItem
        }
      }
    }
  }
}
    ${DisplayComputerListItemFragmentDoc}`;

/**
 * __useGetDisplayComputersQuery__
 *
 * To run a query within a React component, call `useGetDisplayComputersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayComputersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayComputersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetDisplayComputersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDisplayComputersQuery, GetDisplayComputersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayComputersQuery, GetDisplayComputersQueryVariables>(GetDisplayComputersDocument, options);
      }
export function useGetDisplayComputersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayComputersQuery, GetDisplayComputersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayComputersQuery, GetDisplayComputersQueryVariables>(GetDisplayComputersDocument, options);
        }
export type GetDisplayComputersQueryHookResult = ReturnType<typeof useGetDisplayComputersQuery>;
export type GetDisplayComputersLazyQueryHookResult = ReturnType<typeof useGetDisplayComputersLazyQuery>;
export type GetDisplayComputersQueryResult = ApolloReactCommon.QueryResult<GetDisplayComputersQuery, GetDisplayComputersQueryVariables>;
export const GetDisplayComputerDocument = gql`
    query GetDisplayComputer($id: ID!) {
  displayComputer(id: $id) {
    ...DisplayComputerListItem
  }
}
    ${DisplayComputerListItemFragmentDoc}`;

/**
 * __useGetDisplayComputerQuery__
 *
 * To run a query within a React component, call `useGetDisplayComputerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayComputerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayComputerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplayComputerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDisplayComputerQuery, GetDisplayComputerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayComputerQuery, GetDisplayComputerQueryVariables>(GetDisplayComputerDocument, options);
      }
export function useGetDisplayComputerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayComputerQuery, GetDisplayComputerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayComputerQuery, GetDisplayComputerQueryVariables>(GetDisplayComputerDocument, options);
        }
export type GetDisplayComputerQueryHookResult = ReturnType<typeof useGetDisplayComputerQuery>;
export type GetDisplayComputerLazyQueryHookResult = ReturnType<typeof useGetDisplayComputerLazyQuery>;
export type GetDisplayComputerQueryResult = ApolloReactCommon.QueryResult<GetDisplayComputerQuery, GetDisplayComputerQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($customerId: ID!, $nameStartsWith: String, $first: Int) {
  customer(id: $customerId) {
    displaygroupSet(name_Istartswith: $nameStartsWith, first: $first) {
      edges {
        node {
          ...DisplayGroup
          customer {
            id
          }
        }
      }
    }
  }
}
    ${DisplayGroupFragmentDoc}`;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      nameStartsWith: // value for 'nameStartsWith'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = ApolloReactCommon.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const CreateRegistrationPinDocument = gql`
    mutation CreateRegistrationPin($displayId: ID!) {
  createRegistrationPin(input: {displayId: $displayId}) {
    pinCode
  }
}
    `;
export type CreateRegistrationPinMutationFn = ApolloReactCommon.MutationFunction<CreateRegistrationPinMutation, CreateRegistrationPinMutationVariables>;

/**
 * __useCreateRegistrationPinMutation__
 *
 * To run a mutation, you first call `useCreateRegistrationPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistrationPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistrationPinMutation, { data, loading, error }] = useCreateRegistrationPinMutation({
 *   variables: {
 *      displayId: // value for 'displayId'
 *   },
 * });
 */
export function useCreateRegistrationPinMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRegistrationPinMutation, CreateRegistrationPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateRegistrationPinMutation, CreateRegistrationPinMutationVariables>(CreateRegistrationPinDocument, options);
      }
export type CreateRegistrationPinMutationHookResult = ReturnType<typeof useCreateRegistrationPinMutation>;
export type CreateRegistrationPinMutationResult = ApolloReactCommon.MutationResult<CreateRegistrationPinMutation>;
export type CreateRegistrationPinMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRegistrationPinMutation, CreateRegistrationPinMutationVariables>;
export const DeleteRegistrationDocument = gql`
    mutation DeleteRegistration($displayId: ID!) {
  deleteRegistration(input: {displayId: $displayId}) {
    ok
  }
}
    `;
export type DeleteRegistrationMutationFn = ApolloReactCommon.MutationFunction<DeleteRegistrationMutation, DeleteRegistrationMutationVariables>;

/**
 * __useDeleteRegistrationMutation__
 *
 * To run a mutation, you first call `useDeleteRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistrationMutation, { data, loading, error }] = useDeleteRegistrationMutation({
 *   variables: {
 *      displayId: // value for 'displayId'
 *   },
 * });
 */
export function useDeleteRegistrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRegistrationMutation, DeleteRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRegistrationMutation, DeleteRegistrationMutationVariables>(DeleteRegistrationDocument, options);
      }
export type DeleteRegistrationMutationHookResult = ReturnType<typeof useDeleteRegistrationMutation>;
export type DeleteRegistrationMutationResult = ApolloReactCommon.MutationResult<DeleteRegistrationMutation>;
export type DeleteRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRegistrationMutation, DeleteRegistrationMutationVariables>;
export const CreateDisplayComputerDocument = gql`
    mutation CreateDisplayComputer($customerId: ID!, $name: String!) {
  createDisplayComputer(input: {customerId: $customerId, name: $name}) {
    displayComputer {
      id
      name
    }
  }
}
    `;
export type CreateDisplayComputerMutationFn = ApolloReactCommon.MutationFunction<CreateDisplayComputerMutation, CreateDisplayComputerMutationVariables>;

/**
 * __useCreateDisplayComputerMutation__
 *
 * To run a mutation, you first call `useCreateDisplayComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisplayComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisplayComputerMutation, { data, loading, error }] = useCreateDisplayComputerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateDisplayComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDisplayComputerMutation, CreateDisplayComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDisplayComputerMutation, CreateDisplayComputerMutationVariables>(CreateDisplayComputerDocument, options);
      }
export type CreateDisplayComputerMutationHookResult = ReturnType<typeof useCreateDisplayComputerMutation>;
export type CreateDisplayComputerMutationResult = ApolloReactCommon.MutationResult<CreateDisplayComputerMutation>;
export type CreateDisplayComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDisplayComputerMutation, CreateDisplayComputerMutationVariables>;
export const UpdateDisplayComputerDocument = gql`
    mutation UpdateDisplayComputer($input: UpdateDisplayComputerInput!) {
  updateDisplayComputer(input: $input) {
    displayComputer {
      ...DisplayComputerListItem
    }
  }
}
    ${DisplayComputerListItemFragmentDoc}`;
export type UpdateDisplayComputerMutationFn = ApolloReactCommon.MutationFunction<UpdateDisplayComputerMutation, UpdateDisplayComputerMutationVariables>;

/**
 * __useUpdateDisplayComputerMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayComputerMutation, { data, loading, error }] = useUpdateDisplayComputerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisplayComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisplayComputerMutation, UpdateDisplayComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateDisplayComputerMutation, UpdateDisplayComputerMutationVariables>(UpdateDisplayComputerDocument, options);
      }
export type UpdateDisplayComputerMutationHookResult = ReturnType<typeof useUpdateDisplayComputerMutation>;
export type UpdateDisplayComputerMutationResult = ApolloReactCommon.MutationResult<UpdateDisplayComputerMutation>;
export type UpdateDisplayComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDisplayComputerMutation, UpdateDisplayComputerMutationVariables>;
export const DeleteDisplayComputerDocument = gql`
    mutation DeleteDisplayComputer($id: ID!) {
  deleteDisplayComputer(input: {id: $id}) {
    ok
  }
}
    `;
export type DeleteDisplayComputerMutationFn = ApolloReactCommon.MutationFunction<DeleteDisplayComputerMutation, DeleteDisplayComputerMutationVariables>;

/**
 * __useDeleteDisplayComputerMutation__
 *
 * To run a mutation, you first call `useDeleteDisplayComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisplayComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisplayComputerMutation, { data, loading, error }] = useDeleteDisplayComputerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDisplayComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDisplayComputerMutation, DeleteDisplayComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteDisplayComputerMutation, DeleteDisplayComputerMutationVariables>(DeleteDisplayComputerDocument, options);
      }
export type DeleteDisplayComputerMutationHookResult = ReturnType<typeof useDeleteDisplayComputerMutation>;
export type DeleteDisplayComputerMutationResult = ApolloReactCommon.MutationResult<DeleteDisplayComputerMutation>;
export type DeleteDisplayComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDisplayComputerMutation, DeleteDisplayComputerMutationVariables>;
export const AddGroupToComputerDocument = gql`
    mutation AddGroupToComputer($computerId: ID!, $groupId: ID!) {
  addGroupToComputer(input: {computerId: $computerId, groupId: $groupId}) {
    displayGroup {
      groups {
        edgeCount
      }
    }
  }
}
    `;
export type AddGroupToComputerMutationFn = ApolloReactCommon.MutationFunction<AddGroupToComputerMutation, AddGroupToComputerMutationVariables>;

/**
 * __useAddGroupToComputerMutation__
 *
 * To run a mutation, you first call `useAddGroupToComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupToComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupToComputerMutation, { data, loading, error }] = useAddGroupToComputerMutation({
 *   variables: {
 *      computerId: // value for 'computerId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useAddGroupToComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGroupToComputerMutation, AddGroupToComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddGroupToComputerMutation, AddGroupToComputerMutationVariables>(AddGroupToComputerDocument, options);
      }
export type AddGroupToComputerMutationHookResult = ReturnType<typeof useAddGroupToComputerMutation>;
export type AddGroupToComputerMutationResult = ApolloReactCommon.MutationResult<AddGroupToComputerMutation>;
export type AddGroupToComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGroupToComputerMutation, AddGroupToComputerMutationVariables>;
export const RemoveGroupToComputerDocument = gql`
    mutation RemoveGroupToComputer($computerId: ID!, $groupId: ID!) {
  removeGroupToComputer(input: {computerId: $computerId, groupId: $groupId}) {
    displayGroup {
      groups {
        edgeCount
      }
    }
  }
}
    `;
export type RemoveGroupToComputerMutationFn = ApolloReactCommon.MutationFunction<RemoveGroupToComputerMutation, RemoveGroupToComputerMutationVariables>;

/**
 * __useRemoveGroupToComputerMutation__
 *
 * To run a mutation, you first call `useRemoveGroupToComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupToComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupToComputerMutation, { data, loading, error }] = useRemoveGroupToComputerMutation({
 *   variables: {
 *      computerId: // value for 'computerId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRemoveGroupToComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveGroupToComputerMutation, RemoveGroupToComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveGroupToComputerMutation, RemoveGroupToComputerMutationVariables>(RemoveGroupToComputerDocument, options);
      }
export type RemoveGroupToComputerMutationHookResult = ReturnType<typeof useRemoveGroupToComputerMutation>;
export type RemoveGroupToComputerMutationResult = ApolloReactCommon.MutationResult<RemoveGroupToComputerMutation>;
export type RemoveGroupToComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveGroupToComputerMutation, RemoveGroupToComputerMutationVariables>;
export const CreateDisplayDocument = gql`
    mutation CreateDisplay($computerId: ID!, $height: Int!, $width: Int!) {
  createDisplay(
    input: {computerId: $computerId, widthPx: $width, heightPx: $height}
  ) {
    display {
      ...DisplayItem
    }
  }
}
    ${DisplayItemFragmentDoc}`;
export type CreateDisplayMutationFn = ApolloReactCommon.MutationFunction<CreateDisplayMutation, CreateDisplayMutationVariables>;

/**
 * __useCreateDisplayMutation__
 *
 * To run a mutation, you first call `useCreateDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisplayMutation, { data, loading, error }] = useCreateDisplayMutation({
 *   variables: {
 *      computerId: // value for 'computerId'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *   },
 * });
 */
export function useCreateDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDisplayMutation, CreateDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDisplayMutation, CreateDisplayMutationVariables>(CreateDisplayDocument, options);
      }
export type CreateDisplayMutationHookResult = ReturnType<typeof useCreateDisplayMutation>;
export type CreateDisplayMutationResult = ApolloReactCommon.MutationResult<CreateDisplayMutation>;
export type CreateDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDisplayMutation, CreateDisplayMutationVariables>;
export const UpdateDisplayDocument = gql`
    mutation UpdateDisplay($id: ID!, $width: Int, $height: Int) {
  updateDisplay(input: {id: $id, widthPx: $width, heightPx: $height}) {
    display {
      ...DisplayItem
    }
  }
}
    ${DisplayItemFragmentDoc}`;
export type UpdateDisplayMutationFn = ApolloReactCommon.MutationFunction<UpdateDisplayMutation, UpdateDisplayMutationVariables>;

/**
 * __useUpdateDisplayMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayMutation, { data, loading, error }] = useUpdateDisplayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useUpdateDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisplayMutation, UpdateDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateDisplayMutation, UpdateDisplayMutationVariables>(UpdateDisplayDocument, options);
      }
export type UpdateDisplayMutationHookResult = ReturnType<typeof useUpdateDisplayMutation>;
export type UpdateDisplayMutationResult = ApolloReactCommon.MutationResult<UpdateDisplayMutation>;
export type UpdateDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDisplayMutation, UpdateDisplayMutationVariables>;
export const DeleteDisplayDocument = gql`
    mutation DeleteDisplay($id: ID!) {
  deleteDisplay(input: {id: $id}) {
    ok
  }
}
    `;
export type DeleteDisplayMutationFn = ApolloReactCommon.MutationFunction<DeleteDisplayMutation, DeleteDisplayMutationVariables>;

/**
 * __useDeleteDisplayMutation__
 *
 * To run a mutation, you first call `useDeleteDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisplayMutation, { data, loading, error }] = useDeleteDisplayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDisplayMutation, DeleteDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteDisplayMutation, DeleteDisplayMutationVariables>(DeleteDisplayDocument, options);
      }
export type DeleteDisplayMutationHookResult = ReturnType<typeof useDeleteDisplayMutation>;
export type DeleteDisplayMutationResult = ApolloReactCommon.MutationResult<DeleteDisplayMutation>;
export type DeleteDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDisplayMutation, DeleteDisplayMutationVariables>;
export const UpdateAdminFieldsDocument = gql`
    mutation updateAdminFields($id: ID!, $anydesk: String, $informations: String, $serialNumber: String, $designation: String) {
  updateAdminFields(
    input: {id: $id, anydesk: $anydesk, informations: $informations, serialNumber: $serialNumber, designation: $designation}
  ) {
    adminFields {
      anydesk
      informations
      serialNumber
      designation
    }
  }
}
    `;
export type UpdateAdminFieldsMutationFn = ApolloReactCommon.MutationFunction<UpdateAdminFieldsMutation, UpdateAdminFieldsMutationVariables>;

/**
 * __useUpdateAdminFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateAdminFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminFieldsMutation, { data, loading, error }] = useUpdateAdminFieldsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      anydesk: // value for 'anydesk'
 *      informations: // value for 'informations'
 *      serialNumber: // value for 'serialNumber'
 *      designation: // value for 'designation'
 *   },
 * });
 */
export function useUpdateAdminFieldsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdminFieldsMutation, UpdateAdminFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAdminFieldsMutation, UpdateAdminFieldsMutationVariables>(UpdateAdminFieldsDocument, options);
      }
export type UpdateAdminFieldsMutationHookResult = ReturnType<typeof useUpdateAdminFieldsMutation>;
export type UpdateAdminFieldsMutationResult = ApolloReactCommon.MutationResult<UpdateAdminFieldsMutation>;
export type UpdateAdminFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdminFieldsMutation, UpdateAdminFieldsMutationVariables>;
export const GetCustomersSoftwareVersionsDocument = gql`
    query GetCustomersSoftwareVersions {
  customers {
    edges {
      node {
        ...CustomerSoftwareVersion
      }
    }
  }
}
    ${CustomerSoftwareVersionFragmentDoc}`;

/**
 * __useGetCustomersSoftwareVersionsQuery__
 *
 * To run a query within a React component, call `useGetCustomersSoftwareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersSoftwareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersSoftwareVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersSoftwareVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomersSoftwareVersionsQuery, GetCustomersSoftwareVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomersSoftwareVersionsQuery, GetCustomersSoftwareVersionsQueryVariables>(GetCustomersSoftwareVersionsDocument, options);
      }
export function useGetCustomersSoftwareVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomersSoftwareVersionsQuery, GetCustomersSoftwareVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomersSoftwareVersionsQuery, GetCustomersSoftwareVersionsQueryVariables>(GetCustomersSoftwareVersionsDocument, options);
        }
export type GetCustomersSoftwareVersionsQueryHookResult = ReturnType<typeof useGetCustomersSoftwareVersionsQuery>;
export type GetCustomersSoftwareVersionsLazyQueryHookResult = ReturnType<typeof useGetCustomersSoftwareVersionsLazyQuery>;
export type GetCustomersSoftwareVersionsQueryResult = ApolloReactCommon.QueryResult<GetCustomersSoftwareVersionsQuery, GetCustomersSoftwareVersionsQueryVariables>;
export const GetCustomerVersionDocument = gql`
    query GetCustomerVersion($id: ID!) {
  customer(id: $id) {
    id
    backofficeVersion {
      ...BackofficeSoftwareVersion
    }
  }
}
    ${BackofficeSoftwareVersionFragmentDoc}`;

/**
 * __useGetCustomerVersionQuery__
 *
 * To run a query within a React component, call `useGetCustomerVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerVersionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerVersionQuery, GetCustomerVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomerVersionQuery, GetCustomerVersionQueryVariables>(GetCustomerVersionDocument, options);
      }
export function useGetCustomerVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerVersionQuery, GetCustomerVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomerVersionQuery, GetCustomerVersionQueryVariables>(GetCustomerVersionDocument, options);
        }
export type GetCustomerVersionQueryHookResult = ReturnType<typeof useGetCustomerVersionQuery>;
export type GetCustomerVersionLazyQueryHookResult = ReturnType<typeof useGetCustomerVersionLazyQuery>;
export type GetCustomerVersionQueryResult = ApolloReactCommon.QueryResult<GetCustomerVersionQuery, GetCustomerVersionQueryVariables>;
export const GetDisplayComputersSoftwareVersionsDocument = gql`
    query GetDisplayComputersSoftwareVersions($first: Int, $after: String) {
  displayComputers(first: $first, after: $after) {
    edgeCount
    edges {
      node {
        ...ComputerSoftwareVersion
      }
    }
  }
}
    ${ComputerSoftwareVersionFragmentDoc}`;

/**
 * __useGetDisplayComputersSoftwareVersionsQuery__
 *
 * To run a query within a React component, call `useGetDisplayComputersSoftwareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayComputersSoftwareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayComputersSoftwareVersionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDisplayComputersSoftwareVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisplayComputersSoftwareVersionsQuery, GetDisplayComputersSoftwareVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayComputersSoftwareVersionsQuery, GetDisplayComputersSoftwareVersionsQueryVariables>(GetDisplayComputersSoftwareVersionsDocument, options);
      }
export function useGetDisplayComputersSoftwareVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayComputersSoftwareVersionsQuery, GetDisplayComputersSoftwareVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayComputersSoftwareVersionsQuery, GetDisplayComputersSoftwareVersionsQueryVariables>(GetDisplayComputersSoftwareVersionsDocument, options);
        }
export type GetDisplayComputersSoftwareVersionsQueryHookResult = ReturnType<typeof useGetDisplayComputersSoftwareVersionsQuery>;
export type GetDisplayComputersSoftwareVersionsLazyQueryHookResult = ReturnType<typeof useGetDisplayComputersSoftwareVersionsLazyQuery>;
export type GetDisplayComputersSoftwareVersionsQueryResult = ApolloReactCommon.QueryResult<GetDisplayComputersSoftwareVersionsQuery, GetDisplayComputersSoftwareVersionsQueryVariables>;
export const GetWidgetTypesDocument = gql`
    query GetWidgetTypes {
  allWidgets {
    edges {
      node {
        ...WidgetType
      }
    }
  }
}
    ${WidgetTypeFragmentDoc}`;

/**
 * __useGetWidgetTypesQuery__
 *
 * To run a query within a React component, call `useGetWidgetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWidgetTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWidgetTypesQuery, GetWidgetTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWidgetTypesQuery, GetWidgetTypesQueryVariables>(GetWidgetTypesDocument, options);
      }
export function useGetWidgetTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWidgetTypesQuery, GetWidgetTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWidgetTypesQuery, GetWidgetTypesQueryVariables>(GetWidgetTypesDocument, options);
        }
export type GetWidgetTypesQueryHookResult = ReturnType<typeof useGetWidgetTypesQuery>;
export type GetWidgetTypesLazyQueryHookResult = ReturnType<typeof useGetWidgetTypesLazyQuery>;
export type GetWidgetTypesQueryResult = ApolloReactCommon.QueryResult<GetWidgetTypesQuery, GetWidgetTypesQueryVariables>;
export const GetSoftwareVersionsDocument = gql`
    query GetSoftwareVersions {
  backofficeVersionList {
    edges {
      node {
        ...BackofficeSoftwareVersion
      }
    }
  }
  playerVersionList {
    edges {
      node {
        ...PlayerSoftwareVersion
      }
    }
  }
}
    ${BackofficeSoftwareVersionFragmentDoc}
${PlayerSoftwareVersionFragmentDoc}`;

/**
 * __useGetSoftwareVersionsQuery__
 *
 * To run a query within a React component, call `useGetSoftwareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoftwareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoftwareVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSoftwareVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSoftwareVersionsQuery, GetSoftwareVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSoftwareVersionsQuery, GetSoftwareVersionsQueryVariables>(GetSoftwareVersionsDocument, options);
      }
export function useGetSoftwareVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSoftwareVersionsQuery, GetSoftwareVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSoftwareVersionsQuery, GetSoftwareVersionsQueryVariables>(GetSoftwareVersionsDocument, options);
        }
export type GetSoftwareVersionsQueryHookResult = ReturnType<typeof useGetSoftwareVersionsQuery>;
export type GetSoftwareVersionsLazyQueryHookResult = ReturnType<typeof useGetSoftwareVersionsLazyQuery>;
export type GetSoftwareVersionsQueryResult = ApolloReactCommon.QueryResult<GetSoftwareVersionsQuery, GetSoftwareVersionsQueryVariables>;
export const ActivateWidgetOnCustomerDocument = gql`
    mutation ActivateWidgetOnCustomer($input: ActivateWidgetOnCustomerInput!) {
  activateWidgetOnCustomer(input: $input) {
    customers {
      edges {
        node {
          ...CustomerSoftwareVersion
        }
      }
    }
  }
}
    ${CustomerSoftwareVersionFragmentDoc}`;
export type ActivateWidgetOnCustomerMutationFn = ApolloReactCommon.MutationFunction<ActivateWidgetOnCustomerMutation, ActivateWidgetOnCustomerMutationVariables>;

/**
 * __useActivateWidgetOnCustomerMutation__
 *
 * To run a mutation, you first call `useActivateWidgetOnCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateWidgetOnCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateWidgetOnCustomerMutation, { data, loading, error }] = useActivateWidgetOnCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateWidgetOnCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateWidgetOnCustomerMutation, ActivateWidgetOnCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ActivateWidgetOnCustomerMutation, ActivateWidgetOnCustomerMutationVariables>(ActivateWidgetOnCustomerDocument, options);
      }
export type ActivateWidgetOnCustomerMutationHookResult = ReturnType<typeof useActivateWidgetOnCustomerMutation>;
export type ActivateWidgetOnCustomerMutationResult = ApolloReactCommon.MutationResult<ActivateWidgetOnCustomerMutation>;
export type ActivateWidgetOnCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateWidgetOnCustomerMutation, ActivateWidgetOnCustomerMutationVariables>;
export const AssignBackOfficeVersionToCustomerDocument = gql`
    mutation AssignBackOfficeVersionToCustomer($customerId: ID!, $backofficeVersionId: ID!) {
  assignBackofficeVersionToCustomer(
    input: {customerId: $customerId, backofficeVersionId: $backofficeVersionId}
  ) {
    customer {
      ...CustomerSoftwareVersion
    }
  }
}
    ${CustomerSoftwareVersionFragmentDoc}`;
export type AssignBackOfficeVersionToCustomerMutationFn = ApolloReactCommon.MutationFunction<AssignBackOfficeVersionToCustomerMutation, AssignBackOfficeVersionToCustomerMutationVariables>;

/**
 * __useAssignBackOfficeVersionToCustomerMutation__
 *
 * To run a mutation, you first call `useAssignBackOfficeVersionToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignBackOfficeVersionToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignBackOfficeVersionToCustomerMutation, { data, loading, error }] = useAssignBackOfficeVersionToCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      backofficeVersionId: // value for 'backofficeVersionId'
 *   },
 * });
 */
export function useAssignBackOfficeVersionToCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignBackOfficeVersionToCustomerMutation, AssignBackOfficeVersionToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignBackOfficeVersionToCustomerMutation, AssignBackOfficeVersionToCustomerMutationVariables>(AssignBackOfficeVersionToCustomerDocument, options);
      }
export type AssignBackOfficeVersionToCustomerMutationHookResult = ReturnType<typeof useAssignBackOfficeVersionToCustomerMutation>;
export type AssignBackOfficeVersionToCustomerMutationResult = ApolloReactCommon.MutationResult<AssignBackOfficeVersionToCustomerMutation>;
export type AssignBackOfficeVersionToCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignBackOfficeVersionToCustomerMutation, AssignBackOfficeVersionToCustomerMutationVariables>;
export const AssignPlayerVersionToComputerDocument = gql`
    mutation AssignPlayerVersionToComputer($computerId: ID!, $playerVersionId: ID!) {
  assignPlayerVersionToComputer(
    input: {computerId: $computerId, playerVersionId: $playerVersionId}
  ) {
    computer {
      ...ComputerSoftwareVersion
    }
  }
}
    ${ComputerSoftwareVersionFragmentDoc}`;
export type AssignPlayerVersionToComputerMutationFn = ApolloReactCommon.MutationFunction<AssignPlayerVersionToComputerMutation, AssignPlayerVersionToComputerMutationVariables>;

/**
 * __useAssignPlayerVersionToComputerMutation__
 *
 * To run a mutation, you first call `useAssignPlayerVersionToComputerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPlayerVersionToComputerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPlayerVersionToComputerMutation, { data, loading, error }] = useAssignPlayerVersionToComputerMutation({
 *   variables: {
 *      computerId: // value for 'computerId'
 *      playerVersionId: // value for 'playerVersionId'
 *   },
 * });
 */
export function useAssignPlayerVersionToComputerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignPlayerVersionToComputerMutation, AssignPlayerVersionToComputerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignPlayerVersionToComputerMutation, AssignPlayerVersionToComputerMutationVariables>(AssignPlayerVersionToComputerDocument, options);
      }
export type AssignPlayerVersionToComputerMutationHookResult = ReturnType<typeof useAssignPlayerVersionToComputerMutation>;
export type AssignPlayerVersionToComputerMutationResult = ApolloReactCommon.MutationResult<AssignPlayerVersionToComputerMutation>;
export type AssignPlayerVersionToComputerMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignPlayerVersionToComputerMutation, AssignPlayerVersionToComputerMutationVariables>;
export const GetCustomerUsersDocument = gql`
    query GetCustomerUsers($customerId: ID!) {
  customer(id: $customerId) {
    userSet {
      edges {
        node {
          id
          firstName
          lastName
          email
          isActive
          isStaff
          profile
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerUsersQuery__
 *
 * To run a query within a React component, call `useGetCustomerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerUsersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, options);
      }
export function useGetCustomerUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, options);
        }
export type GetCustomerUsersQueryHookResult = ReturnType<typeof useGetCustomerUsersQuery>;
export type GetCustomerUsersLazyQueryHookResult = ReturnType<typeof useGetCustomerUsersLazyQuery>;
export type GetCustomerUsersQueryResult = ApolloReactCommon.QueryResult<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($search: String!, $first: Int) {
  users(email_Icontains: $search, first: $first) {
    edges {
      node {
        id
        firstName
        lastName
        email
        profile
      }
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($email: String!, $customerId: ID!, $firstName: String!, $lastName: String!, $profile: UserProfile!) {
  registerUser(
    input: {email: $email, customerId: $customerId, firstName: $firstName, lastName: $lastName, profile: $profile}
  ) {
    user {
      id
      email
    }
  }
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      customerId: // value for 'customerId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $firstName: String, $lastName: String, $profile: UserProfile) {
  updateUser(
    input: {userId: $id, firstName: $firstName, lastName: $lastName, profile: $profile}
  ) {
    user {
      id
      firstName
      lastName
      profile
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($id: ID!, $customerId: ID!) {
  addUser(input: {userId: $id, customerId: $customerId}) {
    user {
      id
      email
      customers {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: ID!, $customerId: ID!) {
  removeUser(input: {userId: $id, customerId: $customerId}) {
    user {
      id
      email
      customers {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const CreateWidgetMediaDocument = gql`
    mutation CreateWidgetMedia($input: CreateWidgetMediaInput!) {
  createWidgetMedia(input: $input) {
    widgetMedia {
      ...WidgetMediaFragment
    }
  }
}
    ${WidgetMediaFragmentFragmentDoc}`;
export type CreateWidgetMediaMutationFn = ApolloReactCommon.MutationFunction<CreateWidgetMediaMutation, CreateWidgetMediaMutationVariables>;

/**
 * __useCreateWidgetMediaMutation__
 *
 * To run a mutation, you first call `useCreateWidgetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWidgetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWidgetMediaMutation, { data, loading, error }] = useCreateWidgetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWidgetMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWidgetMediaMutation, CreateWidgetMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateWidgetMediaMutation, CreateWidgetMediaMutationVariables>(CreateWidgetMediaDocument, options);
      }
export type CreateWidgetMediaMutationHookResult = ReturnType<typeof useCreateWidgetMediaMutation>;
export type CreateWidgetMediaMutationResult = ApolloReactCommon.MutationResult<CreateWidgetMediaMutation>;
export type CreateWidgetMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWidgetMediaMutation, CreateWidgetMediaMutationVariables>;
export const UpdateWidgetMediaDocument = gql`
    mutation UpdateWidgetMedia($input: UpdateWidgetMediaInput!) {
  updateWidgetMedia(input: $input) {
    widgetMedia {
      ...WidgetMediaFragment
    }
  }
}
    ${WidgetMediaFragmentFragmentDoc}`;
export type UpdateWidgetMediaMutationFn = ApolloReactCommon.MutationFunction<UpdateWidgetMediaMutation, UpdateWidgetMediaMutationVariables>;

/**
 * __useUpdateWidgetMediaMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetMediaMutation, { data, loading, error }] = useUpdateWidgetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWidgetMediaMutation, UpdateWidgetMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateWidgetMediaMutation, UpdateWidgetMediaMutationVariables>(UpdateWidgetMediaDocument, options);
      }
export type UpdateWidgetMediaMutationHookResult = ReturnType<typeof useUpdateWidgetMediaMutation>;
export type UpdateWidgetMediaMutationResult = ApolloReactCommon.MutationResult<UpdateWidgetMediaMutation>;
export type UpdateWidgetMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWidgetMediaMutation, UpdateWidgetMediaMutationVariables>;
export const DeleteWidgetMediaDocument = gql`
    mutation DeleteWidgetMedia($input: DeleteWidgetMediaInput!) {
  deleteWidgetMedia(input: $input) {
    ok
  }
}
    `;
export type DeleteWidgetMediaMutationFn = ApolloReactCommon.MutationFunction<DeleteWidgetMediaMutation, DeleteWidgetMediaMutationVariables>;

/**
 * __useDeleteWidgetMediaMutation__
 *
 * To run a mutation, you first call `useDeleteWidgetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWidgetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWidgetMediaMutation, { data, loading, error }] = useDeleteWidgetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWidgetMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWidgetMediaMutation, DeleteWidgetMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteWidgetMediaMutation, DeleteWidgetMediaMutationVariables>(DeleteWidgetMediaDocument, options);
      }
export type DeleteWidgetMediaMutationHookResult = ReturnType<typeof useDeleteWidgetMediaMutation>;
export type DeleteWidgetMediaMutationResult = ApolloReactCommon.MutationResult<DeleteWidgetMediaMutation>;
export type DeleteWidgetMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWidgetMediaMutation, DeleteWidgetMediaMutationVariables>;