import { CustomerList } from "./screens/CustomerList";
import { useGetMeQuery } from "./generated/graphql";
import { Login } from "./screens/Login";
import { CustomerBackOfficeScreen } from "./screens/CustomerBackOffice";
import { Redirect, Route, Switch } from "react-router";
import { isLogged } from "./api";
import { FunctionComponent } from "react";
import { Spinner } from "@chakra-ui/spinner";
import { AdminScreen } from "./screens/Admin";

const PrivateRoute = ({
  component: ChildComponent,
  ...rest
}: {
  component: FunctionComponent<any>;
  path?: any;
  exact?: boolean;
}) => (
  <Route
    {...rest}
    render={(...props) =>
      isLogged() ? <ChildComponent /> : <Redirect to="/login" />
    }
  />
);

export const Routes = () => {
  const { data, loading } = useGetMeQuery();
  const customers = data?.me?.customers?.edges;
  const customer = customers && customers.length > 0 ? customers[0] : null;
  return loading ? (
    <Spinner />
  ) : (
    <Switch>
      <Route exact path="/">
        {data?.me?.isStaff ? (
          <Redirect to="/mgmt" />
        ) : (customers?.length || 0) > 1 ? (
          <Redirect to="/customers" />
        ) : customers?.length === 1 ? (
          <Redirect to={`/backoffice/${customer?.node?.id}`} />
        ) : (
          <Redirect to={`/login`} />
        )}
      </Route>
      <PrivateRoute path="/customers" component={CustomerList} />
      <PrivateRoute path="/mgmt" component={AdminScreen} />
      <PrivateRoute
        path="/backoffice/:customerId"
        component={CustomerBackOfficeScreen}
      />
      <Route path="/login" component={Login} />
    </Switch>
  );
};
