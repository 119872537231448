import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import { BackOfficeVersions } from "./BackOfficeVersions";
import { PlayerVersions } from "./PlayerVersions";

export const SoftwareVersions = () => {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  return (
    <Box padding={isLargerThan1080 ? "32px" : "8px"}>
      <Tabs variant="soft-rounded" colorScheme="orange">
        <TabList>
          <Tab>BackOffice</Tab>
          <Tab>Player</Tab>
          <Tab>Platform</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <BackOfficeVersions />
          </TabPanel>
          <TabPanel>
            <PlayerVersions />
          </TabPanel>
          <TabPanel>
            <p>three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
