import React, { FunctionComponent } from "react";

type PlaylistContextType = {
  selectedMedia: null | string;
  setSelectedMedia: (mediaId: string) => void;
};

export const PlaylistContext = React.createContext<PlaylistContextType>({
  selectedMedia: null,
  setSelectedMedia: () => null,
});

export const PlaylistContextProvider: FunctionComponent = ({ children }) => {
  const [selectedMedia, setSelectedMedia] = React.useState<null | string>(null);
  return (
    <PlaylistContext.Provider
      value={{
        selectedMedia,
        setSelectedMedia,
      }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};
