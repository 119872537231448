import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  IconButton,
  Button,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
  Checkbox,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { CustomerContext } from "../../screens/NavigationContext";
import {
  DisplayGroupNode,
  DisplayNode,
  useGetAllocationQuery,
  useRemoveDisplayGroupToAllocationMutation,
  useRemoveDisplayToAllocationMutation,
  useUpdatePublicationAllocationMutation,
} from "../../generated/graphql";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { AddDisplay } from "./AddDisplay";
import { DisplayGroupCard, DisplayItem } from "./DisplayGroupCard";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import { useForm } from "react-hook-form";
import { FiSave, FiX } from "react-icons/fi";

type UpdatePublishParams = {
  allocationId: string;
  index: string;
};

type FormFields = {
  startDate: string;
  endDate: string;
  startTime: string | null;
  endTime: string | null;
  active: boolean;
};

const formatToLocalIsoDate = (date: string) => {
  if (date) {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    const dateTime = new Date(new Date(date).getTime() - timeZoneOffset);
    return dateTime.toISOString().substring(0, 16);
  }
  return "";
};

export const UpdatePublish = () => {
  const { allocationId, index } = useParams<UpdatePublishParams>();
  const customerId = useContext(CustomerContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useGetAllocationQuery({
    variables: { publicationAllocationId: allocationId },
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const { register, handleSubmit, setValue } = useForm<FormFields>();
  const toast = useToast();

  const [updateAllocation] = useUpdatePublicationAllocationMutation({
    refetchQueries: ["GetAllocation", "GetPublicationAllocations"],
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [removeDisplayGroup] = useRemoveDisplayGroupToAllocationMutation({
    refetchQueries: ["GetAllocation", "GetPublicationAllocations"],
  });
  const [removeDisplay] = useRemoveDisplayToAllocationMutation({
    refetchQueries: ["GetAllocation", "GetPublicationAllocations"],
  });

  const onSubmit = handleSubmit(async (formData) => {
    setIsUpdating(true);
    await updateAllocation({
      variables: {
        id: allocationId,
        active: formData.active,
        startDate: formData.startDate
          ? new Date(formData.startDate).toISOString()
          : null,
        endDate: formData.endDate
          ? new Date(formData.endDate).toISOString()
          : null,
        startTime: formData.startTime || null,
        endTime: formData.endTime || null,
      },
    });
    setIsUpdating(false);
  });

  return (
    <VStack alignItems="flex-start" spacing="18px">
      <DataBreadcrumb
        items={[
          {
            name: "Publications",
            url: `/backoffice/${customerId}/publications`,
          },
          {
            name: data?.publicationAllocation?.publication.name || "",
            url: `/backoffice/${customerId}/publications/publication/${data?.publicationAllocation?.publication.id}`,
          },
          {
            name: "Liste de diffusion",
            url: `/backoffice/${customerId}/publications/publication/${data?.publicationAllocation?.publication.id}/publish`,
          },
          {
            name: `Diffusion n°${parseInt(index) + 1}`,
          },
        ]}
      />

      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={"70px"}
        width="100%"
      >
        <GridItem colSpan={3}>
          <VStack alignItems="flex-start">
            <Text
              fontSize="md"
              fontWeight="bold"
              color="gray.600"
              lineHeight="40px"
            >
              Paramètres de diffusion
            </Text>
            <Box
              minHeight="300px"
              borderRadius="20px"
              bg="white"
              padding="24px 24px"
            >
              {data?.publicationAllocation ? (
                <form onSubmit={onSubmit}>
                  <VStack spacing="24px" alignItems="flex-start">
                    <FormControl>
                      <FormLabel>Periode d’affichage</FormLabel>
                      <Grid
                        templateColumns="repeat(12, 1fr)"
                        gap={6}
                        rowGap={0}
                      >
                        <GridItem colSpan={5}>
                          <Input
                            type="datetime-local"
                            {...register("startDate")}
                            defaultValue={formatToLocalIsoDate(
                              data?.publicationAllocation?.startDate
                            )}
                          />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Flex justifyContent="center" height="100%">
                            <ArrowForwardIcon
                              color="gray.500"
                              alignSelf="center"
                            />
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={5}>
                          <Input
                            type="datetime-local"
                            {...register("endDate")}
                            defaultValue={formatToLocalIsoDate(
                              data?.publicationAllocation?.endDate
                            )}
                          />
                        </GridItem>
                        <GridItem colSpan={5}>
                          <FormHelperText>
                            Début (vide: maintenant)
                          </FormHelperText>
                        </GridItem>
                        <GridItem colSpan={5} colStart={8}>
                          <FormHelperText>Fin (vide: sans fin)</FormHelperText>
                        </GridItem>
                      </Grid>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Tranche Horaire Journalière</FormLabel>
                      <Alert
                        status="info"
                        marginY={"12px"}
                        borderRadius={"md"}
                        bg="orange.50"
                      >
                        <AlertIcon />
                        Cette tranche horaire définit l'heure de début et de fin
                        de diffusion chaque jour.
                      </Alert>
                      <Grid
                        templateColumns="repeat(12, 1fr)"
                        gap={6}
                        rowGap={0}
                      >
                        <GridItem colSpan={5}>
                          <HStack>
                            <Input
                              type="time"
                              defaultValue={
                                data?.publicationAllocation?.startTime
                              }
                              {...register("startTime")}
                            />
                            <IconButton
                              aria-label="Vider"
                              icon={<FiX />}
                              bg="white"
                              borderWidth={1}
                              border="gray.100"
                              onClick={() => setValue("startTime", null)}
                            />
                          </HStack>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Flex justifyContent="center" height="100%">
                            <ArrowForwardIcon
                              color="gray.500"
                              alignSelf="center"
                            />
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={5}>
                          <HStack>
                            <Input
                              type="time"
                              defaultValue={
                                data?.publicationAllocation?.endTime
                              }
                              {...register("endTime")}
                            />
                            <IconButton
                              aria-label="Vider"
                              icon={<FiX />}
                              bg="white"
                              borderWidth={1}
                              border="gray.100"
                              onClick={() => setValue("endTime", null)}
                            />
                          </HStack>
                        </GridItem>
                        <GridItem colSpan={5}>
                          <FormHelperText>
                            Début (vide: depuis le debut de la journée)
                          </FormHelperText>
                        </GridItem>
                        <GridItem colSpan={5} colStart={8}>
                          <FormHelperText>
                            Fin (vide: jusqu’à la fin de la journée)
                          </FormHelperText>
                        </GridItem>
                      </Grid>
                    </FormControl>
                    <FormControl>
                      <Checkbox
                        size="lg"
                        {...register("active")}
                        defaultChecked={data?.publicationAllocation?.active}
                      >
                        Active ?
                      </Checkbox>
                    </FormControl>
                    <Button
                      leftIcon={isUpdating ? <Spinner /> : <FiSave />}
                      type="submit"
                      colorScheme={"orange"}
                    >
                      Enregistrer
                    </Button>
                  </VStack>
                </form>
              ) : (
                <Spinner />
              )}
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={2}>
          <VStack alignItems="flex-start">
            <Flex justifyContent="space-between" width="100%">
              <Text
                fontSize="md"
                fontWeight="bold"
                color="gray.600"
                lineHeight="40px"
              >
                Allocations
              </Text>
              <>
                <Button variant="outline" bg="white" onClick={onOpen}>
                  Ajouter
                </Button>
                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  scrollBehavior="inside"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Ajouter des écrans</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody bg="gray.100" p="24px">
                      {data?.publicationAllocation && (
                        <AddDisplay
                          allocationId={allocationId}
                          refRatio={
                            data!.publicationAllocation!.publication!.width /
                            data!.publicationAllocation!.publication!.height
                          }
                        />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            </Flex>

            <VStack divider={<Divider />} alignItems="flex-start" width="100%">
              {data?.publicationAllocation?.displayGroup.edges.map(
                (displayGroup, index) =>
                  displayGroup?.node && (
                    <DisplayGroupCard
                      key={"display-group-card-" + index}
                      displayGroup={displayGroup?.node as DisplayGroupNode}
                      width="100%"
                      minHeight="50px"
                      refRatio={
                        data?.publicationAllocation!.publication!.width /
                        data?.publicationAllocation!.publication!.height
                      }
                      actionButton={
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() =>
                            removeDisplayGroup({
                              variables: {
                                id: allocationId,
                                displayGroupId: displayGroup?.node!.id,
                              },
                            })
                          }
                        >
                          Retirer
                        </Button>
                      }
                    />
                  )
              )}
              {data?.publicationAllocation?.display.edges.length && (
                <Box
                  padding="14px 24px "
                  bg="white"
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderRadius="12px"
                >
                  <VStack alignItems="flex-start">
                    <Text fontSize="sm" fontWeight="bold" color="gray.700">
                      Ecrans
                    </Text>
                    <VStack alignItems="flex-start" divider={<Divider />}>
                      {data?.publicationAllocation?.display.edges.map(
                        (display, index) => {
                          return (
                            display?.node && (
                              <HStack spacing="4" width="100%">
                                <DisplayItem
                                  key={"display-" + index}
                                  name={display?.node?.computer.name || ""}
                                  refRatio={
                                    data?.publicationAllocation!.publication!
                                      .width /
                                    data?.publicationAllocation!.publication!
                                      .height
                                  }
                                  display={display?.node as DisplayNode}
                                />
                                <IconButton
                                  aria-label="Remove Display"
                                  size="sm"
                                  icon={<DeleteIcon />}
                                  variant="outline"
                                  onClick={() =>
                                    removeDisplay({
                                      variables: {
                                        id: allocationId,
                                        displayId: display?.node!.id,
                                      },
                                    })
                                  }
                                >
                                  Retirer
                                </IconButton>
                              </HStack>
                            )
                          );
                        }
                      )}
                    </VStack>
                  </VStack>
                </Box>
              )}
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </VStack>
  );
};
