import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Center,
  Divider,
  Flex,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { FunctionComponent } from "react";
import { DisplayGroupNode, DisplayNode } from "../../generated/graphql";

type DisplayGroupCardProps = {
  displayGroup: DisplayGroupNode;
  actionButton?: React.ReactNode | null;
  refRatio?: number | null;
} & BoxProps;

type DisplayItemProps = {
  name: string;
  display: DisplayNode;
  refRatio?: number | null;
};
export const DisplayItem: FunctionComponent<DisplayItemProps> = ({
  name,
  display,
  refRatio,
}) => {
  const ratio = display.widthPx / display.heightPx;
  const warning =
    refRatio && (ratio > refRatio * 1.2 || ratio < refRatio * 0.8);
  const baseColor = warning ? "orange" : "gray";
  return (
    <SimpleGrid columns={3} spacing={10} width="100%">
      <Box>
        <Center>
          <Flex
            height="25px"
            width={((display.widthPx || 1) * 25) / (display.heightPx || 1)}
            borderRadius="3px"
            bg={`${baseColor}.200`}
            borderWidth="1px"
            borderColor={`${baseColor}.300`}
            alignItems="center"
            justifyContent="center"
          >
            {warning && (
              <Tooltip
                label={`Difference de ratio ecran (${ratio.toFixed(
                  2
                )}) vs publication (${refRatio!.toFixed(2)}) > 20%`}
                fontSize="md"
              >
                <WarningTwoIcon color={`${baseColor}.400`} />
              </Tooltip>
            )}
          </Flex>
        </Center>
      </Box>
      <Box>
        <Text fontSize="md" color={`${baseColor}.600`}>
          {name}
        </Text>
      </Box>
      <Box>
        <Text fontSize="md" color={`${baseColor}.400`}>
          {display.widthPx} x {display.heightPx}
        </Text>
      </Box>
    </SimpleGrid>
  );
};

export const DisplayGroupCard: FunctionComponent<DisplayGroupCardProps> = ({
  displayGroup,
  actionButton,
  refRatio,
  ...boxProps
}) => {
  return (
    <Box
      padding="14px 24px "
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="12px"
      {...boxProps}
    >
      <VStack alignItems="flex-start">
        <Flex justifyContent="space-between" width="100%">
          <Text fontSize="sm" fontWeight="bold" color="gray.700">
            {displayGroup.name}
          </Text>
          {actionButton}
        </Flex>
        <VStack alignItems="flex-start" divider={<Divider />}>
          {displayGroup.displaycomputerSet.edges.flatMap((computer) =>
            computer?.node?.displaySet.edges.map((display) => (
              <DisplayItem
                key={computer.node!.id}
                name={computer.node!.name}
                refRatio={refRatio}
                display={display?.node as DisplayNode}
              />
            ))
          )}
        </VStack>
      </VStack>
    </Box>
  );
};
