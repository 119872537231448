import { FunctionComponent } from "react";
import { Icon } from "@chakra-ui/icons";
import { useMediaQuery } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { MenuBar, MenuBarItemType } from "../components/MenuBar";
import { HeaderSideMenuContent } from "../layouts/HeaderSideMenuContent";
import { HeaderContentBottomBar } from "../layouts/HeaderContentBottomBar";
import { FiMail, FiUsers } from "react-icons/fi";
import { ScreenIcon, PublicationIcon } from "../components/CustomIcons";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { DisplaysPage } from "../pages/Displays";
import { PublicationsPage } from "../pages/Publications";
import { CustomerContext, UserContext } from "./NavigationContext";
import { UsersPage } from "../pages/Users";
import { ContactPage } from "../pages/Contact";
import { Statistics } from "../pages/Statistics";
import {
  useGetCustomersSoftwareVersionsQuery,
  useGetCustomerVersionQuery,
  useGetMeQuery,
  UserDataFragment,
} from "../generated/graphql";
import { handleVersion } from "../libs/versionCookie";

type CustomerBackOfficeParams = {
  customerId: string;
};
export const CustomerBackOfficeScreen: FunctionComponent = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { data: me } = useGetMeQuery();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const { customerId } = useParams<CustomerBackOfficeParams>();

  const selectedCustomer = me?.me?.customers.edges.find(
    (edge) => edge?.node?.id === customerId
  );

  const { data: customerSoftwareVersion } = useGetCustomerVersionQuery({
    variables: {
      id: customerId,
    },
  });

  const customerVersion =
    customerSoftwareVersion?.customer?.backofficeVersion?.number;
  if (!!customerVersion) {
    console.log("customerVersion", customerVersion, selectedCustomer);
    handleVersion(customerVersion.toString());
  }
  const menubarItems: MenuBarItemType[] = [
    {
      id: "screens",
      text: "Ecrans",
      icon: ScreenIcon,
      active:
        location.pathname.startsWith(`${match.url}/screens`) ||
        location.pathname === match.url,
      onSelect: (id) => history.push(`${match.url}/screens`),
    },
    {
      id: "publications",
      text: "Publi",
      icon: PublicationIcon,
      active: location.pathname.startsWith(`${match.url}/publications`),
      onSelect: (id) => history.push(`${match.url}/publications`),
    },
    // {
    //   id: "stats",
    //   text: "Statistics",
    //   icon: (props) => <Icon as={FiBarChart2} {...props} />,
    //   active: location.pathname.startsWith(`${match.url}/stats`),
    //   onSelect: (id) => history.push(`${match.url}/stats`),
    // },
    {
      id: "users",
      text: "Utilisateurs",
      icon: (props) => <Icon as={FiUsers} {...props} />,
      active: location.pathname.startsWith(`${match.url}/users`),
      onSelect: (id) => history.push(`${match.url}/users`),
    },
    // {
    //   id: "aide",
    //   text: "Documentation",
    //   icon: (props) => <Icon as={FiBookOpen} {...props} />,
    //   active: location.pathname.startsWith(`${match.url}/helps`),
    //   onSelect: (id) => history.push(`${match.url}/helps`),
    // },
    {
      id: "contact",
      text: "Contact",
      icon: (props) => <Icon as={FiMail} {...props} />,
      active: location.pathname.startsWith(`${match.url}/contact`),
      onSelect: (id) => history.push(`${match.url}/contact`),
    },
  ];
  const Layout = isLargerThan1080
    ? HeaderSideMenuContent
    : HeaderContentBottomBar;
  return (
    <CustomerContext.Provider value={customerId}>
      <UserContext.Provider value={me?.me as UserDataFragment}>
        <Layout
          header={<Header />}
          bar={
            <MenuBar
              direction={isLargerThan1080 ? "column" : "row"}
              items={menubarItems}
            />
          }
        >
          <Switch>
            <Route path={`${match.url}/screens`} component={DisplaysPage} />
            <Route
              path={`${match.url}/publications`}
              component={PublicationsPage}
            />
            {/* <Route path={`${match.url}/medias`} component={MediaPage} /> */}
            <Route path={`${match.url}/users`} component={UsersPage} />
            <Route path={`${match.url}/contact`} component={ContactPage} />
            <Route path={`${match.url}/stats`} component={Statistics} />
            <Route path={`${match.url}/`} exact>
              <Redirect to={`${match.url}/screens`} />
            </Route>
          </Switch>
        </Layout>
      </UserContext.Provider>
    </CustomerContext.Provider>
  );
};
