import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";

import { Input } from "@chakra-ui/input";
import { CircularProgress } from "@chakra-ui/progress";
import { VStack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";

import { useState } from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN } from "../../api";
import { useGetTokenAuthMutation } from "../../generated/graphql";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [getTokenAuth] = useGetTokenAuthMutation({
    errorPolicy: "all",
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const authPayload = await getTokenAuth({
      variables: { email, password },
    });
    if (authPayload.data?.tokenAuth) {
      localStorage.setItem(AUTH_TOKEN, authPayload.data.tokenAuth.token);
      window.location.href = "/";
    } else {
      if (Array.isArray(authPayload.errors)) {
        toast({
          title: "Email or password is wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: "Unable to connect.",
          status: "error",
          isClosable: true,
        });
      }

      setIsLoading(false);
      setPassword("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing="6" alignItems="flex-start">
        <FormControl isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            type={"email"}
            name="email"
            placeholder="your@email.com"
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            type="password"
            name="password"
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
        </FormControl>
        <Link to="/login/forgotten-password">Mot de passe oublié</Link>
        <Button width="full" mt={4} colorScheme="orange" type="submit">
          {isLoading ? (
            <CircularProgress isIndeterminate size="24px" color="white" />
          ) : (
            "Log In"
          )}
        </Button>
      </VStack>
    </form>
  );
};
