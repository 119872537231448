import {
  Badge,
  Box,
  ComponentWithAs,
  HStack,
  Icon,
  IconProps,
} from "@chakra-ui/react";
import { FC } from "react";
import { FiAlertTriangle, FiMicOff, FiMoon, FiThumbsUp } from "react-icons/fi";
import {
  MdCancelPresentation,
  MdSignalCellularAlt,
  MdSignalCellularConnectedNoInternet0Bar,
} from "react-icons/md";
import {
  DisplayComputerListItemFragment,
  DisplayStatusType,
} from "../generated/graphql";

type DisplayStatusBadgeProps = {
  computer: DisplayComputerListItemFragment;
};

type DisplayStatusProps = {
  name: string;
  color: string;
  icon: ComponentWithAs<"svg", IconProps>;
};

const DisplayStatusValues: { [key in DisplayStatusType]: DisplayStatusProps } =
  {
    [DisplayStatusType.Disabled]: {
      name: "Désactivé",
      color: "gray",
      icon: (props: IconProps) => <Icon as={FiMicOff} {...props} />,
    },
    [DisplayStatusType.Standby]: {
      name: "En Attente",
      color: "purple",
      icon: (props: IconProps) => <Icon as={FiMoon} {...props} />,
    },
    [DisplayStatusType.Empty]: {
      name: "Vide",
      color: "orange",
      icon: (props: IconProps) => <Icon as={MdCancelPresentation} {...props} />,
    },
    [DisplayStatusType.Disconnected]: {
      name: "Deconnecté",
      color: "blue",
      icon: (props: IconProps) => (
        <Icon as={MdSignalCellularConnectedNoInternet0Bar} {...props} />
      ),
    },
    [DisplayStatusType.Connected]: {
      name: "Connecté",
      color: "blue",
      icon: (props: IconProps) => <Icon as={MdSignalCellularAlt} {...props} />,
    },
    [DisplayStatusType.Live]: {
      name: "Live",
      color: "green",
      icon: (props: IconProps) => <Icon as={FiThumbsUp} {...props} />,
    },
    [DisplayStatusType.Error]: {
      name: "Vérifier !",
      color: "red",
      icon: (props: IconProps) => <Icon as={FiAlertTriangle} {...props} />,
    },
  };

export const DisplayStatusBadge: FC<DisplayStatusBadgeProps> = ({
  computer,
}) => {
  if (computer.status === null || computer.status === undefined) {
    return <Badge colorScheme={"gray"}>Unknown</Badge>;
  }
  const { name, color, icon } = DisplayStatusValues[computer.status];
  const ItemIcon = icon;
  return (
    <Badge colorScheme={color} verticalAlign="middle">
      <HStack>
        <ItemIcon /> <Box verticalAlign={"middle"}>{name}</Box>
      </HStack>
    </Badge>
  );
};
