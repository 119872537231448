import { FunctionComponent } from "react";
import { BreadcrumbItem, BreadcrumbLink, Breadcrumb } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

type BreadcrumbItemType = {
  name: string;
  url?: string;
  component?: () => JSX.Element;
};

type BreadcrumbProps = {
  items: BreadcrumbItemType[];
};

export const DataBreadcrumb: FunctionComponent<BreadcrumbProps> = ({
  items,
}) => {
  return (
    <Breadcrumb
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
      lineHeight="38px"
    >
      {items.map((item) => (
        <BreadcrumbItem key={item.name + "-bc"} isCurrentPage={!item.url}>
          {item.component ? (
            item.component()
          ) : (
            <BreadcrumbLink
              as={item.url ? Link : undefined}
              to={item.url || ""}
            >
              {item.name}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
