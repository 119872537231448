import { IconProps } from "@chakra-ui/icon";
import { Box, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { ComponentWithAs } from "@chakra-ui/system";
import { FunctionComponent } from "react";

export type MenuBarItemType = {
  id: string;
  icon: ComponentWithAs<"svg", IconProps>;
  text: string;
  active: boolean;
  direction?: "row" | "column";
  onSelect: (id: string) => void;
};

const MenuBarItem: FunctionComponent<MenuBarItemType> = ({
  id,
  icon,
  text,
  active,
  direction,
  onSelect,
}) => {
  const ItemIcon = icon;
  const borders =
    direction === "column"
      ? {
          borderLeft: "4px solid",
          borderLeftColor: active ? "orange.500" : "gray.100",
        }
      : {
          borderBottom: "4px solid",
          borderBottomColor: active ? "orange.500" : "gray.100",
        };
  return (
    <Box
      key={id}
      p="18px"
      cursor="pointer"
      _hover={{
        backgroundColor: active ? "orange.100" : "gray.100",
      }}
      {...borders}
      onClick={() => onSelect(id)}
    >
      <VStack spacing={0}>
        <ItemIcon color={active ? "orange.500" : "gray.500"} boxSize={"24px"} />
        <Text
          fontSize="xs"
          color={active ? "orange.500" : "gray.500"}
          fontWeight="bold"
          letterSpacing="-0.05em"
        >
          {text}
        </Text>
      </VStack>
    </Box>
  );
};

type MenuBarProps = {
  direction: "row" | "column";
  items: MenuBarItemType[];
};
export const MenuBar: FunctionComponent<MenuBarProps> = ({
  items,
  direction,
}) => {
  return (
    <SimpleGrid columns={[direction === "row" ? items.length : 1]}>
      {items.map((item, index) => (
        <MenuBarItem key={"item-" + index} {...item} direction={direction} />
      ))}
    </SimpleGrid>
  );
};
