import { FC } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import {
  DisplayComputerListItemFragment,
  useDeleteDisplayComputerMutation,
} from "../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import { ConfirmModal } from "../../components/ConfirmModal";

type DisplayMenuProps = {
  computer: DisplayComputerListItemFragment;
};

export const DisplayMenu: FC<DisplayMenuProps> = ({ computer }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const [deleteDisplay] = useDeleteDisplayComputerMutation({
    refetchQueries: ["GetDisplayComputers"],
    onError: (error) =>
      toast({
        title: "erreur lors de la suppression.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        rounded={100}
        aria-label="Options"
        icon={<AiOutlineEllipsis />}
        variant="outline"
      />
      <MenuList>
        <MenuItem
          icon={<FiEdit2 />}
          onClick={() =>
            history.push(`${location.pathname}/display-computer/${computer.id}`)
          }
        >
          Modifier
        </MenuItem>
        <MenuItem icon={<FiTrash2 />} onClick={onOpen}>
          <ConfirmModal
            bodyText={`Voulez-vous supprimer ${computer.name} ?`}
            confirmButtonText="Supprimer"
            onConfirm={async () => {
              onClose();
              const { errors } = await deleteDisplay({
                variables: { id: computer.id },
              });
              if (errors && errors.length > 0) {
                toast({
                  title: "erreur lors de la mise à jour.",
                  status: "error",
                  description: errors[0].message,
                  isClosable: true,
                });
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
          Supprimer
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
