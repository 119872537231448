import { extendTheme, ThemeConfig } from "@chakra-ui/react";
// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const styles = {
  global: {
    // styles for the `body`
    body: {
      bg: "gray.50",
    },
  },
};
// 3. extend the theme
const theme = extendTheme({ config, styles });
export default theme;
