import { VStack, HStack, Box, Text } from "@chakra-ui/react";
import { AddUser } from "./AddUser";
import { CreateUser } from "./CreateUser";
import { UserTable } from "./UserTable";

export const UserList = () => {
  return (
    <VStack alignItems="flex-start">
      <HStack spacing="32px">
        <Box p="0px 16px">
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="gray.700"
            lineHeight="40px"
          >
            Utilisateurs
          </Text>
        </Box>
        <HStack>
          <CreateUser />
          <AddUser />
        </HStack>
      </HStack>
      <UserTable />
    </VStack>
  );
};
