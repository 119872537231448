import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  HStack,
  VStack,
  Select,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useState } from "react";
import { UserProfile } from "../../generated/graphql";

export type UserFormFields = {
  email: string;
  firstName: string;
  lastName: string;
  profile: UserProfile;
};

type UserFormProps = {
  create?: boolean;
  defaultValues?: UserFormFields;
  handleSubmit: (fields: UserFormFields) => void;
};

export const UserForm: FunctionComponent<UserFormProps> = ({
  create,
  defaultValues,
  handleSubmit,
}) => {
  const [userEmail, setUserEmail] = useState(defaultValues?.email || "");
  const [firstName, setFirstName] = useState(defaultValues?.firstName || "");
  const [lastName, setLastName] = useState(defaultValues?.lastName || "");
  const [selectedUserProfile, selectUserProfile] = useState<UserProfile | null>(
    defaultValues?.profile || null
  );

  return (
    <form
      id="user-form"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit({
          email: userEmail || "",
          firstName: firstName,
          lastName: lastName,
          profile: selectedUserProfile || UserProfile.ContentManager,
        });
      }}
    >
      <VStack spacing="6">
        <FormControl id="email" isRequired>
          <FormLabel>Adresse email</FormLabel>
          <Input
            type="email"
            defaultValue={defaultValues?.email}
            disabled={!create}
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            placeholder="michel.dupont@gmail.com"
          />
          <FormHelperText>
            Envoie un email avec les informations de connexion.
          </FormHelperText>
        </FormControl>

        <FormControl id="name" isRequired>
          <FormLabel>Nom</FormLabel>
          <HStack>
            <Input
              defaultValue={defaultValues?.firstName}
              type="text"
              onChange={(e) => setFirstName(e.currentTarget.value)}
              placeholder="Michel"
            />
            <Input
              defaultValue={defaultValues?.lastName}
              type="text"
              onChange={(e) => setLastName(e.currentTarget.value)}
              placeholder="Dupont"
            />
          </HStack>
        </FormControl>

        <FormControl id="profile" isRequired>
          <FormLabel>Droits d'accès</FormLabel>
          <Select
            placeholder="Select option"
            defaultValue={defaultValues?.profile}
            onChange={(e) =>
              selectUserProfile(e.currentTarget.value as UserProfile)
            }
          >
            <option value={UserProfile.Admin}>{UserProfile.Admin}</option>
            <option value={UserProfile.ParkManager}>
              {UserProfile.ParkManager}
            </option>
            <option value={UserProfile.ContentManager}>
              {UserProfile.ContentManager}
            </option>
          </Select>
        </FormControl>
      </VStack>
    </form>
  );
};
