import { Image } from "@chakra-ui/react";
import { WidgetMediaNode } from "../../../generated/graphql";
import { parseMediaMetaData } from "../metaData";

export const ImagePreview = (widgetMedia: WidgetMediaNode) => {
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  return (
    <Image
      key={widgetMedia.id}
      width="100%"
      height="100%"
      draggable={false}
      objectFit={
        (mediaMetaData.object_fit || "fill") as "fill" | "contain" | "cover"
      }
      src={widgetMedia.mediaContent.signedUrl || ""}
    />
  );
};
