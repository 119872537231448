import {
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { FiInfo } from "react-icons/fi";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { DataTable } from "../../components/DataTable";
import { DisplayStatusBadge } from "../../components/DisplayStatusBadge";
import {
  DisplayComputerMonitoringFragment,
  useGetDisplayComputersStatusQuery,
} from "../../generated/graphql";
import { compareComputerStatus } from "../../libs/displayStatus";
import { ComputerDetail } from "./ComputerDetail";

type ComputerNameCellProps = {
  computer: DisplayComputerMonitoringFragment;
};

const ComputerNameCell: FC<ComputerNameCellProps> = ({ computer }) => {
  const match = useRouteMatch();
  return (
    <>
      <Link to={`${match.url}/display-computer/${computer.id}`}>
        <Text color="gray.700" fontSize="large" fontWeight="bold">
          {computer?.name}
        </Text>
      </Link>
      <Text color="gray.500" fontSize="medium">
        {computer.customer.name}
      </Text>
    </>
  );
};
type DisplayMonitoringPageParams = {
  selectedComputer?: string;
};
export const DisplayMonitoringPage = () => {
  const { data } = useGetDisplayComputersStatusQuery();
  const match = useRouteMatch();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const { selectedComputer } = useParams<DisplayMonitoringPageParams>();
  const history = useHistory();

  const compareStatus = useMemo(
    () => (rowA: any, rowB: any) => {
      return compareComputerStatus(rowA.original.status, rowB.original.status);
    },
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Composer",
        id: "composer",
        accessor: (row: DisplayComputerMonitoringFragment) => row.customer.name,
      },
      {
        Header: "Name",
        accessor: "name",
        ...(isLargerThan1080
          ? {}
          : {
              Cell: (row: any) => (
                <ComputerNameCell
                  computer={
                    row.row.original as DisplayComputerMonitoringFragment
                  }
                />
              ),
            }),
      },
      {
        Header: "AnyDesk",
        id: "anydesk",
        accessor: (row: DisplayComputerMonitoringFragment) =>
          row.displaycomputeradminfields?.anydesk,
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: compareStatus,
        Cell: (row: any) => {
          console.log(row);
          return <DisplayStatusBadge computer={row.row.original} />;
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: (row: any) => (
          <IconButton
            icon={<FiInfo />}
            aria-label="Info"
            onClick={() => history.push(`${match.url}/${row.row.original.id}`)}
          />
        ),
      },
    ],
    [compareStatus, history, isLargerThan1080, match.url]
  );
  const initialState = useMemo(
    () => ({
      pageSize: 25,
      hiddenColumns: isLargerThan1080 ? [] : ["composer"],
    }),
    [isLargerThan1080]
  );

  const tableData = useMemo(
    () => data?.displayComputers?.edges.map((edge) => edge?.node) || [],
    [data]
  );

  return (
    <Flex
      direction="column"
      padding={isLargerThan1080 ? "32px" : "8px"}
      width="100%"
    >
      <VStack spacing="8px" alignItems="flex-start">
        <Text fontSize="md" color="gray.700" lineHeight="32px">
          Status écrans
        </Text>
        <Grid
          h="200px"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap={"12px"}
        >
          <GridItem bg="white" borderRadius={"md"} colSpan={3}>
            <DataTable
              data={tableData}
              columns={columns}
              initialState={initialState}
            />
          </GridItem>
          {selectedComputer && (
            <GridItem bg="white" borderRadius={"md"} height="100%" colSpan={2}>
              <ComputerDetail computerId={selectedComputer} />
            </GridItem>
          )}
        </Grid>
      </VStack>
    </Flex>
  );
};
