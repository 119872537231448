import { FunctionComponent, useContext, useState } from "react";
import {
  Textarea,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  Spinner,
  Input,
  VStack,
  Box,
  HStack,
  Button,
  IconButton,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useToast,
  Checkbox,
  Wrap,
} from "@chakra-ui/react";
import { FiSave, FiKey, FiTrash2 } from "react-icons/fi";
import { GiHorizontalFlip, GiVerticalFlip } from "react-icons/gi";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { LatLngPosition } from "../../components/Map";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import {
  useAddGroupToComputerMutation,
  useDeleteDisplayComputerMutation,
  useGetDisplayComputerQuery,
  useGetMeQuery,
  useRemoveGroupToComputerMutation,
  useUpdateDisplayComputerMutation,
  useUpdateDisplayMutation,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { NewKeyDialog } from "./NewKeyDialog";
import { SelectGroup } from "./SelectGroup";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AdminFieldsForm } from "./AdminFieldsForm";

type EditDisplayComputerProps = {
  setMapCenter: (position: LatLngPosition) => void;
  refRightPanel: React.MutableRefObject<HTMLElement | undefined>;
};

type EditDisplayComputerParams = {
  displayComputerId: string;
};

type Day = {
  value:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  label:
    | "Lundi"
    | "Mardi"
    | "Mercredi"
    | "Jeudi"
    | "Vendredi"
    | "Samedi"
    | "Dimanche";
};

const DAYS: Day[] = [
  {
    value: "monday",
    label: "Lundi",
  },
  {
    value: "tuesday",
    label: "Mardi",
  },
  {
    value: "wednesday",
    label: "Mercredi",
  },
  {
    value: "thursday",
    label: "Jeudi",
  },
  {
    value: "friday",
    label: "Vendredi",
  },
  {
    value: "saturday",
    label: "Samedi",
  },
  {
    value: "sunday",
    label: "Dimanche",
  },
];

export const EditDisplayComputer: FunctionComponent<
  EditDisplayComputerProps
> = ({ refRightPanel }) => {
  const { displayComputerId } = useParams<EditDisplayComputerParams>();
  const toast = useToast();
  const history = useHistory();
  const [updateDisplayComputer] = useUpdateDisplayComputerMutation({
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [updateDisplay] = useUpdateDisplayMutation({
    onError: (error) =>
      toast({
        title: "erreur lors de la mise à jour.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const [deleteDisplay] = useDeleteDisplayComputerMutation({
    onError: (error) =>
      toast({
        title: "erreur lors de la suppression.",
        status: "error",
        description: error.message,
        isClosable: true,
      }),
  });
  const { data } = useGetDisplayComputerQuery({
    variables: { id: displayComputerId || "" },
  });
  const [addGroup] = useAddGroupToComputerMutation({
    refetchQueries: ["GetDisplayComputer"],
  });
  const [removeGroup] = useRemoveGroupToComputerMutation({
    refetchQueries: ["GetDisplayComputer"],
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const customerId = useContext(CustomerContext);
  const { register, handleSubmit, control } = useForm();
  const displayComputer = data?.displayComputer;
  const display = displayComputer?.displaySet.edges[0]?.node;
  const { data: userData } = useGetMeQuery();

  if (!displayComputer || !display) {
    return <Spinner />;
  }

  const onSubmit = handleSubmit(async (formData) => {
    setIsUpdating(true);
    await updateDisplayComputer({
      variables: {
        input: {
          id: displayComputerId,
          name: formData.name,
          description: formData.description,
          isMuted: formData.isMuted,
          startTime: formData.startTime,
          endTime: formData.endTime,
          monday: formData.monday,
          tuesday: formData.tuesday,
          wednesday: formData.wednesday,
          thursday: formData.thursday,
          friday: formData.friday,
          saturday: formData.saturday,
          sunday: formData.sunday,
        },
      },
    });
    await updateDisplay({
      variables: {
        id: display.id,
        width: parseInt(formData.displayWidth),
        height: parseInt(formData.displayHeight),
      },
    });

    if (formData.groups) {
      // filter added groups
      formData.groups
        .filter(
          (selectedGroup: { value: string }) =>
            !displayComputer.groups.edges.find(
              (group) => group?.node?.id === selectedGroup.value
            )
        )
        .forEach((group: { value: string }) =>
          addGroup({
            variables: {
              computerId: displayComputerId,
              groupId: group.value,
            },
          })
        );
      // filter removed groups
      displayComputer.groups.edges
        .filter(
          (group) =>
            !formData.groups.find(
              (selectedGroup: { value: string }) =>
                selectedGroup.value === group?.node?.id
            )
        )
        .forEach((group) =>
          removeGroup({
            variables: {
              computerId: displayComputerId,
              groupId: group?.node?.id || "",
            },
          })
        );
    }

    setIsUpdating(false);
  });
  return (
    <Flex wrap="wrap" width="100%" style={{ gap: "12px" }}>
      <VStack
        alignItems="flex-start"
        maxHeight="100%"
        justifyContent="space-between"
        flexGrow={2}
      >
        <Flex justifyContent="space-between" width="100%">
          <DataBreadcrumb
            items={[
              {
                name: "Parc Ecrans",
                url: `/backoffice/${customerId}/screens`,
              },
              {
                name: `Ecran ${displayComputer.name}`,
              },
            ]}
          />
          {userData?.me?.isStaff && (
            <HStack>
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label={"Supprimer Ecran"}
                    icon={<FiTrash2 />}
                    color="gray.500"
                  ></IconButton>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Attention!</PopoverHeader>
                  <PopoverBody>
                    Etes-vous sûr de vouloir supprimer cette écran ?
                  </PopoverBody>
                  <Button
                    onClick={async () => {
                      const { errors } = await deleteDisplay({
                        variables: { id: displayComputerId },
                      });
                      if (errors && errors.length > 0) {
                        toast({
                          title: "erreur lors de la mise à jour.",
                          status: "error",
                          description: errors[0].message,
                          isClosable: true,
                        });
                      } else {
                        history.push(`/backoffice/${customerId}/screens`);
                      }
                    }}
                  >
                    Supprimer
                  </Button>
                </PopoverContent>
              </Popover>
              {display.displaycredential?.activated ? (
                <Popover>
                  <PopoverTrigger>
                    <Button
                      aria-label={"Réassigner une clé"}
                      title={"Réassigner une clé"}
                      leftIcon={<FiKey />}
                      color="gray.500"
                    >
                      Réassigner
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Attention!</PopoverHeader>
                    <PopoverBody>
                      Cette clé est déjà activé ! <br />
                      {displayComputer.lastConnection
                        ? "Derniere utilistation: " +
                          displayComputer.lastConnection
                        : "Mais l'écran n'a jamais été connecté."}{" "}
                      <br />
                      <NewKeyDialog
                        display={display}
                        computer={displayComputer}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : (
                <NewKeyDialog display={display} computer={displayComputer} />
              )}
            </HStack>
          )}
        </Flex>
        <Box
          borderRadius="12px"
          padding="18px"
          bg="white"
          width="100%"
          maxHeight="100%"
        >
          <form onSubmit={onSubmit}>
            <VStack alignItems="flex-start" spacing="4">
              <FormControl id="name" isRequired>
                <FormLabel>Nom PC</FormLabel>
                <Input
                  type="text"
                  bg="white"
                  {...register("name")}
                  defaultValue={displayComputer.name}
                />
              </FormControl>
              <FormControl id="horaires">
                <FormLabel>Horaires</FormLabel>
                <HStack>
                  <Input
                    type="time"
                    bg="white"
                    {...register("startTime")}
                    defaultValue={displayComputer.startTime}
                  />
                  <Flex justifyContent="center" height="100%">
                    <ArrowForwardIcon color="gray.500" alignSelf="center" />
                  </Flex>
                  <Input
                    type="time"
                    bg="white"
                    {...register("endTime")}
                    defaultValue={displayComputer.endTime}
                  />
                </HStack>
              </FormControl>
              <FormControl id="jours">
                <FormLabel>Jours</FormLabel>
                <Wrap spacing={"16px"}>
                  {DAYS.map((day: Day) => (
                    <Checkbox
                      key={day.value}
                      {...register(day.value)}
                      defaultIsChecked={displayComputer[day.value]}
                    >
                      {day.label}
                    </Checkbox>
                  ))}
                </Wrap>
              </FormControl>
              <FormControl id="groups">
                <FormLabel>Groupes</FormLabel>
                <Controller
                  name="groups"
                  control={control}
                  render={({ field }) => (
                    <SelectGroup
                      field={field}
                      displayComputer={displayComputer}
                    />
                  )}
                />
              </FormControl>
              <FormControl id="description">
                <FormLabel>Description</FormLabel>
                <Textarea
                  bg="white"
                  {...register("description")}
                  defaultValue={displayComputer.description || ""}
                />
              </FormControl>
              <FormControl id="resolution" isRequired>
                <FormLabel>Résolution Ecran</FormLabel>
                <HStack>
                  <InputGroup maxW="160px">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<GiHorizontalFlip />}
                      color="gray.500"
                    />
                    <Input
                      type="number"
                      {...register("displayWidth")}
                      defaultValue={display.widthPx}
                      bg="white"
                    />
                    <InputRightElement
                      pointerEvents="none"
                      children="px"
                      color="gray.500"
                    />
                  </InputGroup>
                  <Text color="gray.500">X</Text>
                  <InputGroup maxW="160px">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<GiVerticalFlip />}
                      color="gray.500"
                    />
                    <Input
                      type="number"
                      {...register("displayHeight")}
                      defaultValue={display.heightPx}
                      bg="white"
                    />
                    <InputRightElement
                      pointerEvents="none"
                      children="px"
                      color="gray.500"
                    />
                  </InputGroup>
                </HStack>
              </FormControl>
              <FormControl id="isMuted">
                <Checkbox
                  {...register("isMuted")}
                  defaultIsChecked={displayComputer.isMuted}
                >
                  Video Silencieuse
                </Checkbox>
              </FormControl>
              <Button
                leftIcon={isUpdating ? <Spinner /> : <FiSave />}
                type="submit"
                colorScheme="orange"
              >
                Enregistrer
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
      <AdminFieldsForm displayComputer={displayComputer} />
    </Flex>
  );
};
