import { Box } from "@chakra-ui/layout";
import { Route, Switch, useRouteMatch } from "react-router";
import { PublicationHomePage } from "./HomePage";
import { PublishList } from "./PublishList";
import { Suspense } from "react";
import { Spinner } from "@chakra-ui/react";
import { UpdatePublish } from "./UpdatePublish";
import { PublicationPage } from "./Publication";

export const PublicationsPage = () => {
  const match = useRouteMatch();
  return (
    <Box p="16px">
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={`${match.url}/`} exact component={PublicationHomePage} />
          <Route
            path={`${match.url}/publication/:publicationId`}
            exact
            component={PublicationPage}
          />
          <Route
            path={`${match.url}/publication/:publicationId/publish`}
            exact
            component={PublishList}
          />
          <Route
            path={`${match.url}/publication/:publicationId/publish/:allocationId/:index`}
            exact
            component={UpdatePublish}
          />
        </Switch>
      </Suspense>
    </Box>
  );
};
