import { Box, Text, VStack } from "@chakra-ui/layout";
import { ResponsivePie } from "@nivo/pie";

export const WeekPublicationsStats = () => {
  const data = [
    {
      id: "menu w17",
      label: "menu w17",
      value: 80,
      color: "hsl(212, 70%, 50%)",
    },
    {
      id: "pub16",
      label: "pub16",
      value: 174,
      color: "hsl(181, 70%, 50%)",
    },
    {
      id: "event 25_06",
      label: "event 25_06",
      value: 199,
      color: "hsl(249, 70%, 50%)",
    },
    {
      id: "event 14_07",
      label: "event 14_07",
      value: 538,
      color: "hsl(245, 70%, 50%)",
    },
    {
      id: "promo ouverture",
      label: "promo ouverture",
      value: 95,
      color: "hsl(67, 70%, 50%)",
    },
  ];
  return (
    <VStack justifyContent="flex-start" height="100%">
      <Text fontSize="md" fontWeight="bold" alignSelf="flex-start">
        Publications
      </Text>
      <Text fontSize="md" alignSelf="flex-start" color="gray.500">
        Nombre de minutes diffusées sur tous les ecrans pendant la semaine 19.
      </Text>
      <Box height="400px" width="400px">
        <ResponsivePie
          data={data}
          margin={{ top: 20, right: 100, bottom: 20, left: 100 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
            {
              match: {
                id: "go",
              },
              id: "dots",
            },
            {
              match: {
                id: "python",
              },
              id: "dots",
            },
            {
              match: {
                id: "scala",
              },
              id: "lines",
            },
            {
              match: {
                id: "lisp",
              },
              id: "lines",
            },
            {
              match: {
                id: "elixir",
              },
              id: "lines",
            },
            {
              match: {
                id: "javascript",
              },
              id: "lines",
            },
          ]}
          // legends={[
          //   {
          //     anchor: "bottom",
          //     direction: "row",
          //     justify: false,
          //     translateX: 0,
          //     translateY: 56,
          //     itemsSpacing: 0,
          //     itemWidth: 100,
          //     itemHeight: 18,
          //     itemTextColor: "#999",
          //     itemDirection: "left-to-right",
          //     itemOpacity: 1,
          //     symbolSize: 18,
          //     symbolShape: "circle",
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemTextColor: "#000",
          //         },
          //       },
          //     ],
          //   },
          // ]}
        />
      </Box>
    </VStack>
  );
};
