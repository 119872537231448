import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  IconButton,
  Image,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Avatar } from "@chakra-ui/avatar";
import { useGetCustomerQuery, useGetMeQuery } from "../generated/graphql";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useMediaQuery } from "@chakra-ui/react";
import { logout } from "../api";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { CustomerContext } from "../screens/NavigationContext";
import { SOFTWARE_VERSION } from "../version";

export const Header = () => {
  const { data } = useGetMeQuery();
  const history = useHistory();
  const customerId = useContext(CustomerContext);
  const customers = data?.me?.customers?.edges;
  const customerQuery = useGetCustomerQuery({
    variables: { id: customerId || "" },
  });
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const hasBackButton =
    (data?.me?.isStaff || (customers?.length || 0) > 1) && customerId;
  console.log("me...", data);
  return (
    <Flex
      flexDir="row"
      padding="37px 27px"
      flexGrow={1}
      justifyContent="space-between"
    >
      <HStack spacing="24px">
        {hasBackButton && (
          <IconButton
            aria-label="Back to home"
            icon={<ChevronLeftIcon />}
            onClick={() => history.push("/")}
          />
        )}
        <Image src="/brand.png" height="50px" />
        <Text fontSize="xl" color="gray.800" fontWeight="bold">
          {customerId ? customerQuery.data?.customer?.name : "GoVisuPro"}
        </Text>
      </HStack>
      <Box alignSelf="center" ml="50px">
        <HStack spacing="6px">
          <VStack spacing="-5px" alignItems="flex-end">
            {isLargerThan1080 && (
              <Text fontSize="sm" color="gray.500">
                BIENVENUE
              </Text>
            )}
            <Popover>
              <PopoverTrigger>
                {isLargerThan1080 ? (
                  <Button size="xs" variant="ghost">
                    {(data?.me?.firstName || data?.me?.lastName) && (
                      <Text fontSize="sm" color="gray.600">
                        {data.me.firstName} {data.me.lastName} ▼
                      </Text>
                    )}
                  </Button>
                ) : (
                  <Avatar
                    name={`${data?.me?.firstName} ${data?.me?.lastName}`}
                    cursor="pointer"
                  />
                )}
              </PopoverTrigger>
              <PopoverContent width="200px">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <VStack>
                    <Text>V{SOFTWARE_VERSION}</Text>
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() => {
                        logout();
                        document.location.reload();
                      }}
                    >
                      Se déconnecter
                    </Button>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </VStack>
          {isLargerThan1080 && (
            <Avatar name={`${data?.me?.firstName} ${data?.me?.lastName}`} />
          )}
        </HStack>
      </Box>
    </Flex>
  );
};
