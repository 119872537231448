import { Grid, GridItem, GridItemProps } from "@chakra-ui/react";
import { WeekDisplaysStats } from "./WeekDisplaysStats";
import { WeekPublicationsStats } from "./WeekPublicationsStats";

const GridCardStyle: GridItemProps = {
  borderRadius: "25px",
  padding: "36px",
  bg: "white",
};

export const Statistics = () => {
  return (
    <div>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem colSpan={3} {...GridCardStyle}>
          <WeekDisplaysStats />
        </GridItem>
        <GridItem colSpan={2} {...GridCardStyle}>
          <WeekPublicationsStats />
        </GridItem>
      </Grid>
    </div>
  );
};
