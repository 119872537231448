import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import {
  UserNode,
  UserProfile,
  useUpdateUserMutation,
} from "../../generated/graphql";
import { UserForm } from "./UserForm";

type UpdateUserProps = {
  user: UserNode;
};

export const UpdateUser: FunctionComponent<UpdateUserProps> = ({ user }) => {
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: ["GetCustomerUsers"],
    onError: (error) => console.log(error),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  return (
    <>
      <Button onClick={onOpen}>Modifier</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier un utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserForm
              defaultValues={{
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                profile: user.profile || UserProfile.ContentManager,
              }}
              handleSubmit={(fields) => {
                updateUser({
                  variables: {
                    id: user.id,
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    profile: fields.profile,
                  },
                }).then((result) => {
                  result.errors
                    ? toast({
                        title: "erreur lors de la mise à jour.",
                        status: "error",
                        description: (result.errors as any).message,
                        isClosable: true,
                      })
                    : onClose();
                });
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button type="submit" colorScheme="orange" form="user-form">
              Modifier
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
