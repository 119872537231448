import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { client } from "./api";
import theme from "./theme";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { Routes } from "./Routes";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <Routes />
        </Router>
      </ApolloProvider>
    </ChakraProvider>
  );
}

export default App;
