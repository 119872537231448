import { Box, Button, Flex, HStack, Spinner, VStack } from "@chakra-ui/react";
import { HeaderToolbar, LayoutItemType, PageMetaData } from "@govisupro/studio";
import { LayoutItemAnimations } from "@govisupro/studio/dist/studio/types";
import { Suspense, useContext, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { DataBreadcrumb } from "../../components/Breadcrumb";
import {
  PublicationNode,
  useCreatePublicationScreenMutation,
  useDeletePublicationScreenMutation,
  useGetPublicationQuery,
  useUpdatePublicationMutation,
} from "../../generated/graphql";
import { CustomerContext } from "../../screens/NavigationContext";
import { EditableName } from "./EditableName";
import { LayoutItemFragmentToType } from "./helpers";
import { UpdatePublication } from "./UpdatePublication";

type PublicationParams = {
  publicationId: string;
};

export const PublicationPage = () => {
  const { publicationId } = useParams<PublicationParams>();
  const customerId = useContext(CustomerContext);
  const { data, refetch } = useGetPublicationQuery({
    variables: { publicationId: publicationId || "" },
    pollInterval: 60 * 1000,
  });

  const [createPublicationScreen] = useCreatePublicationScreenMutation({
    refetchQueries: ["GetPublication"],
  });
  const [updatePublication] = useUpdatePublicationMutation({
    refetchQueries: ["GetPublication"],
  });
  const [deletePublicationScreen] = useDeletePublicationScreenMutation({
    refetchQueries: ["GetPublication"],
  });

  const history = useHistory();
  const match = useRouteMatch();
  const [selectedScreenIndex, setSelectedScreenIndex] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [showAnimations, setShowAnimations] = useState<boolean>(false);

  const publication = data?.publication;
  if (!publication) {
    return <Spinner />;
  }
  const selectedScreen =
    publication.publicationscreenSet.edges[selectedScreenIndex];
  const screens = publication.publicationscreenSet.edges || [];
  const pageList: PageMetaData[] = screens.map((screen, index) => ({
    index,
    isSelected: index === selectedScreenIndex,
    thumbnailUrl: screen?.node?.thumbnailUrl || "",
  }));

  const handleAnimationPlay = () => {
    const animationTimeout: number = Math.max(
      ...(screens[selectedScreenIndex]?.node?.layoutitemSet?.edges.map(
        (edge) => {
          const animation = JSON.parse(
            edge?.node?.animations
          ) as LayoutItemAnimations;
          return (
            (animation?.apparition.delay ?? 0) +
            (animation?.apparition.duration ?? 0)
          );
        }
      ) ?? [])
    );

    setShowAnimations(true);

    setTimeout(() => {
      setShowAnimations(false);
    }, animationTimeout * 1000);
  };

  const layoutItems: LayoutItemType[] =
    screens[selectedScreenIndex]?.node?.layoutitemSet?.edges
      .map((edge) => edge?.node && LayoutItemFragmentToType(edge!.node))
      .filter((item): item is LayoutItemType => !!item) ?? [];

  const noAnimation = layoutItems.every(
    (item) => (item.animations?.apparition.type ?? "none") === "none"
  );

  // console.log(selectedScreen?.node);
  return (
    <Suspense fallback={<Spinner />}>
      <VStack alignItems="flex-start" spacing="12px">
        <Flex justifyContent="space-between" width="100%">
          <DataBreadcrumb
            items={[
              {
                name: "Publications",
                url: `/backoffice/${customerId}/publications`,
              },
              {
                name: publication.name,
                component: () =>
                  data?.publication ? (
                    <EditableName
                      publication={data.publication as PublicationNode}
                    />
                  ) : (
                    <div></div>
                  ),
              },
            ]}
          />

          <HStack>
            <HeaderToolbar
              height={publication.height}
              width={publication.width}
              isLocked={isLocked}
              onChangeLock={setIsLocked}
              onHeightChange={(height: number) => {
                updatePublication({
                  variables: {
                    id: publication.id,
                    height: height,
                  },
                });
              }}
              onWidthChange={(width: number) => {
                updatePublication({
                  variables: {
                    id: publication.id,
                    width: width,
                  },
                });
              }}
              showAnimations={showAnimations}
              handleAnimationPlay={handleAnimationPlay}
              noAnimation={noAnimation}
            />
            <Button
              variant="outline"
              bg="white"
              onClick={() => history.push(`${match.url}/publish`)}
            >
              Diffuser
            </Button>
          </HStack>
        </Flex>
        <Box flexGrow={1} marginLeft="12px">
          {selectedScreen?.node && (
            <UpdatePublication
              publication={publication}
              screen={selectedScreen.node}
              isLocked={isLocked}
              pageList={pageList}
              showAnimations={showAnimations}
              onSelectPage={(index) => setSelectedScreenIndex(index)}
              onCreatePage={() =>
                createPublicationScreen({
                  variables: {
                    publicationId: publicationId || "",
                  },
                })
              }
              onDeletePage={(index) => {
                deletePublicationScreen({
                  variables: {
                    id: screens[index]?.node?.id || "",
                  },
                });
                refetch();
              }}
              onDuplicatePage={(index) => null}
              onEditPage={(index) => null}
            />
          )}
        </Box>
      </VStack>
    </Suspense>
  );
};
