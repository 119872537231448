import { VStack, HStack, Text, IconButton, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { useHistory } from "react-router";
import { AiOutlineCheck, AiOutlineStar } from "react-icons/ai";
import { BsMoonStars } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import {
  CustomerListItemFragment,
  DisplayStatusType,
} from "../generated/graphql";

type CustomerCardProps = {
  customer: CustomerListItemFragment;
};

export const CustomerCard: FC<CustomerCardProps> = ({ customer }) => {
  const history = useHistory();
  const onlinePlayers = customer.displaycomputerSet.edges.reduce(
    (acc, c) =>
      c?.node?.status === DisplayStatusType.Live ||
      c?.node?.status === DisplayStatusType.Connected
        ? acc + 1
        : acc,
    0
  );
  const offlinePlayers = customer.displaycomputerSet.edges.reduce(
    (acc, c) =>
      c?.node?.status === DisplayStatusType.Disconnected ||
      c?.node?.status === DisplayStatusType.Error
        ? acc + 1
        : acc,
    0
  );
  const sleepingPlayers = customer.displaycomputerSet.edges.reduce(
    (acc, c) => (c?.node?.status === DisplayStatusType.Standby ? acc + 1 : acc),
    0
  );
  return (
    <VStack
      w="300px"
      h="128px"
      bg="white"
      p="16px"
      borderRadius="12px"
      alignItems="flex-start"
      spacing="0"
    >
      <Flex direction="row" width="100%" alignItems="baseline">
        <Text
          fontWeight="bold"
          fontSize="md"
          color="gray.700"
          lineHeight="53px"
          flexGrow={1}
          isTruncated
          cursor="pointer"
          onClick={() => history.push(`/backoffice/${customer.id}`)}
        >
          {customer.name}
        </Text>
        <IconButton
          icon={<AiOutlineStar />}
          aria-label="star"
          bg="white"
        ></IconButton>
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <VStack spacing="0">
          <HStack>
            <AiOutlineCheck color="#48BB78" />
            <Text fontSize="sm" color="gray.500">
              Online
            </Text>
          </HStack>
          <Text fontSize="lg" fontWeight="bold" color="gray.500">
            {onlinePlayers}
          </Text>
        </VStack>
        <VStack spacing="0">
          <HStack>
            <AiOutlineWarning color="#ED8936" />
            <Text fontSize="sm" color="gray.500">
              Offline
            </Text>
          </HStack>
          <Text fontSize="lg" fontWeight="bold" color="gray.500">
            {offlinePlayers}
          </Text>
        </VStack>
        <VStack spacing="0">
          <HStack>
            <BsMoonStars color="#9F7AEA" />
            <Text fontSize="sm" color="gray.500">
              Inactive
            </Text>
          </HStack>
          <Text fontSize="lg" fontWeight="bold" color="gray.500">
            {sleepingPlayers}
          </Text>
        </VStack>
      </Flex>
    </VStack>
  );
};
