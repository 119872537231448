import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";

type ConfirmButtonProps = {
  bodyText: string;
  confirmButtonText: string;
  onConfirm: () => void;
};

export const ConfirmButton: FunctionComponent<ConfirmButtonProps> = ({
  children,
  bodyText,
  confirmButtonText,
  onConfirm,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>Confirmation!</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>{bodyText}</PopoverBody>
          <PopoverFooter>
            <Button colorScheme="orange" onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
